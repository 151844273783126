export const settingViewRights = [
    "DCT-PORTAL",
    "BRD-PORTAL",
    "BLPS-PORTAL",
    "CF-PORTAL",
    "BLPS-PORTAL-METRIC",
    "BLPS-PORTAL-TOOL",
    "BLPS-PORTAL-KNOWLEDGE",
    "BLPS-PORTAL-TEAM",
    "BLPS-PORTAL-HELPDESK",
    "BRD-PORTAL-METRIC",
    "BRD-PORTAL-TOOL",
    "BRD-PORTAL-KNOWLEDGE",
    "BRD-PORTAL-TEAM",
    "BRD-PORTAL-HELPDESK",
    "CF-PORTAL-METRIC",
    "CF-PORTAL-TOOL",
    "CF-PORTAL-KNOWLEDGE",
    "CF-PORTAL-TEAM",
    "CF-PORTAL-HELPDESK",
];

export const metricsViewRights = [
    "DCT-PORTAL",
    "BRD-PORTAL",
    "BLPS-PORTAL",
    "CF-PORTAL",
    "BLPS-PORTAL-METRIC",
    "BRD-PORTAL-METRIC",
    "CF-PORTAL-METRIC",
];
