import { useEffect } from "react";

import { PageContentWrapper } from "components/PageContentWrapper";
import { ContactsBlock } from "./components/ContactsBlock";
import { HeroBlock } from "./components/HeroBlock";
import { IncreasingCompetenciesBlock } from "./components/IncreasingCompetenciesBlock";
import { PortfolioBlock } from "./components/PortfolioBlock";
import { IdeaBlock } from "./components/IdeaBlock";
import { AboutProcessBlock } from "./components/AboutProcessBlock";

import "./MainPage.scss";
import { useGetMetricsQuery } from "../../store/labApi";
import { MetricTypeDto } from "../../store/types/Metrics.model";
import { MetricKey } from "../../shared/constants/metrics.const";
import { FaqBlock } from "./components/FaqBlock";



export const MainPage = () => {
    useEffect(() => {
        document.title = 'Лаборатория данных';
    });
    const { data } = useGetMetricsQuery(undefined, { refetchOnMountOrArgChange: true });
    const portfolioCount = data?.find(
            (metric: MetricTypeDto) => metric.key === MetricKey.PORTFOLIO_COUNT
    )?.value;

    return (
        <>
            <HeroBlock />
            <IdeaBlock/>
            <AboutProcessBlock/>
            <PortfolioBlock portfolioCount={portfolioCount} />
            <PageContentWrapper className="main-page">
                {/*<BusinessProcessBlock />*/}
                {/*<DataBlock />*/}
                {/*<ToolsBlock />*/}

                <IncreasingCompetenciesBlock />
                <FaqBlock />


                <ContactsBlock />
            </PageContentWrapper>
        </>
    );
};
