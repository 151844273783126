import { FC, useState, useEffect } from "react";

import { useFieldArray, useForm } from "react-hook-form";

import { useEditHelpdeskMutation } from "store/labApi";

import { Button } from "@consta/uikit/Button";
import { cnMixSpace } from "@consta/uikit/MixSpace";
import { IconAdd } from "@consta/uikit/IconAdd";
import { Informer } from "@consta/uikit/Informer";

import { ErrorType } from "shared/types/ErrorType";

import { HelpdeskFieldsBlock } from "./components/HelpdeskFieldsBlock";

import { ModalWrapper } from "shared/components/ModalWrapper";

import { getdDefaultFormValueForHelpdesk } from "shared/utils/helpdeskUtils";

import {
    EditHelpdesksModalProps,
    EditHelpdesksModalSubmitData,
} from "./model/EditHelpdesksModal.model";

import "./EditHelpdesksModal.scss";

export const EditHelpdesksModal: FC<EditHelpdesksModalProps> = ({
    type,
    isOpen,
    handleClose,
    helpdesks,
    blockId,
    refetch,
}) => {
    const emailType = type === "EMAIL";

    const [hasError, setHasError] = useState(false);

    const defaultValues = getdDefaultFormValueForHelpdesk(helpdesks);

    const { control, handleSubmit, reset } = useForm({
        defaultValues: {
            helpdesks: defaultValues,
        },
        mode: "onChange",
    });

    const { fields, append, remove } = useFieldArray({
        control,
        name: "helpdesks",
    });

    const [editHelpdesk, { error, isLoading }] = useEditHelpdeskMutation();

    const handleAppend = () => {
        append({
            link: { value: "" },
            name: { value: "" },
            type,
        });
    };
    const onSubmit = async (data: EditHelpdesksModalSubmitData) => {
        const helpdesks = data.helpdesks.map((item) => {
            return {
                name: item.name.value,
                link: item.link.value,
                type: item.type,
            };
        });
        await editHelpdesk({
            id: blockId,
            body: {
                helpdesks,
                type: emailType ? "EMAIL" : "RESOURCE",
            },
        }).unwrap();
        refetch();
        handleClose();
    };

    useEffect(() => {
        if (error) {
            setHasError(true);
            setTimeout(() => {
                setHasError(false);
            }, 7000);
        }
    }, [error]);

    return (
        <ModalWrapper
            title={`Добавить ${emailType ? "почту" : "ссылку"}`}
            isOpen={isOpen}
            handleClose={handleClose}
        >
            {hasError && error && (
                <Informer
                    status="alert"
                    view="filled"
                    title="Ошибка"
                    label={"data" in error ? (error as ErrorType).data.error : ""}
                />
            )}
            <form onSubmit={handleSubmit(onSubmit)} className="edit-helpdesks-modal">
                {fields.map((field, index) => (
                    <HelpdeskFieldsBlock
                        key={field.id}
                        index={index}
                        control={control}
                        removeBlock={remove}
                        emailType={emailType}
                    />
                ))}
                <Button
                    className="edit-helpdesks-modal__add-button"
                    label={`Добавить еще ${emailType ? "почту" : "ссылку"}`}
                    view="secondary"
                    size="s"
                    iconLeft={IconAdd}
                    type="button"
                    onClick={handleAppend}
                />
                <div className="edit-helpdesks-modal__buttons">
                    <Button
                        type="button"
                        label="Отменить"
                        onClick={() => {
                            reset();
                            handleClose();
                        }}
                        view="ghost"
                    />
                    <Button
                        type="submit"
                        label="Сохранить"
                        className={cnMixSpace({
                            mL: "m",
                        })}
                        loading={isLoading}
                    />
                </div>
            </form>
        </ModalWrapper>
    );
};
