import { FC } from "react";

import { ReviewsViewWidgetItemProps } from "./model/ReviewsViewWidgetItem.model";

import "./ReviewsViewWidgetItem.scss";
import { Avatar } from "@consta/uikit/Avatar";
import { imgConfig } from "../../../../../../configs/imgConfig";
import { getMimeType } from "../../../../../../shared/utils/users.utils";

export const ReviewsViewWidgetItem: FC<ReviewsViewWidgetItemProps> = ({ review }) => {
    const img = review?.user.image
        ? `data:${getMimeType(review.user.image)};base64,${review.user.image.replace(
              /\s+/g,
              ""
          )}`
        : `${process.env.REACT_APP_API_URL}/images/${imgConfig.defaultAvatarUuid}`;

    return (
        <div className="reviews-view-widget-item">
            <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M5.26829 9.75196C8.16496 9.75196 10.5132 12.1002 10.5132 14.9968C10.5132 17.8935 8.16496 20.2417 5.26829 20.2417C2.37162 20.2417 0.0234146 17.8935 0.0234146 14.9968L0 14.2476C0 8.45423 4.69641 3.75781 10.4898 3.75781V6.75489C8.48841 6.75489 6.60681 7.53427 5.19163 8.94945C4.91923 9.2219 4.67061 9.51177 4.44635 9.81626C4.71416 9.77411 4.98863 9.75196 5.26829 9.75196ZM18.7551 9.75196C21.6517 9.75196 24 12.1002 24 14.9968C24 17.8935 21.6517 20.2417 18.7551 20.2417C15.8585 20.2417 13.5102 17.8935 13.5102 14.9968L13.4868 14.2476C13.4868 8.45423 18.1832 3.75781 23.9766 3.75781V6.75489C21.9753 6.75489 20.0936 7.53427 18.6785 8.94945C18.406 9.2219 18.1574 9.51177 17.9331 9.81626C18.2009 9.77411 18.4754 9.75196 18.7551 9.75196Z"
                    fill="#002033"
                    fillOpacity="0.3"
                />
            </svg>
            <div>
                <div className="reviews-view-widget-item__info user-info">
                    <div className="review-user-avatar">
                        <Avatar
                            className="user-info__avatar"
                            size="l"
                            name={review.user?.first_name}
                            url={img}
                        />
                        <div>
                            <div className="review-user-avatar-name">
                                {review?.user.last_name +
                                    " " +
                                    review?.user.first_name +
                                    " " +
                                    review?.user.middle_name}
                            </div>
                            <div className="review-user-avatar-position">
                                {review?.position}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="review-user__text">{review.body}</div>
            </div>
        </div>
    );
};
