import { ExternalUserDto } from "store/types/ExternalUsers.model";

import avatar from "assets/icons/icn_avatar.svg";

export const getMimeType = (base64: string) => {
    const byteCharacters = atob(base64.substring(0, 30));
    const bytes = new Uint8Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
        bytes[i] = byteCharacters.charCodeAt(i);
    }

    // Сигнатуры файлов
    const signatures: { [key: string]: string } = {
        "89504E47": "image/png",
        "47494638": "image/gif",
        FFD8FFDB: "image/jpeg",
        FFD8FFE0: "image/jpeg",
        FFD8FFE1: "image/jpeg",
        // Добавьте другие сигнатуры по мере необходимости
    };

    const hex = bytes
        .slice(0, 4)
        .reduce(
            (acc, byte) => acc + byte.toString(16).toUpperCase().padStart(2, "0"),
            ""
        );
    return signatures[hex] || "application/octet-stream"; // 'application/octet-stream' на случай неизвестного типа
};

export const getExternalEmployees = (models?: ExternalUserDto[]) => {
    return models?.map((item) => {
        let img = avatar; // значение по умолчанию для списка пользователей, отличается от дефолтного
        if (item.image) {
            const mimeType = getMimeType(item.image);
            img = item.image
                ? `data:${mimeType};base64,${item.image.replace(/\s+/g, "")}`
                : "";
        }

        return {
            label: `${item.last_name + " "} ${item.first_name + " "} ${item.middle_name}`,
            subLabel: item.email,
            avatarUrl: img,
            id: item.external_key,
            position: item.position,
        };
    });
};
