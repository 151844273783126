import { FilterBlockDto, FilterComplexityDto } from "store/types/KnowledgeFilters.model";

export const getComboboxValideValues = (
    param: string,
    filters?: FilterBlockDto[] | FilterComplexityDto[]
) =>
    filters?.map((item) => {
        if ("short_name" in item && item.short_name) {
            return {
                id: item._uuid,
                label: item.short_name,
                param,
            };
        } else {
            return {
                id: item._uuid,
                label: item.name,
                param,
            };
        }
    });
