import { FilterType } from "shared/types/FiltersType";
// import { UserSelectType } from "shared/types/UserSelectType";
import { ReviewType } from "./components/ReviewsWidget/model/ReviewsWidget.model";

import { FormMutatePortfolioSubmitType } from "./model/FormMutatePortfolio.model";

export const createParams = (data: FormMutatePortfolioSubmitType) => {
    const formData = new FormData();
    formData.append("name", data.name?.value || "");
    formData.append("description", data.description?.value ? data.description?.value :  data.description || "");
    formData.append("uuid_block", data.block?.value.id || "");
    data.domains?.value.forEach((domain: FilterType) => {
        formData.append("domains[]", domain.id);
    });
    formData.append("uuid_tool", data.tool?.value.id || "");
    formData.append("goals", data.goals?.value ? data.goals?.value :  data.goals || "");
    formData.append("results", data.results?.value ? data.results?.value  : data.results || "");
    console.log(typeof data.plans);
    formData.append("plans", (typeof data.plans === 'object') ?  data.plans?.value || '' : data.plans ||  '');
    formData.append("users", data.users?.value || "");
    // formData.append("uuid_complexity", data.complexity?.value.id || "");

    formData.append("application_link", data.link?.value || "");

    // formData.append("main_image", data.avatar?.value[0]?._uuid ? data.avatar?.value[0]?._uuid : data.avatar?.value[0]  || "");
    formData.append("uuid_user", data.developers?.value?.id || "");
    //console.log(data.developers?.value?.id );
    data.screenshots?.value.forEach((image: File | any) => {
        formData.append(`images[${image?._uuid||''}][file]`, image?._uuid || image);
        formData.append(`images[${image?._uuid||''}][description]`, "123");
    });

    data.reviews?.forEach((item: ReviewType, index: number) => {
        formData.append(`comments[${item._uuid||index}][uuid_user]`, item.user?.id || "");
        formData.append(`comments[${item._uuid||index}][avatar]`, item.image || "");
        formData.append(`comments[${item._uuid||index}][body]`, item.body || "");
    });

    return formData;
};
