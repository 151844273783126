import { FC, KeyboardEvent, useEffect, useRef, useState } from "react";

import { useFormContext, useWatch } from "react-hook-form";

import { useLazyGetExternalUsersQuery } from "store/labApi";

import { UserSelect } from "@consta/uikit/UserSelect";
import { User } from "@consta/uikit/User";
import { cnMixSpace } from "@consta/uikit/MixSpace";
import { TextField } from "@consta/uikit/TextField";
import { Button } from "@consta/uikit/Button";

import { ReviewsPlacementOrder } from "./components/ReviewsPlacementOrder";

import { getExternalEmployees, getMimeType } from "shared/utils/users.utils";

import { UserSelectType } from "shared/types/UserSelectType";

import { ReviewsWidgetProps } from "./model/ReviewsWidget.model";

import "./ReviewsWidget.scss";
import useDebouncedFunction from "../../../../../../shared/utils/debounce.ustils";
import { imgConfig } from "../../../../../../configs/imgConfig";

export const ReviewsWidget: FC<ReviewsWidgetProps> = ({ control, reviews }) => {
    const { setValue } = useFormContext();

    const formReviews = useWatch({
        control,
        name: "reviews",
    });
    const [users, setUsers] = useState<UserSelectType[] | undefined>([]);
    const [currentUser, setCurrentUser] = useState<UserSelectType | null>();
    const [reviewText, setReviewText] = useState<string | null>(null);

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [getUsers] = useLazyGetExternalUsersQuery();

    const abortRef = useRef<any | undefined>();

    const sendRequest = useDebouncedFunction(
        async (input) => {
            try {
                const request = getUsers(input.value);
                abortRef.current = request;
                const { data } = await request.unwrap();
                setUsers(getExternalEmployees(data?.models));
                setIsLoading(false);
            } catch (error) {
                if ((error as Error)?.name !== "AbortError") throw error;
            } finally {
                abortRef.current = undefined;
            }
        },
        500,
        true
    );

    const handleSearchEmployee = async (event: KeyboardEvent<HTMLDivElement>) => {
        const input = event.target as HTMLInputElement;
        if (input.value.length < 2) {
            setUsers([]);
            setIsLoading(false);
        } else {
            abortRef.current?.abort();
            setIsLoading(true);
            sendRequest(input);
        }
    };

    const resetFields = () => {
        setCurrentUser(null);
        setReviewText(null);
        setUsers(undefined);
    };

    const handleAddReview = () => {
        const review = {
            user: currentUser,
            body: reviewText,
        };
        setValue("reviews", formReviews ? [...formReviews, review] : [review]);
        resetFields();
    };

    useEffect(() => {
        if (reviews?.length) {
            const updatedReviews = reviews.map((review) => {
                let img = `${process.env.REACT_APP_API_URL}/images/${imgConfig.defaultAvatarUuid}`;
                if (review.user.image) {
                    img = `data:${getMimeType(
                        review.user.image
                    )};base64,${review.user.image.replace(/\s+/g, "")}`;
                }

                return {
                    ...review,
                    user: {
                        label:
                            review.user.last_name +
                            " " +
                            review.user.first_name +
                            " " +
                            review.user.middle_name,
                        subLabel: review.user.email,
                        avatarUrl: img,
                        id: review.user._uuid,
                        position: review.position,
                    },
                };
            });
            setValue("reviews", updatedReviews);
        }
    }, [reviews, setValue]);

    return (
        <div className="reviews-widget">
            <div className="reviews-widget__title">Комментарии</div>
            <ReviewsPlacementOrder reviews={formReviews} />
            <div className="review-widget__new-title">
                Комментарий {formReviews?.length + 1 || 1}
            </div>
            <UserSelect
                placeholder="Выберите сотрудника"
                label="Сотрудник"
                items={users || []}
                value={currentUser}
                isLoading={isLoading}
                onKeyUp={(e) => handleSearchEmployee(e)}
                className={cnMixSpace({
                    mB: "l",
                    mT: "l",
                })}
                onChange={(item) => setCurrentUser(item.value)}
                searchFunction={() => true}
                renderValue={({ item }) => (
                    <User
                        view="ghost"
                        name={item.label}
                        avatarUrl={item.avatarUrl}
                        info={item.subLabel}
                    />
                )}
            />
            <TextField
                placeholder="Введите комментарий"
                label="Комментарий"
                type="textarea"
                rows={5}
                width="full"
                value={reviewText}
                onChange={(event) => setReviewText(event.value)}
                className={cnMixSpace({
                    mB: "m",
                })}
            />
            <div className="reviews-widget__buttons">
                <Button
                    view="secondary"
                    label="Отменить"
                    onClick={resetFields}
                    size="s"
                    className="reviews-widget__buttons-item"
                />
                <Button
                    disabled={!currentUser}
                    view="secondary"
                    label="Сохранить"
                    onClick={handleAddReview}
                    size="s"
                    className="reviews-widget__buttons-item"
                />
            </div>
        </div>
    );
};
