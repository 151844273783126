import { FC } from "react";

import { ImageDragAndDropItem } from "./components/ImageDragAndDropItem";

import { ImagesWidgetProps } from "./model/ImagesWidget.model";
import { cnMixSpace } from "@consta/uikit/MixSpace";

export const ImagesWidget: FC<ImagesWidgetProps> = ({
    control,
    images,
    refC,
    fieldState,
}) => (
    <div
        className={cnMixSpace({ mT: "2xl" })}
        ref={(instance) => {
            refC?.({
                focus: () => {
                    instance?.scrollIntoView({ block: "center" });
                },
            });
        }}
    >
        <ImageDragAndDropItem
            title="Изображения"
            fieldName="screenshots"
            control={control}
            images={images}
            fieldState={fieldState}
        />
    </div>
);
