import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Controller, FormProvider, useForm } from "react-hook-form";

import {
    useEditKnowledgeMutation,
    useGetKnowledgeFiltersQuery,
    useGetKnowledgeQuery,
} from "store/labApi";

import { TextField } from "@consta/uikit/TextField";
import { Combobox } from "@consta/uikit/Combobox";
import { Button } from "@consta/uikit/Button";
import { cnMixSpace } from "@consta/uikit/MixSpace";
import { Loader } from "@consta/uikit/Loader";

import { MaterialField } from "shared/components/MaterialField";
import { materialTypesItems } from "shared/constants/materialTypes.const";
import { formatType } from "shared/constants/formatTypes.const";
import { createParams, getComboboxValideValuesShared } from "shared/utils";
import { FormMutateKnowledgeSubmitType } from "shared/types/mutateKnowledgeType";

import "./FormEditKnowledge.scss";
import { ChoiceGroup } from "@consta/uikit/ChoiceGroup";
import { PageRoutes } from "../../../../appMenuAndRouting/appRoutes";

export const FormEditKnowledge = () => {
    const { pathname } = useLocation();
    const navigate = useNavigate();
    const pathnameAsArray = pathname.split("/");
    const knowledgeId = pathnameAsArray[pathnameAsArray.length - 1];

    const {
        data: knowledgeData,
        isLoading: isLoadingKnowledge,
        refetch,
    } = useGetKnowledgeQuery(knowledgeId);

    const knowledge = knowledgeData?.data;
    const knowledgeBlocks = getComboboxValideValuesShared(knowledge?.blocks);
    const knowledgeTags = getComboboxValideValuesShared(knowledge?.tags);

    const { data: filtersData, isLoading: isLoadingFilters } =
        useGetKnowledgeFiltersQuery();

    const blocksItems = getComboboxValideValuesShared(filtersData?.blocks);
    const complexitiesItems = getComboboxValideValuesShared(filtersData?.complexities);
    const toolsItems = getComboboxValideValuesShared(filtersData?.tools);
    const tagsItems = getComboboxValideValuesShared(filtersData?.tags);

    const [editKnowledge, { isLoading: isLoadingEditKnowledge }] =
        useEditKnowledgeMutation();

    const methods = useForm({
        mode: "onChange",
    });
    const control = methods.control;
    const handleSubmit = methods.handleSubmit;
    const setValue = methods.setValue;
    const formState = methods.formState;
    const isValidForm = formState.isValid;

    const basepath =
        process.env.REACT_APP_BASEPATH === "/"
            ? "/"
            : `${process.env.REACT_APP_BASEPATH}/`;

    const onSubmit = async (data: FormMutateKnowledgeSubmitType) => {
        const params = createParams(data);
        await editKnowledge({ id: knowledgeId, body: params }).unwrap();
        await refetch();
        console.log("обновили");
        navigate(basepath + "knowledge");
    };

    useEffect(() => {
        if (knowledge?.type === formatType.LINK) {
            setValue("material_link", { value: knowledge.link });
        }
        if (knowledge?.type === formatType.INTERNAL) {
            setValue("material_article", { value: knowledge?.body });
        }
    }, [knowledge?.body, knowledge?.link, knowledge?.subject, knowledge?.type, setValue]);

    if (isLoadingKnowledge || isLoadingFilters || isLoadingEditKnowledge) {
        return <Loader />;
    }

    return (
        <FormProvider {...methods}>
            <form onSubmit={handleSubmit(onSubmit)} className="form-edit-document">
                <div className="hidden">
                    <Controller
                        name="material_type"
                        control={control}
                        defaultValue={{
                            value: materialTypesItems.find(
                                (item) => item.id === knowledge?.type
                            ),
                        }}
                        render={({ field }) => {
                            return (
                                <ChoiceGroup
                                    items={materialTypesItems}
                                    multiple={false}
                                    {...field}
                                    value={field.value?.value}
                                    size="m"
                                />
                            );
                        }}
                    />
                </div>

                <Controller
                    name="title"
                    defaultValue={{ value: knowledge?.subject }}
                    rules={{
                        required: true,
                        validate: (value) => value?.value !== null,
                    }}
                    control={control}
                    render={({ field }) => {
                        return (
                            <TextField
                                required
                                placeholder="Введите название"
                                label="Название"
                                {...field}
                                value={field.value?.value}
                            />
                        );
                    }}
                />
                <Controller
                    name="description"
                    control={control}
                    defaultValue={{ value: knowledge?.description }}
                    render={({ field }) => (
                        <TextField
                            placeholder="Введите описание"
                            label="Описание"
                            type="textarea"
                            rows={3}
                            {...field}
                            value={field.value?.value}
                        />
                    )}
                />
                {blocksItems && (
                    <Controller
                        name="belonging"
                        control={control}
                        defaultValue={{ value: knowledgeBlocks }}
                        rules={{
                            required: true,
                            validate: (value) => value?.value !== null,
                        }}
                        render={({ field }) => {
                            return (
                                <Combobox
                                    required
                                    placeholder="Выберите блок"
                                    label="Принадлежность к блоку"
                                    items={blocksItems}
                                    multiple
                                    {...field}
                                    value={field.value?.value}
                                />
                            );
                        }}
                    />
                )}
                {complexitiesItems && (
                    <Controller
                        name="complexity"
                        control={control}
                        defaultValue={{
                            value: {
                                label: knowledge?.complexity?.name,
                                id: knowledge?.complexity?._uuid,
                            },
                        }}
                        rules={{
                            required: true,
                            validate: (value) => value?.value !== null,
                        }}
                        render={({ field }) => {
                            return (
                                <Combobox
                                    required
                                    placeholder="Выберите сложность"
                                    label="Сложность материала"
                                    items={complexitiesItems}
                                    {...field}
                                    value={field.value?.value}
                                />
                            );
                        }}
                    />
                )}

                {toolsItems?.length && (
                    <Controller
                        name="tool"
                        control={control}
                        rules={{
                            required: true,
                            validate: (value) => value?.value !== null,
                        }}
                        defaultValue={{
                            value: {
                                label: knowledge?.tool?.name,
                                id: knowledge?.tool?._uuid,
                            },
                        }}
                        render={({ field }) => {
                            return (
                                <Combobox
                                    required
                                    placeholder="Выберите инструмент"
                                    label="Используемый инструмент"
                                    items={toolsItems}
                                    {...field}
                                    value={field.value?.value}
                                />
                            );
                        }}
                    />
                )}
                {tagsItems && (
                    <Controller
                        name="tags"
                        control={control}
                        defaultValue={{ value: knowledgeTags }}
                        render={({ field }) => {
                            return (
                                <Combobox
                                    placeholder="Выберите теги"
                                    label="Теги"
                                    items={tagsItems}
                                    multiple
                                    {...field}
                                    value={field.value?.value}
                                />
                            );
                        }}
                    />
                )}

                <MaterialField
                    control={control}
                    type={knowledge?.type}
                    file={knowledge?.file}
                />
                <div className="form-create-document__button_group">
                    <Button
                        label="Отмена"
                        view={"ghost"}
                        onClick={() => navigate(PageRoutes.DataLab_KnowledgesPage)}
                        className={cnMixSpace({ mR: "xs" })}
                    />
                    <Button
                        label="Сохранить документ"
                        type="submit"
                        className="form-create-document__button"
                        disabled={!isValidForm}
                    />
                </div>
            </form>
        </FormProvider>
    );
};
