import { Modal } from "@consta/uikit/Modal";
import { Button } from "@consta/uikit/Button";
import React, { ReactNode, useEffect } from "react";
import classes from "./ModalTemplate.module.css";
import { IconClose } from "@consta/uikit/IconClose";
import { Text } from "@consta/uikit/Text";

type ModalTemplateProps = {
    children: ReactNode;
    isOpen: boolean;
    onClose: () => void;
    className?: string;
    disableCloseOnClickOutside?: boolean;
    disableCloseOnEsc?: boolean;
    hideCloseIcon?: boolean;
    title: string;
    cancelName?: string;
    cancelAction?: () => void;
    confirmName?: string;
    confirmAction?: () => void;
    disableConfirm?: boolean;
    hideFooter?: boolean;
};

export const ModalTemplate = (props: ModalTemplateProps) => {
    const {
        title,
        children,
        isOpen,
        onClose,
        className,
        disableCloseOnClickOutside,
        disableCloseOnEsc,
        hideCloseIcon,
        cancelName,
        cancelAction,
        confirmName,
        confirmAction,
        disableConfirm,
        hideFooter,
    } = props;
    useEffect(() => {
        return () => {
            document.body.style.overflow = "";
        };
    }, []);
    return (
        <Modal
            isOpen={isOpen}
            hasOverlay
            onClickOutside={disableCloseOnClickOutside === true ? undefined : onClose}
            onEsc={disableCloseOnEsc === true ? undefined : onClose}
            className={`${classes.modal} ${className}`}
            onOpen={() => {
                document.body.style.overflow = "hidden"; // отключаем прокрутку при открытии модального окна
            }}
            onClose={() => {
                document.body.style.overflow = ""; // возвращаем прокрутку при закрытии модального окна
            }}
        >
            <Text className={classes.title}>{title}</Text>
            {!hideCloseIcon && (
                <Button
                    size={"s"}
                    view={"clear"}
                    className={classes.closeIcon}
                    onClick={onClose}
                    onlyIcon={true}
                    iconRight={IconClose}
                />
            )}
            <div className={classes.content}>{children}</div>
            {!hideFooter && (
                <div className={classes.footer}>
                    <Button
                        view={"ghost"}
                        onClick={cancelAction ? cancelAction : onClose}
                        label={cancelName ? cancelName : "Отмена"}
                    />
                    <Button
                        view={"primary"}
                        onClick={confirmAction ? confirmAction : onClose}
                        label={confirmName ? confirmName : "Ок"}
                        disabled={disableConfirm}
                    />
                </div>
            )}
        </Modal>
    );
};
