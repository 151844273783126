import { useEffect, useState } from "react";

import { PageContentWrapper } from "components/PageContentWrapper";
import { Breadcrumbs } from "@consta/uikit/Breadcrumbs";
import { PageRoutes } from "../../appMenuAndRouting/appRoutes";
import { Modal } from "@consta/uikit/Modal";
import "./ToolsPage.scss";
import { Button } from "@consta/uikit/Button";
import { IconForward } from "@consta/uikit/IconForward";
import iconQrCode from "../../assets/icons/icn_qr-code.svg";
import iconPixQrCode from "../../assets/icons/pixbi.svg";
import iconQlikQrCode from "../../assets/icons/qlik.svg";
import imgPix from "../../assets/images/img-pix.png";
import imgQlik from "../../assets/images/img-qlik.png";
import imgPython from "../../assets/images/img-python.png";
import { IconClose } from "@consta/uikit/IconClose";
import { useGetMetricsQuery, useGetToolsQuery } from "../../store/labApi";
import { BlockLinkDto, ToolDto } from "../../store/types/Tool.model";
import { SupportModal } from "../../components/SupportModal/SupportModal";
import { divideNumIntoCategories, enumerate } from "../../shared/utils";
import { MetricKey } from "../../shared/constants/metrics.const";

const IconQlik = () => <img src={imgQlik} alt="Qlick" width="111" height="32" />;
const IconPython = () => <img src={imgPython} alt="Python" width="130" height="32" />;
const IconPix = () => <img src={imgPix} alt="Pix" width="149" height="32" />;

export const ToolsPage = () => {
    useEffect(() => {
        document.title = "Инструменты | Лаборатория данных";
    }, []);

    const { data: metricsData } = useGetMetricsQuery(undefined, {
        refetchOnMountOrArgChange: true,
    });

    const totalAppPix =
        metricsData?.find(
            (metric) => metric.key === MetricKey.STORE_APPLICATION_PIX_COUNT
        )?.value ?? "";

    const totalAppQlik =
        metricsData?.find(
            (metric) => metric.key === MetricKey.STORE_APPLICATION_QLIK_COUNT
        )?.value ?? "";

    const totalAppPyton =
        metricsData?.find(
            (metric) => metric.key === MetricKey.STORE_APPLICATION_PYTHON_COUNT
        )?.value ?? "";

    const basepath =
        process.env.REACT_APP_BASEPATH === "/"
            ? process.env.REACT_APP_BASEPATH
            : `${process.env.REACT_APP_BASEPATH}/`;

    const [activeTab, setActiveTab] = useState<string>("pixbi");

    const [isPixQrModalOpen, setIsPixQrModalOpen] = useState<boolean>(false);
    const [isQlikQrModalOpen, setIsQlikQrModalOpen] = useState<boolean>(false);

    const items = [
        {
            label: "Лаборатория данных",
            href: PageRoutes.DataLab_MainPage,
        },
        {
            label: "Инструменты",
            href: PageRoutes.DataLab_ToolsPage,
        },
    ];

    const { data: toolsData } = useGetToolsQuery(undefined, {
        refetchOnMountOrArgChange: true,
    });

    const toolsObj: { [key: string]: ToolDto } = {};
    //console.log(toolsData);
    if (toolsData) {
        toolsData.map((el) => {
            if (el.slug) {
                toolsObj[el.slug] = el;
            }
        });
        console.log(toolsObj);
    }

    const [isRequestModalOpen, setIsRequestModalOpen] = useState<boolean>(false);

    const handlerSwitchTool = (tabName: string) => {
        setActiveTab(tabName);
        scrollTo({ behavior: "smooth", top: 240 });
    };

    return (
        <>
            <div className="hero-block-portfolio">
                <div className="hero-block-portfolio__content tools-header">
                    <Breadcrumbs items={items} />
                    <div className="hero-block-portfolio__title">Инструменты</div>
                    <div className="hero-block-portfolio__desc">
                        Определитесь с инструментом анализа данных, исходя из нюансов
                        задачи и возможностей инструмента
                    </div>
                    <div className="tools-tabs">
                        <div
                            className={activeTab === "pixbi" ? "active" : ""}
                            onClick={() => setActiveTab("pixbi")}
                        >
                            <svg
                                width="32"
                                height="32"
                                viewBox="0 0 32 32"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <g clipPath="url(#clip0_14513_62678)">
                                    <rect
                                        width="32"
                                        height="32"
                                        rx="5.33333"
                                        fill="white"
                                    />
                                    <rect
                                        x="7.55588"
                                        y="7.55588"
                                        width="16.8889"
                                        height="16.8889"
                                        stroke="#872BFF"
                                        strokeWidth="1.77778"
                                    />
                                    <path
                                        d="M6.93359 7.06665L15.8672 16.0002L6.93359 24.9338V7.06665Z"
                                        fill="#872BFF"
                                    />
                                    <path
                                        d="M24.9336 7.06665L16 16.0002L24.9336 24.9338V7.06665Z"
                                        fill="#872BFF"
                                    />
                                </g>
                                <rect
                                    x="0.222222"
                                    y="0.222222"
                                    width="31.5556"
                                    height="31.5556"
                                    rx="5.11111"
                                    stroke="#004166"
                                    strokeOpacity="0.2"
                                    strokeWidth="0.444444"
                                />
                                <defs>
                                    <clipPath id="clip0_14513_62678">
                                        <rect
                                            width="32"
                                            height="32"
                                            rx="5.33333"
                                            fill="white"
                                        />
                                    </clipPath>
                                </defs>
                            </svg>
                            PIX BI
                        </div>
                        <div
                            className={activeTab === "qlik" ? "active" : ""}
                            onClick={() => setActiveTab("qlik")}
                        >
                            <svg
                                width="32"
                                height="32"
                                viewBox="0 0 32 32"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <rect width="32" height="32" rx="5.33333" fill="white" />
                                <rect
                                    x="0.222222"
                                    y="0.222222"
                                    width="31.5556"
                                    height="31.5556"
                                    rx="5.11111"
                                    stroke="#004166"
                                    strokeOpacity="0.2"
                                    strokeWidth="0.444444"
                                />
                                <circle
                                    cx="15.9999"
                                    cy="15.9999"
                                    r="8"
                                    stroke="#009C4D"
                                    strokeWidth="3.55556"
                                />
                                <circle cx="16" cy="16" r="4" fill="#54565B" />
                                <rect
                                    x="20.4443"
                                    y="22.9583"
                                    width="3.55556"
                                    height="5.76488"
                                    rx="0.444444"
                                    transform="rotate(-45 20.4443 22.9583)"
                                    fill="#009C4D"
                                />
                            </svg>
                            Qlik Sense
                        </div>
                        <div
                            className={activeTab === "python" ? "active" : ""}
                            onClick={() => setActiveTab("python")}
                        >
                            <svg
                                width="32"
                                height="32"
                                viewBox="0 0 32 32"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <rect width="32" height="32" rx="5.33333" fill="white" />
                                <rect
                                    x="0.222222"
                                    y="0.222222"
                                    width="31.5556"
                                    height="31.5556"
                                    rx="5.11111"
                                    stroke="#004166"
                                    strokeOpacity="0.2"
                                    strokeWidth="0.444444"
                                />
                                <path
                                    d="M16.012 6.22254C15.195 6.22634 14.4149 6.29524 13.7285 6.41555C11.7062 6.76954 11.3392 7.50997 11.3392 8.87556V10.6792H16.1178V11.2804H9.54356C8.15468 11.2804 6.93856 12.1072 6.55858 13.6797C6.11987 15.4823 6.1003 16.6073 6.55858 18.4895C6.89835 19.8907 7.70959 20.8888 9.09826 20.8888H10.7414V18.7268C10.7414 17.1646 12.106 15.7868 13.7264 15.7868H18.4996C19.8282 15.7868 20.8888 14.7033 20.8888 13.3818V8.87535C20.8888 7.59277 19.7963 6.62921 18.4996 6.41534C17.6788 6.28007 16.8269 6.21854 16.0099 6.22233L16.012 6.22254ZM13.4255 7.67284C13.9191 7.67284 14.3223 8.07867 14.3223 8.57741C14.3223 9.07468 13.9191 9.4765 13.4255 9.4765C12.9302 9.4765 12.5288 9.07447 12.5288 8.57741C12.5288 8.07845 12.93 7.67284 13.4255 7.67284Z"
                                    fill="#0075FF"
                                />
                                <path
                                    d="M21.2382 11.1111V13.1966C21.2382 14.8136 19.8462 16.1742 18.2593 16.1742H13.4957C12.191 16.1742 11.1113 17.2741 11.1113 18.5609V23.0331C11.1113 24.3059 12.2352 25.0544 13.4957 25.4197C15.0032 25.8568 16.453 25.9358 18.2593 25.4197C19.46 25.0774 20.6437 24.3885 20.6437 23.0331V21.2432H15.8801V20.6466H23.0281C24.4141 20.6466 24.9305 19.6946 25.4125 18.2656C25.9104 16.7944 25.8892 15.3796 25.4125 13.4925C25.07 12.1338 24.4158 11.1115 23.0281 11.1115H21.2382V11.1111ZM18.5591 22.4365C19.0534 22.4365 19.454 22.8355 19.454 23.3287C19.454 23.8239 19.0536 24.2264 18.5591 24.2264C18.0665 24.2264 17.6641 23.8237 17.6641 23.3287C17.6641 22.8353 18.0665 22.4365 18.5591 22.4365Z"
                                    fill="#FFC700"
                                />
                            </svg>
                            Python
                        </div>
                    </div>
                </div>
            </div>
            <PageContentWrapper>
                {activeTab === "pixbi" && (
                    <div className="tools-content">
                        <div className="tools-title">Преимущества PIX BI</div>
                        <div className="tools-advantages">
                            <div>
                                <div className="adv-title">
                                    <div className="adv-number">1</div>
                                    Отечественное ПО
                                </div>
                                <div className="adv-text">
                                    Решайте задачи без риска потери наработок из-за
                                    введения санкций
                                </div>
                            </div>
                            <div>
                                <div className="adv-title">
                                    <div className="adv-number">2</div>
                                    Развитая поддержка
                                </div>
                                <div className="adv-text">
                                    Изучайте актуальную документацию от вендора и
                                    задавайте экспертам вопросы на русском языке
                                </div>
                            </div>
                            <div>
                                <div className="adv-title">
                                    <div className="adv-number">3</div>
                                    Простой в использовании
                                </div>
                                <div className="adv-text">
                                    Создавайте приложения без продвинутых навыков
                                    программирования
                                </div>
                            </div>
                            <div>
                                <div className="adv-title">
                                    <div className="adv-number">4</div>
                                    Знакомый интерфейс
                                </div>
                                <div className="adv-text">
                                    Быстро освойте новую систему, интерфейс и логика
                                    работы которой максимально схожи с Qlik Sense
                                </div>
                            </div>
                        </div>
                        <div className="tool-links">
                            <div className="tool-logo">
                                <img
                                    src={`${process.env.REACT_APP_API_URL}/images/${toolsObj["pixbi"]?.uuid_image}`}
                                    alt={""}
                                />
                            </div>
                            <div className="tool-text">
                                Работа сотрудников каждого блока ведется на выделенном для
                                этого сервере PIX BI, перейти на нужный ресурс вы можете
                                по ссылкам:
                            </div>
                            <div className="tool-buttons">
                                {toolsObj["pixbi"]?.block_links?.map(
                                    (el: BlockLinkDto, index: number) => (
                                        <Button
                                            key={index}
                                            size="m"
                                            view="primary"
                                            form="round"
                                            iconRight={IconForward}
                                            label={el.s_name}
                                            onClick={() =>
                                                window.open(el.block_tool_link, "_blank")
                                            }
                                        />
                                    )
                                )}
                                {/*<Button*/}
                                {/*        size='m'*/}
                                {/*        view='primary'*/}
                                {/*        form='round'*/}
                                {/*        iconRight={IconForward}*/}
                                {/*        label='БРД'*/}
                                {/*/>*/}
                                {/*<Button*/}
                                {/*        size='m'*/}
                                {/*        view='primary'*/}
                                {/*        form='round'*/}
                                {/*        iconRight={IconForward}*/}
                                {/*        label='БЛПС'*/}
                                {/*/>*/}
                                {/*<Button*/}
                                {/*        size='m'*/}
                                {/*        view='primary'*/}
                                {/*        form='round'*/}
                                {/*        iconRight={IconForward}*/}
                                {/*        label='КФ'*/}
                                {/*/>*/}
                            </div>
                        </div>
                        <div className="grey-links">
                            <div className="big-block">
                                <div className="cup"></div>
                                <div className="b-b-text">
                                    <div className="b-b-title">
                                        {`${divideNumIntoCategories(
                                            +totalAppPix
                                        )} ${enumerate(totalAppPix, [
                                            "Приложение создано",
                                            "Приложения созданы",
                                            "Приложений созданы",
                                        ])}`}
                                        <br /> с помощью PIX BI
                                    </div>
                                    <div className="b-b-desc">
                                        {`${divideNumIntoCategories(
                                            toolsObj["pixbi"]?.application_count ?? 0
                                        )} ${enumerate(
                                            toolsObj["pixbi"]?.application_count
                                                ? String(
                                                      toolsObj["pixbi"]?.application_count
                                                  )
                                                : "0",
                                            [
                                                "из них доступно в “Портфолио”. Вы можете",
                                                "из них доступны в “Портфолио”. Вы можете",
                                                "из них доступны в “Портфолио”. Вы можете",
                                            ]
                                        )}`}
                                        <br /> познакомиться с ними перейдя по ссылке
                                    </div>
                                    <div className="b-b-button">
                                        <Button
                                            size="m"
                                            view="primary"
                                            form="round"
                                            iconRight={IconForward}
                                            label="Перейти"
                                            onClick={() =>
                                                window.open(
                                                    basepath +
                                                        `portfolio?tools=${toolsObj["pixbi"]?.guid}`,
                                                    "_blank"
                                                )
                                            }
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="small-blocks">
                                <div>
                                    <div className="s-b-title">
                                        <svg
                                            width="16"
                                            height="16"
                                            viewBox="0 0 16 16"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <g clipPath="url(#clip0_14513_62764)">
                                                <path
                                                    fillRule="evenodd"
                                                    clipRule="evenodd"
                                                    d="M0 0V6H7V4H9V6H11.5C11.5 6.55228 11.0523 7 10.5 7H5.5C3.84315 7 2.5 8.34315 2.5 10H0V16H7V14H9V16H16V10H9V12H7V10H4.5C4.5 9.44772 4.94772 9 5.5 9H10.5C12.1569 9 13.5 7.65685 13.5 6H16V0H9V2H7V0H0ZM5 2H2V4H5V2ZM14 2H11V4H14V2ZM2 12H5V14H2V12ZM14 12H11V14H14V12Z"
                                                    fill="#0078D2"
                                                />
                                            </g>
                                            <defs>
                                                <clipPath id="clip0_14513_62764">
                                                    <rect
                                                        width="16"
                                                        height="16"
                                                        fill="white"
                                                    />
                                                </clipPath>
                                            </defs>
                                        </svg>
                                        База знаний
                                    </div>
                                    <div className="s-b-text">
                                        Изучите обучающие материалы и инструкции, которые
                                        помогут вам при работе с инструментом
                                    </div>
                                    <Button
                                        size="m"
                                        view="clear"
                                        label="Перейти"
                                        iconRight={IconForward}
                                        onClick={() =>
                                            window.open(
                                                basepath +
                                                    `knowledge?tools=${toolsObj["pixbi"]?.guid}`,
                                                "_blank"
                                            )
                                        }
                                    />
                                </div>
                                <div>
                                    <div className="s-b-title">
                                        <svg
                                            width="17"
                                            height="16"
                                            viewBox="0 0 17 16"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <g clipPath="url(#clip0_14513_62771)">
                                                <path
                                                    fillRule="evenodd"
                                                    clipRule="evenodd"
                                                    d="M1.47635 1C0.845171 1 0.333496 1.51659 0.333496 2.15385V11.3846C0.333496 12.0219 0.845171 12.5385 1.47635 12.5385H7.19064V13.6923H4.90492V16H11.7621V13.6923H9.47635V12.5385H15.1906C15.8218 12.5385 16.3335 12.0219 16.3335 11.3846V2.15385C16.3335 1.51659 15.8218 1 15.1906 1H1.47635ZM14.0478 3.30769H2.61921V10.2308H14.0478V3.30769Z"
                                                    fill="#0078D2"
                                                />
                                            </g>
                                            <defs>
                                                <clipPath id="clip0_14513_62771">
                                                    <rect
                                                        width="16"
                                                        height="16"
                                                        fill="white"
                                                        transform="translate(0.333496)"
                                                    />
                                                </clipPath>
                                            </defs>
                                        </svg>
                                        Обучение
                                    </div>
                                    <div className="s-b-text">
                                        Не знаете, с чего начать работу с инструментом?
                                        Записывайтесь на онлайн-курсы обучения
                                    </div>
                                    <Button
                                        size="m"
                                        view="clear"
                                        // label="Перейти"
                                        label="Скоро появится"
                                        disabled
                                        iconRight={IconForward}
                                        onClick={() =>
                                            window.open(
                                                PageRoutes.DataLab_EducationPage,
                                                "_target"
                                            )
                                        }
                                    />
                                </div>
                                <div>
                                    <div className="s-b-title">
                                        <svg
                                            width="17"
                                            height="16"
                                            viewBox="0 0 17 16"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <g clipPath="url(#clip0_14652_106395)">
                                                <path
                                                    fillRule="evenodd"
                                                    clipRule="evenodd"
                                                    d="M1.80936 1C1.17818 1 0.666504 1.51659 0.666504 2.15385V11.3846C0.666504 12.0219 1.17818 12.5385 1.80936 12.5385H7.52365V13.6923H5.23793V16H12.0951V13.6923H9.80936V12.5385H15.5236C16.1548 12.5385 16.6665 12.0219 16.6665 11.3846V2.15385C16.6665 1.51659 16.1548 1 15.5236 1H1.80936ZM14.3808 3.30769H2.95222V10.2308H14.3808V3.30769Z"
                                                    fill="#0078D2"
                                                />
                                            </g>
                                            <defs>
                                                <clipPath id="clip0_14652_106395">
                                                    <rect
                                                        width="16"
                                                        height="16"
                                                        fill="white"
                                                        transform="translate(0.666504)"
                                                    />
                                                </clipPath>
                                            </defs>
                                        </svg>
                                        Консультации
                                    </div>
                                    <div className="s-b-text">
                                        Сотрудники лаборатории расскажут с чего начать или
                                        как пройти определённый этап процесса
                                    </div>
                                    <Button
                                        size="m"
                                        view="clear"
                                        label="Перейти"
                                        iconRight={IconForward}
                                        onClick={() => setIsRequestModalOpen(true)}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="tools-title">Информационные ссылки</div>
                        <div className="tools-links-desc">
                            По любым вопросам, связанным с работой в инструменте, вы
                            можете обратиться за консультацией на текущем портале или
                            попытаться найти ответы в базе знаний вендора и в
                            telegram-канале инструмента
                        </div>
                        <div className="tools-links-buttons">
                            <Button
                                size="m"
                                view="ghost"
                                form="round"
                                iconRight={IconForward}
                                label="Сайт вендора"
                                onClick={() =>
                                    window.open(
                                        "https://pix.ru/products/pix-bi/bi/",
                                        "_blank"
                                    )
                                }
                            />
                            <Button
                                size="m"
                                view="ghost"
                                form="round"
                                iconRight={IconForward}
                                label="Документация PIX BI"
                                onClick={() =>
                                    window.open(
                                        "https://docs.pixrobotics.com/articles/#!bi/welcome",
                                        "_blank"
                                    )
                                }
                            />
                            <Button
                                size="m"
                                view="ghost"
                                form="round"
                                iconRight={() => <img src={iconQrCode} alt={""} />}
                                label="Telegram"
                                onClick={() => setIsPixQrModalOpen(true)}
                            />
                            <Modal
                                isOpen={isPixQrModalOpen}
                                hasOverlay
                                onClickOutside={() => setIsPixQrModalOpen(false)}
                                onEsc={() => setIsPixQrModalOpen(false)}
                            >
                                <div className="modal-qr">
                                    <img src={iconPixQrCode} alt={""} />
                                    <br />
                                    @BI_PIX_Chat
                                    <Button
                                        size="s"
                                        view="clear"
                                        onlyIcon
                                        iconRight={IconClose}
                                        onClick={() => setIsPixQrModalOpen(false)}
                                    />
                                </div>
                            </Modal>
                        </div>
                        <div className="tools-table">
                            <div className="tools-title">
                                Подробные характеристики PIX BI
                            </div>
                            <div className="tools-table-row">
                                <div className="t-t-title">
                                    Структура хранения приложений
                                </div>
                                <div className="t-t-desc">
                                    Создание приложений ведётся в директории
                                    «Персональная», из которой готовые приложения
                                    публикуются в другие директории для общего доступа
                                </div>
                            </div>
                            <div className="tools-table-row">
                                <div className="t-t-title">Источники данных</div>
                                <div className="t-t-desc">
                                    Доступна загрузка данных системных источников
                                    различного типа из магазина данных, несистемных — из
                                    файлов Excel
                                </div>
                            </div>
                            <div className="tools-table-row">
                                <div className="t-t-title">
                                    Загрузка и преобразование данных
                                </div>
                                <div className="t-t-desc">
                                    <ul>
                                        <li>
                                            Данные из источников загружаются в наборы
                                            данных, существующие вне приложений
                                        </li>
                                        <li>
                                            Доступны базовые преобразования данных после
                                            загрузки из источника без написания кода, для
                                            более сложных требуется знание SQL
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="tools-table-row">
                                <div className="t-t-title">Обновление данных</div>
                                <div className="t-t-desc">
                                    Данные могут обновляться пользователем в ручном
                                    режиме, а также автоматически по расписанию (его может
                                    настроить администратор)
                                </div>
                            </div>
                            <div className="tools-table-row">
                                <div className="t-t-title">Модель данных</div>
                                <div className="t-t-desc">
                                    <ul>
                                        <li>
                                            Объединение наборов данных в модель
                                            осуществляется вручную с указанием связываемых
                                            таблиц, ключевых полей и типа связи (при этом
                                            нет связи типа «многие ко многим»)
                                        </li>
                                        <li>
                                            Получаемая модель данных визуализируется
                                            аналогично Qlik Sense
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="tools-table-row">
                                <div className="t-t-title">Визуализации</div>
                                <div className="t-t-desc">
                                    <ul>
                                        <li>
                                            Наличие множества объектов стандартной
                                            визуализации
                                        </li>
                                        <li>
                                            Гибкое расширение возможностей визуализации на
                                            основе шаблонов библиотеки ECharts
                                        </li>
                                        <li>
                                            Наличие встроенного языка выражений,
                                            позволяющего создавать участвующие в
                                            визуализации расчётные показатели в знакомом
                                            по работе с продуктами Qlik синтаксисе
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="tools-table-row">
                                <div className="t-t-title">Фильтрация</div>
                                <div className="t-t-desc">
                                    <ul>
                                        <li>
                                            Сквозная фильтрация делает процесс
                                            использования приложения комфортнее
                                        </li>
                                        <li>
                                            Привычная по работе с продуктами Qlik цветовая
                                            индикация выборок упрощает понимание
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="tools-table-row">
                                <div className="t-t-title">Таблицы как в Excel</div>
                                <div className="t-t-desc">
                                    Встроенный конструктор прямых и плоских таблиц в
                                    режиме просмотра. Выгрузка сводных таблиц в Excel с
                                    возможностью работы с ними как со сводными в Excel
                                </div>
                            </div>
                            <div className="tools-table-row">
                                <div className="t-t-title">Быстрое развитие</div>
                                <div className="t-t-desc">
                                    Релизы выходят регулярно, вендор при составлении
                                    дорожной карты учитывает требования нашей компании
                                </div>
                            </div>
                        </div>
                    </div>
                )}
                {activeTab === "qlik" && (
                    <div className="tools-content">
                        <div className="tools-title">Преимущества Qlik Sense</div>
                        <div className="tools-advantages">
                            <div>
                                <div className="adv-title">
                                    <div className="adv-number">1</div>
                                    Известный разработчик
                                </div>
                                <div className="adv-text">
                                    Используйте систему от одного из мировых лидеров BI
                                    (но помните о риске потери наработок из-за санкций)
                                </div>
                            </div>
                            <div>
                                <div className="adv-title">
                                    <div className="adv-number">2</div>
                                    Развитая поддержка
                                </div>
                                <div className="adv-text">
                                    Изучайте актуальную документацию от вендора и
                                    задавайте экспертам вопросы на русском и английском
                                    языке
                                </div>
                            </div>
                            <div>
                                <div className="adv-title">
                                    <div className="adv-number">3</div>
                                    Простой в использовании
                                </div>
                                <div className="adv-text">
                                    Создавайте приложения без продвинутых навыков
                                    программирования
                                </div>
                            </div>
                            <div>
                                <div className="adv-title">
                                    <div className="adv-number">4</div>
                                    Внутренняя экспертиза
                                </div>
                                <div className="adv-text">
                                    Обратитесь к опыту коллег, разработавших множество
                                    приложений и нестандартных визуализаций в системе
                                </div>
                            </div>
                        </div>
                        <div className="tool-links">
                            <div className="tool-logo">
                                <img
                                    src={`${process.env.REACT_APP_API_URL}/images/${toolsObj["qlik"]?.uuid_image}`}
                                    alt={""}
                                />
                            </div>
                            <div className="tool-text">
                                Работа сотрудников каждого блока ведется на выделенном для
                                этого сервере PIX BI, перейти на нужный ресурс вы можете
                                по ссылкам:
                            </div>
                            <div className="tool-buttons">
                                {toolsObj["qlik"]?.block_links?.map(
                                    (el: BlockLinkDto, index: number) => (
                                        <Button
                                            key={index}
                                            size="m"
                                            view="primary"
                                            form="round"
                                            iconRight={IconForward}
                                            label={el.s_name}
                                            onClick={() =>
                                                window.open(el.block_tool_link, "_blank")
                                            }
                                        />
                                    )
                                )}
                                {/*<Button*/}
                                {/*        size='m'*/}
                                {/*        view='primary'*/}
                                {/*        form='round'*/}
                                {/*        iconRight={IconForward}*/}
                                {/*        label='БЛПС'*/}
                                {/*/>*/}
                                {/*<Button*/}
                                {/*        size='m'*/}
                                {/*        view='primary'*/}
                                {/*        form='round'*/}
                                {/*        iconRight={IconForward}*/}
                                {/*        label='КФ'*/}
                                {/*/>*/}
                            </div>
                        </div>
                        <div className="grey-links">
                            <div className="big-block">
                                <div className="cup"></div>
                                <div className="b-b-text">
                                    <div className="b-b-title">
                                        {`${divideNumIntoCategories(
                                            +totalAppQlik
                                        )} ${enumerate(totalAppQlik, [
                                            "Приложение создано",
                                            "Приложения созданы",
                                            "Приложений созданы",
                                        ])}`}
                                        <br /> с помощью Qlik Sense
                                    </div>
                                    <div className="b-b-desc">
                                        {`${divideNumIntoCategories(
                                            toolsObj["qlik"]?.application_count ?? 0
                                        )} ${enumerate(
                                            toolsObj["qlik"]?.application_count
                                                ? String(
                                                      toolsObj["pixbi"]?.application_count
                                                  )
                                                : "0",
                                            [
                                                "из них доступно в “Портфолио”. Вы можете",
                                                "из них доступны в “Портфолио”. Вы можете",
                                                "из них доступны в “Портфолио”. Вы можете",
                                            ]
                                        )}`}
                                        <br /> познакомиться с ними перейдя по ссылке
                                    </div>
                                    <div className="b-b-button">
                                        <Button
                                            size="m"
                                            view="primary"
                                            form="round"
                                            iconRight={IconForward}
                                            label="Перейти"
                                            onClick={() =>
                                                window.open(
                                                    basepath +
                                                        `portfolio?tools=${toolsObj["qlik"]?.guid}`,
                                                    "_blank"
                                                )
                                            }
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="small-blocks">
                                <div>
                                    <div className="s-b-title">
                                        <svg
                                            width="16"
                                            height="16"
                                            viewBox="0 0 16 16"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <g clipPath="url(#clip0_14513_62764)">
                                                <path
                                                    fillRule="evenodd"
                                                    clipRule="evenodd"
                                                    d="M0 0V6H7V4H9V6H11.5C11.5 6.55228 11.0523 7 10.5 7H5.5C3.84315 7 2.5 8.34315 2.5 10H0V16H7V14H9V16H16V10H9V12H7V10H4.5C4.5 9.44772 4.94772 9 5.5 9H10.5C12.1569 9 13.5 7.65685 13.5 6H16V0H9V2H7V0H0ZM5 2H2V4H5V2ZM14 2H11V4H14V2ZM2 12H5V14H2V12ZM14 12H11V14H14V12Z"
                                                    fill="#0078D2"
                                                />
                                            </g>
                                            <defs>
                                                <clipPath id="clip0_14513_62764">
                                                    <rect
                                                        width="16"
                                                        height="16"
                                                        fill="white"
                                                    />
                                                </clipPath>
                                            </defs>
                                        </svg>
                                        База знаний
                                    </div>
                                    <div className="s-b-text">
                                        Изучите обучающие материалы и инструкции, которые
                                        помогут вам при работе с инструментом
                                    </div>
                                    <Button
                                        size="m"
                                        view="clear"
                                        label="Перейти"
                                        iconRight={IconForward}
                                        onClick={() =>
                                            window.open(
                                                basepath +
                                                    `knowledge?tools=${toolsObj["qlik"]?.guid}`,
                                                "_blank"
                                            )
                                        }
                                    />
                                </div>
                                <div>
                                    <div className="s-b-title">
                                        <svg
                                            width="17"
                                            height="16"
                                            viewBox="0 0 17 16"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <g clipPath="url(#clip0_14513_62771)">
                                                <path
                                                    fillRule="evenodd"
                                                    clipRule="evenodd"
                                                    d="M1.47635 1C0.845171 1 0.333496 1.51659 0.333496 2.15385V11.3846C0.333496 12.0219 0.845171 12.5385 1.47635 12.5385H7.19064V13.6923H4.90492V16H11.7621V13.6923H9.47635V12.5385H15.1906C15.8218 12.5385 16.3335 12.0219 16.3335 11.3846V2.15385C16.3335 1.51659 15.8218 1 15.1906 1H1.47635ZM14.0478 3.30769H2.61921V10.2308H14.0478V3.30769Z"
                                                    fill="#0078D2"
                                                />
                                            </g>
                                            <defs>
                                                <clipPath id="clip0_14513_62771">
                                                    <rect
                                                        width="16"
                                                        height="16"
                                                        fill="white"
                                                        transform="translate(0.333496)"
                                                    />
                                                </clipPath>
                                            </defs>
                                        </svg>
                                        Обучение
                                    </div>
                                    <div className="s-b-text">
                                        Не знаете, с чего начать работу с инструментом?
                                        Записывайтесь на онлайн-курсы обучения
                                    </div>
                                    <Button
                                        size="m"
                                        view="clear"
                                        // label="Перейти"
                                        label="Скоро появится"
                                        disabled
                                        iconRight={IconForward}
                                        onClick={() =>
                                            window.open(
                                                PageRoutes.DataLab_EducationPage,
                                                "_target"
                                            )
                                        }
                                    />
                                </div>
                                <div>
                                    <div className="s-b-title">
                                        <svg
                                            width="17"
                                            height="16"
                                            viewBox="0 0 17 16"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <g clipPath="url(#clip0_14652_106395)">
                                                <path
                                                    fillRule="evenodd"
                                                    clipRule="evenodd"
                                                    d="M1.80936 1C1.17818 1 0.666504 1.51659 0.666504 2.15385V11.3846C0.666504 12.0219 1.17818 12.5385 1.80936 12.5385H7.52365V13.6923H5.23793V16H12.0951V13.6923H9.80936V12.5385H15.5236C16.1548 12.5385 16.6665 12.0219 16.6665 11.3846V2.15385C16.6665 1.51659 16.1548 1 15.5236 1H1.80936ZM14.3808 3.30769H2.95222V10.2308H14.3808V3.30769Z"
                                                    fill="#0078D2"
                                                />
                                            </g>
                                            <defs>
                                                <clipPath id="clip0_14652_106395">
                                                    <rect
                                                        width="16"
                                                        height="16"
                                                        fill="white"
                                                        transform="translate(0.666504)"
                                                    />
                                                </clipPath>
                                            </defs>
                                        </svg>
                                        Консультации
                                    </div>
                                    <div className="s-b-text">
                                        Сотрудники лаборатории расскажут с чего начать или
                                        как пройти определённый этап процесса
                                    </div>
                                    <Button
                                        size="m"
                                        view="clear"
                                        label="Перейти"
                                        iconRight={IconForward}
                                        onClick={() => setIsRequestModalOpen(true)}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="tools-title">Информационные ссылки</div>
                        <div className="tools-links-desc">
                            По любым вопросам, связанным с работой в инструменте, вы
                            можете обратиться за консультацией на текущем портале или
                            попытаться найти ответы в базе знаний вендора и в
                            telegram-канале инструмента
                        </div>
                        <div className="tools-links-buttons">
                            <Button
                                size="m"
                                view="ghost"
                                form="round"
                                iconRight={IconForward}
                                label="Сайт вендора"
                                onClick={() =>
                                    window.open("https://help.qlik.com/ru-RU/", "_blank")
                                }
                            />
                            <Button
                                size="m"
                                view="ghost"
                                form="round"
                                iconRight={() => <img src={iconQrCode} alt={""} />}
                                label="Telegram"
                                onClick={() => setIsQlikQrModalOpen(true)}
                            />

                            <Modal
                                isOpen={isQlikQrModalOpen}
                                hasOverlay
                                onClickOutside={() => setIsQlikQrModalOpen(false)}
                                onEsc={() => setIsQlikQrModalOpen(false)}
                            >
                                <div className="modal-qr">
                                    <img src={iconQlikQrCode} alt={""} />
                                    <br />
                                    @qlikbi_chat
                                    <Button
                                        size="s"
                                        view="clear"
                                        onlyIcon
                                        iconRight={IconClose}
                                        onClick={() => setIsQlikQrModalOpen(false)}
                                    />
                                </div>
                            </Modal>
                        </div>
                        <div className="tools-table">
                            <div className="tools-title">
                                Подробные характеристики Qlik Sense
                            </div>
                            <div className="tools-table-row">
                                <div className="t-t-title">
                                    Структура хранения приложений
                                </div>
                                <div className="t-t-desc">
                                    Создание приложений ведётся в потоке «Личное», из
                                    которого готовые приложения публикуются в другие
                                    потоки для общего доступа
                                </div>
                            </div>
                            <div className="tools-table-row">
                                <div className="t-t-title">Источники данных</div>
                                <div className="t-t-desc">
                                    Доступна загрузка данных системных источников
                                    различного типа из магазина данных, несистемных — из
                                    файлов Excel
                                </div>
                            </div>
                            <div className="tools-table-row">
                                <div className="t-t-title">
                                    Загрузка и преобразование данных
                                </div>
                                <div className="t-t-desc">
                                    <ul>
                                        <li>
                                            Данные из источников загружаются в наборы
                                            данных, существующие вне приложений
                                        </li>
                                        <li>
                                            Доступны базовые преобразования данных после
                                            загрузки из источника без написания кода, для
                                            более сложных требуется знание SQL
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="tools-table-row">
                                <div className="t-t-title">Обновление данных</div>
                                <div className="t-t-desc">
                                    Данные могут обновляться пользователем в ручном
                                    режиме, а также автоматически по расписанию (его может
                                    настроить администратор)
                                </div>
                            </div>
                            <div className="tools-table-row">
                                <div className="t-t-title">Модель данных</div>
                                <div className="t-t-desc">
                                    <ul>
                                        <li>
                                            Объединение наборов данных в модель
                                            осуществляется вручную с указанием связываемых
                                            таблиц, ключевых полей и типа связи (при этом
                                            нет связи типа «многие ко многим»)
                                        </li>
                                        <li>
                                            Получаемая модель данных визуализируется
                                            аналогично Qlik Sense
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="tools-table-row">
                                <div className="t-t-title">Визуализации</div>
                                <div className="t-t-desc">
                                    <ul>
                                        <li>
                                            Наличие множества объектов стандартной
                                            визуализации
                                        </li>
                                        <li>
                                            Дополнение возможностей визуализации на основе
                                            отдельно разработанных расширений
                                        </li>
                                        <li>
                                            Наличие встроенного языка выражений,
                                            позволяющего создавать участвующие в
                                            визуализации расчётные показатели (даже самые
                                            сложные)
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="tools-table-row">
                                <div className="t-t-title">Фильтрация</div>
                                <div className="t-t-desc">
                                    <ul>
                                        <li>
                                            Сквозная фильтрация делает процесс
                                            использования приложения комфортнее
                                        </li>
                                        <li>
                                            Цветовая индикация выборок упрощает понимание
                                            текущих выборок, доступных и недоступных
                                            значений полей при этих выборках.
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
                {activeTab === "python" && (
                    <div className="tools-content">
                        <div className="tools-title">Преимущества Python</div>
                        <div className="tools-advantages">
                            <div>
                                <div className="adv-title">
                                    <div className="adv-number">1</div>
                                    Продвинутая аналитика
                                </div>
                                <div className="adv-text">
                                    Обрабатывайте данные, выявляйте закономерности в
                                    данных и стройте модели машинного обучения
                                </div>
                            </div>
                            <div>
                                <div className="adv-title">
                                    <div className="adv-number">2</div>
                                    Развёрнутая поддержка
                                </div>
                                <div className="adv-text">
                                    Привлекайте специалиста Лаборатории данных для
                                    подготовки ваших данных, проверки гипотез и настройки
                                    моделей машинного обучения
                                </div>
                            </div>
                            <div>
                                <div className="adv-title">
                                    <div className="adv-number">3</div>
                                    Среда разработки
                                </div>
                                <div className="adv-text">
                                    Разрабатывайте и представляйте проекты Data Science в
                                    интерактивном виде, используя Jupyter Notebook
                                </div>
                            </div>
                            <div>
                                <div className="adv-title">
                                    <div className="adv-number">4</div>
                                    Развёртывание и эксплуатация ML-моделей
                                </div>
                                <div className="adv-text">
                                    Используйте Airflow, исполняемый скрипт или API для
                                    непосредственного доступа к модели для промышленного
                                    использования моделей
                                </div>
                            </div>
                        </div>
                        <div className="tool-links">
                            <div className="tool-logo">
                                <img
                                    src={`${process.env.REACT_APP_API_URL}/images/${toolsObj["python"]?.uuid_image}`}
                                    alt={""}
                                />
                            </div>
                            <div className="tool-text">
                                Работа сотрудников каждого блока ведется на выделенном для
                                этого сервере Python, перейти на нужный ресурс вы можете
                                по ссылкам:
                            </div>
                            <div className="tool-buttons">
                                {toolsObj["python"]?.block_links?.map(
                                    (el: BlockLinkDto, index: number) => (
                                        <Button
                                            key={index}
                                            size="m"
                                            view="primary"
                                            form="round"
                                            iconRight={IconForward}
                                            label={el.s_name}
                                            onClick={() =>
                                                window.open(el.block_tool_link, "_blank")
                                            }
                                        />
                                    )
                                )}
                                {/*<Button*/}
                                {/*        size='m'*/}
                                {/*        view='primary'*/}
                                {/*        form='round'*/}
                                {/*        iconRight={IconForward}*/}
                                {/*        label='БЛПС'*/}
                                {/*/>*/}
                                {/*<Button*/}
                                {/*        size='m'*/}
                                {/*        view='primary'*/}
                                {/*        form='round'*/}
                                {/*        iconRight={IconForward}*/}
                                {/*        label='КФ'*/}
                                {/*/>*/}
                            </div>
                        </div>
                        <div className="grey-links">
                            <div className="big-block">
                                <div className="cup"></div>
                                <div className="b-b-text">
                                    <div className="b-b-title">
                                        {divideNumIntoCategories(+totalAppPyton)} ML{" "}
                                        {enumerate(String(totalAppPyton), [
                                            "модель",
                                            "модели",
                                            "моделей",
                                        ])}{" "}
                                        созданы
                                        <br /> с помощью Python
                                    </div>
                                    <div className="b-b-desc">
                                        {`${divideNumIntoCategories(
                                            toolsObj["python"]?.application_count ?? 0
                                        )} ${enumerate(
                                            toolsObj["python"]?.application_count
                                                ? String(
                                                      toolsObj["python"]
                                                          ?.application_count
                                                  )
                                                : "0",
                                            [
                                                "из них доступно в “Портфолио”. Вы можете",
                                                "из них доступны в “Портфолио”. Вы можете",
                                                "из них доступны в “Портфолио”. Вы можете",
                                            ]
                                        )}`}
                                        <br /> познакомиться с ними перейдя по ссылке
                                    </div>
                                    <div className="b-b-button">
                                        <Button
                                            size="m"
                                            view="primary"
                                            form="round"
                                            iconRight={IconForward}
                                            label="Перейти"
                                            onClick={() =>
                                                window.open(
                                                    basepath +
                                                        `portfolio?tools=${toolsObj["python"]?.guid}`,
                                                    "_blank"
                                                )
                                            }
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="small-blocks python">
                                <div>
                                    <div className="s-b-title">
                                        <svg
                                            width="16"
                                            height="16"
                                            viewBox="0 0 16 16"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <g clipPath="url(#clip0_14513_62764)">
                                                <path
                                                    fillRule="evenodd"
                                                    clipRule="evenodd"
                                                    d="M0 0V6H7V4H9V6H11.5C11.5 6.55228 11.0523 7 10.5 7H5.5C3.84315 7 2.5 8.34315 2.5 10H0V16H7V14H9V16H16V10H9V12H7V10H4.5C4.5 9.44772 4.94772 9 5.5 9H10.5C12.1569 9 13.5 7.65685 13.5 6H16V0H9V2H7V0H0ZM5 2H2V4H5V2ZM14 2H11V4H14V2ZM2 12H5V14H2V12ZM14 12H11V14H14V12Z"
                                                    fill="#0078D2"
                                                />
                                            </g>
                                            <defs>
                                                <clipPath id="clip0_14513_62764">
                                                    <rect
                                                        width="16"
                                                        height="16"
                                                        fill="white"
                                                    />
                                                </clipPath>
                                            </defs>
                                        </svg>
                                        База знаний
                                    </div>
                                    <div className="s-b-text">
                                        Изучите обучающие материалы и инструкции, которые
                                        помогут вам при работе с инструментом
                                    </div>
                                    <Button
                                        size="m"
                                        view="clear"
                                        label="Перейти"
                                        iconRight={IconForward}
                                        onClick={() =>
                                            window.open(
                                                basepath +
                                                    `knowledge?tools=${toolsObj["python"]?.guid}`,
                                                "_blank"
                                            )
                                        }
                                    />
                                </div>
                                <div>
                                    <div className="s-b-title">
                                        <svg
                                            width="17"
                                            height="16"
                                            viewBox="0 0 17 16"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <g clipPath="url(#clip0_14513_62771)">
                                                <path
                                                    fillRule="evenodd"
                                                    clipRule="evenodd"
                                                    d="M1.47635 1C0.845171 1 0.333496 1.51659 0.333496 2.15385V11.3846C0.333496 12.0219 0.845171 12.5385 1.47635 12.5385H7.19064V13.6923H4.90492V16H11.7621V13.6923H9.47635V12.5385H15.1906C15.8218 12.5385 16.3335 12.0219 16.3335 11.3846V2.15385C16.3335 1.51659 15.8218 1 15.1906 1H1.47635ZM14.0478 3.30769H2.61921V10.2308H14.0478V3.30769Z"
                                                    fill="#0078D2"
                                                />
                                            </g>
                                            <defs>
                                                <clipPath id="clip0_14513_62771">
                                                    <rect
                                                        width="16"
                                                        height="16"
                                                        fill="white"
                                                        transform="translate(0.333496)"
                                                    />
                                                </clipPath>
                                            </defs>
                                        </svg>
                                        Обучение
                                    </div>
                                    <div className="s-b-text">
                                        Не знаете, с чего начать работу с инструментом?
                                        Записывайтесь на онлайн-курсы обучения
                                    </div>
                                    <Button
                                        size="m"
                                        view="clear"
                                        // label="Перейти"
                                        label="Скоро появится"
                                        disabled
                                        iconRight={IconForward}
                                        onClick={() =>
                                            window.open(
                                                PageRoutes.DataLab_EducationPage,
                                                "_target"
                                            )
                                        }
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="tools-title">Информационные ссылки</div>
                        <div className="tools-links-desc">
                            По любым вопросам, связанным с работой в инструменте, вы
                            можете обратиться за консультацией на текущем портале или
                            попытаться найти ответы в базе знаний вендора
                        </div>
                        <div className="tools-links-buttons">
                            <Button
                                size="m"
                                view="ghost"
                                form="round"
                                iconRight={IconForward}
                                label={
                                    activeTab === "python"
                                        ? "База знаний Python"
                                        : "Консультация"
                                }
                                onClick={
                                    activeTab === "python"
                                        ? () =>
                                              window.open(
                                                  "https://pythonru.com",
                                                  "_blank",
                                                  "noreferrer"
                                              )
                                        : () => setIsRequestModalOpen(true)
                                }
                            />
                        </div>
                        <div className="tools-table">
                            <div className="tools-title">
                                Подробные характеристики Python
                            </div>
                            <div className="tools-table-row">
                                <div className="t-t-title">Источники данных</div>
                                <div className="t-t-desc">
                                    Доступна загрузка данных системных источников
                                    различного типа (базы и хранилища данных), несистемных
                                    – из локальных файлов (.xlsx, .csv, .txt, .html), а
                                    также сайтов
                                </div>
                            </div>
                            <div className="tools-table-row">
                                <div className="t-t-title">Особенности языка</div>
                                <div className="t-t-desc">
                                    Python отличается простотой синтаксиса и обширными
                                    библиотеками алгоритмов искусственного интеллекта
                                </div>
                            </div>
                        </div>
                    </div>
                )}
                <div className="tools-switcher">
                    <div className="tools-switcher-title">Другие инструменты</div>
                    <div className="tools-switcher-content">
                        {activeTab !== "pixbi" && (
                            <Button
                                iconRight={IconPix}
                                view={"clear"}
                                onlyIcon
                                onClick={() => handlerSwitchTool("pixbi")}
                            />
                        )}
                        {activeTab !== "qlik" && (
                            <Button
                                iconRight={IconQlik}
                                view={"clear"}
                                onlyIcon
                                onClick={() => handlerSwitchTool("qlik")}
                            />
                        )}
                        {activeTab !== "python" && (
                            <Button
                                iconRight={IconPython}
                                view={"clear"}
                                onlyIcon
                                onClick={() => handlerSwitchTool("python")}
                            />
                        )}
                    </div>
                </div>
            </PageContentWrapper>
            <SupportModal
                mode={"short"}
                handlerClose={() => setIsRequestModalOpen(false)}
                open={isRequestModalOpen}
            />
        </>
    );
};
