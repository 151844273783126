import { IconComponent } from "@consta/uikit/__internal__/src/icons/Icon";

import { IconEdit } from "@consta/uikit/IconEdit";
import { IconTrash } from "@consta/uikit/IconTrash";

export type ContextMenuItem = {
    label: string;
    icon: IconComponent;
    status?: "alert";
    value: string;
};

export const ContextMenuItems: ContextMenuItem[] = [
    {
        label: "Редактировать",
        value: "EDIT",
        icon: IconEdit,
    },
    {
        label: "Удалить",
        value: "DELETE",
        icon: IconTrash,
        status: "alert",
    },
];
