import React from "react";
import classes from "./NotificationsBar.module.css";
import { SnackBar } from "@consta/uikit/SnackBar";
import { deleteNotification } from "../../store/general";
import { useAppDispatch, useAppSelector } from "../../store";

export const NotificationsBar = () => {
    const dispatch = useAppDispatch();
    const notifications = useAppSelector((state) => state.general.notifications);

    return (
        <div className={classes.snackBar}>
            <SnackBar
                items={notifications}
                onItemClose={(item) => {
                    dispatch(deleteNotification(item.key));
                }}
                getItemAutoClose={() => 5}
                getItemShowProgress={() => "line"}
                getItemMessage={(item) => (
                    <div style={{ wordBreak: "break-word" }}>
                        {item.responseCode} {item.message}
                        <br /> {item.responseUrl && `url запроса ${item.responseUrl}`}
                    </div>
                )}
            />
        </div>
    );
};
