import { useGetTeamQuery } from "store/labApi";

import { TeamBlock } from "./components/TeamBlock";

import contactsBlockContent from "templateContent/mainPage/contactsBlockContent.json";

import "./ContactsBlock.scss";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";

export const ContactsBlock = () => {
    const { data, refetch } = useGetTeamQuery(undefined, {
        refetchOnMountOrArgChange: true,
    });
    const { hash } = useLocation();

    useEffect(() => {
        // существует проблема "перемотки" к блоку контактов при продолжительной загрузке других данных на главной
        if (hash) {
            const element = document.querySelector(hash);
            if (element) {
                element.scrollIntoView({ behavior: "smooth" });
            }
        }
    }, [hash, data]);
    return (
        <>
            {data && <div id={"contacts"}></div>}
            <div className="contacts-block">
                <div className="contacts-block__title">{contactsBlockContent.title}</div>
                {data?.map((contact) => (
                    <TeamBlock
                        key={contact.guid}
                        title={contactsBlockContent.team_title}
                        items={contact.items}
                        resources={contact.resources}
                        emails={contact.emails}
                        contactShortName={contact.s_name}
                        blockId={contact.guid}
                        refetch={refetch}
                    />
                ))}
            </div>
        </>
    );
};
