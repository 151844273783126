import { MetricKey } from "shared/constants/metrics.const";

export const MetricWidgetStaticData = [
    {
        key: MetricKey.STORE_USER_COUNT,
        title: "Количество уникальных пользователей Лаборатории данных",
        description:
            "Количество уникальных пользователей из числа:\n мин 1 раз зашедших в PIX BI\nмин 1 раз зашедших в QS SS\nмин 1 раз зашедших в Jupiter SS",
        entity: ["Пользователь", "Пользователя", "Пользователей"],
        entity_description: "Уже работают в Лаборатории данных",
    },
    {
        key: MetricKey.STORE_APPLICATION_COUNT,
        title: "Количество приложений",
        description:
            "Количество опубликованных приложений (PIX BI SS, QS SS) без ETL, учебных и прочих служебных приложений",
        entity: ["Приложение", "Приложения", "Приложений"],
        entity_description: "Уже создано в Лаборатории данных",
    },
    {
        key: MetricKey.COURSE_LEARNED_COUNT,
        title: "Количество пользователей, прошедших обучение",
        description:
            "Количество уникальных пользователей из КУ, прошедших обучение на курсах Лаборатории данных и Школы Аналитики",
        entity: ["Обучившийся", "Обучившихся", "Обучившихся"],
    },
    {
        key: MetricKey.HELPDESK_CALL_COUNT,
        title: "Количество обращений в техподдержку",
        description: " Количество обращений пользователей из КСУИТ на Бизнес-услугу",
        entity: ["Обращение", "Обращения", "Обращений"],
        entity_description: "Уже обработано командой Лаборатории данных",
    },
    {
        key: MetricKey.STORE_SYSTEM_COUNT,
        title: "Количество подключенных систем",
        description:
            "Количество уникальных Систем-источников, подключенных к Магазину данных",
        entity: ["Система", "Системы", "Систем"],
        entity_description: "Подключено к Магазину данных",
    },
    {
        key: MetricKey.STORE_DATASET_COUNT,
        title: "Количество наборов данных (витрин) в Магазине данных",
        description:
            "Количество уникальных наборов данных (витрин), доступных в Магазине данных (без учета учебных наборов данных)",
        entity: ["Набор данных", "Набора данных", "Наборов данных"],
        entity_description: "Доступно в Магазине данных",
    },
    {
        key: MetricKey.STORE_APPLICATION_PIX_COUNT,
        title: "Количество приложений PIX BI SS",
        description:
            "Количество опубликованных приложений PIX BI SS (без ETL, учебных и служебных приложений)",
        entity: ["Приложение", "Приложения", "Приложений"],
        entity_description: "",
    },
    {
        key: MetricKey.STORE_APPLICATION_QLIK_COUNT,
        title: "Количество приложений Qlik Sense",
        description:
            "Количество опубликованных приложений Qlik Sense (без ETL, учебных и служебных приложений)",
        entity: ["Приложение", "Приложения", "Приложений"],
        entity_description: "",
    },
    {
        key: MetricKey.STORE_APPLICATION_PYTHON_COUNT,
        title: "Количество ML моделей Python",
        description:
            "Количество опубликованных ML моделей Python (без ETL, учебных и служебных ML моделей)",
        entity: ["ML модель", "ML модели", "ML моделей"],
        entity_description: "",
    },
];

export const brdMetricRights = ["BRD-PORTAL"];
export const blpsMetricRights = ["BLPS-PORTAL"];
export const cfMetricRights = ["CF-PORTAL"];
