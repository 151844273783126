import { FC } from "react";
import { PortfolioCardProps } from "./model/PortfolioCard.model";
import "./PortfolioCard.scss";
import { Tag } from "@consta/uikit/Tag";
import { useNavigate } from "react-router-dom";
import moment from "moment";

export const PortfolioCard: FC<PortfolioCardProps> = ({
    id,
    image,
    blockTitle,
    name,
    description,
    isCurrentSLide,
    toolName,
    toolIcon,
    date,
}) => {
    const navigate = useNavigate();
    const basepath =
        process.env.REACT_APP_BASEPATH === "/"
            ? process.env.REACT_APP_BASEPATH
            : `${process.env.REACT_APP_BASEPATH}/`;
    return (
        <div
            className={isCurrentSLide ? "portfolio-card current" : "portfolio-card"}
            onClick={() => navigate(basepath + `portfolio/${id}`)}
        >
            <div className="header-img">
                <img
                    className="main-img"
                    src={`${process.env.REACT_APP_API_URL}/images/${image}`}
                />
                <img
                    className="tool-img"
                    src={`${process.env.REACT_APP_API_URL}/images/${toolIcon}`}
                />
            </div>

            <div className="portfolio-card-content">
                <div>
                    <Tag size="s" label={blockTitle} mode={"info"}></Tag>
                    <div className="content-title">{name}</div>
                    {/* в description может быть html теги, поэтому нужно использовать*/}
                    {/*html-react-parser для отображения форматируемого текста, либо очищать*/}
                    {/*от html тегов и оставлять только текст для отображения */}
                    {/*<div className="content-text">{parse(description || "")}</div>*/}
                    <div className="content-text">
                        {description.replace(/<[^>]*>/g, "")}
                    </div>
                </div>
                <div className="content-bottom">
                    <div>{toolName}</div>
                    <div>{moment(date).format("DD MMMM YYYY")}</div>
                </div>
            </div>
        </div>
    );
};
