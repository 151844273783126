import React, { FC, useEffect, useMemo, useState } from "react";

import { Controller, useForm } from "react-hook-form";

import {
    useGetAdminBlocksQuery,
    useGetCurrentUserRightsQuery,
    useUpdateAdminBlocksMutation,
} from "store/labApi";

import { ChoiceGroup } from "@consta/uikit/ChoiceGroup";
import { Button } from "@consta/uikit/Button";
import { TextField } from "@consta/uikit/TextField";
import { Informer } from "@consta/uikit/Informer";

import { AdminBlockDto } from "store/types/Tool.model";

import { createUpdateAdminBlocksParams } from "./EditToolsModal.utils";

import { checkPermission } from "shared/utils/rights.utils";

import { ErrorType } from "shared/types/ErrorType";

import { blpsToolsRights, brdToolsRights, cfToolsRights } from "./EditToolsModal.const";

import { EditToolsModalProps, EditToolsModalSubmit } from "./model/EditToolsModal.model";

import "./EditToolsModal.scss";
import { Modal } from "@consta/uikit/Modal";
import { IconClose } from "@consta/uikit/IconClose";

export const EditToolsModal: FC<EditToolsModalProps> = ({
    isOpen,
    setIsOpenModal,
    refetchTools,
}) => {
    const { handleSubmit, control, reset } = useForm({
        mode: "onChange",
    });

    const [hasError, setHasError] = useState(false);

    const { data: userData } = useGetCurrentUserRightsQuery();
    const rightsData = userData?.roles ?? undefined;
    const { data: adminBlocksData, refetch } = useGetAdminBlocksQuery();

    const blocks = useMemo(
        () =>
            adminBlocksData?.data?.filter((item) => {
                switch (item.short_name) {
                    case "БРД":
                        return checkPermission(brdToolsRights, rightsData);
                    case "БЛПС":
                        return checkPermission(blpsToolsRights, rightsData);
                    case "КФ":
                        return checkPermission(cfToolsRights, rightsData);
                }
            }),
        [adminBlocksData?.data, rightsData]
    );

    const [currentBlock, setCurrentBlock] = useState<AdminBlockDto | undefined>();

    const [updateAdminBlocks, { error, isLoading }] = useUpdateAdminBlocksMutation();

    const onSubmit = async (data: EditToolsModalSubmit) => {
        const params = createUpdateAdminBlocksParams(data);
        const promises = params.map(async (param) => {
            await updateAdminBlocks({
                id: param.block,
                body: {
                    tools: param.tools,
                },
            }).unwrap();
        });
        await Promise.all(promises);
        await refetch();
        refetchTools();
        !error && setIsOpenModal(false);
    };

    const handleClose = () => {
        reset();
        setIsOpenModal(false);
    };

    useEffect(() => setCurrentBlock(blocks?.[0]), [blocks]);

    useEffect(() => {
        if (error) {
            setHasError(true);
            setTimeout(() => {
                setHasError(false);
            }, 7000);
        }
    }, [error]);

    const pattern = /(https:\/\/)([а-яА-Яa-zA-Z0-9_-])/;

    return (
        <>
            <Modal
                isOpen={isOpen}
                hasOverlay
                onClickOutside={handleClose}
                onEsc={handleClose}
                className="edit-links-tools-modal"
                onOpen={() => {
                    document.body.style.overflow = "hidden"; // отключаем прокрутку при открытии модального окна
                }}
                onClose={() => {
                    document.body.style.overflow = ""; // возвращаем прокрутку при закрытии модального окна
                }}
            >
                {hasError && error && (
                    <Informer
                        status="alert"
                        view="filled"
                        title="Ошибка в редактировании инструментов"
                        label={"data" in error ? (error as ErrorType).data.error : ""}
                    />
                )}
                <div className="edit-links-tools-modal__title">
                    Инструменты Лаборатории данных
                </div>
                <Button
                    size={"s"}
                    view={"clear"}
                    className={"edit-links-tools-modal__close_icon"}
                    onClick={handleClose}
                    onlyIcon={true}
                    iconRight={IconClose}
                />
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="edit-links-tools-modal__body">
                        {blocks && (
                            <ChoiceGroup
                                width={"full"}
                                value={currentBlock}
                                onChange={({ value }) => setCurrentBlock(value)}
                                items={blocks}
                                getItemLabel={(item) => item.short_name}
                                name="blocks"
                            />
                        )}
                        <div className="edit-links-tools-modal__subtitle">
                            Ссылки на инструменты
                        </div>
                        {currentBlock?.tools.map((tool) => {
                            const blockId = tool.uuid_block || currentBlock._uuid;
                            return (
                                <Controller
                                    key={`tool:${tool._uuid} block:${blockId}`}
                                    defaultValue={{ value: tool.link || "" }}
                                    name={`tool:${tool._uuid} block:${blockId}`}
                                    control={control}
                                    rules={{
                                        validate: ({ value }) => {
                                            if (!value) return true;
                                            if (!value.match(pattern)) return "not link";
                                        },
                                    }}
                                    render={({ field, fieldState }) => {
                                        return (
                                            <TextField
                                                placeholder="https://"
                                                className={
                                                    fieldState.error ? "form-error" : ""
                                                }
                                                label={tool.name}
                                                {...field}
                                                value={field.value?.value}
                                            />
                                        );
                                    }}
                                />
                            );
                        })}
                    </div>
                    <div className="edit-links-tools-modal__actions">
                        <Button
                            type="button"
                            label="Отмена"
                            onClick={handleClose}
                            view="ghost"
                        />
                        <Button type="submit" label="Сохранить" loading={isLoading} />
                    </div>
                </form>
            </Modal>
        </>
    );
};
