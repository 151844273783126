import { useEffect } from "react";

import { PageContentWrapper } from "components/PageContentWrapper";
import { HeroBlock } from "./components/HeroBlock";
import { PorfolioBlock } from "./components/PorfolioBlock";

export const PortfolioPage = () => {
    useEffect(() => {
        document.title = 'Портфолио | Лаборатория данных';
    });
    return (
        <>
            <HeroBlock />
            <PageContentWrapper>
                <PorfolioBlock />
            </PageContentWrapper>
        </>
    );
};
