import React, { useEffect } from "react";
import { Button } from "@consta/uikit/Button";
import { IconClose } from "@consta/uikit/IconClose";
import { Modal } from "@consta/uikit/Modal";
import classes from "./RequestForDatasetModal.module.scss";
import { ResponsesEmptyPockets } from "@consta/uikit/ResponsesEmptyPockets";
import { Text } from "@consta/uikit/Text";

type Props = {
    open: boolean;
    setOpen: (open: boolean) => void;
};

// временная модалка заглушка
export const RequestForDatasetModal = ({ setOpen, open }: Props) => {
    // при условном рендеринге onClose не отработает
    useEffect(() => {
        return () => {
            document.body.style.overflow = "";
        };
    }, []);

    return (
        <Modal
            isOpen={open}
            hasOverlay
            onClickOutside={() => setOpen(false)}
            onEsc={() => setOpen(false)}
            onOpen={() => {
                document.body.style.overflow = "hidden"; // отключаем прокрутку при открытии модального окна
            }}
            onClose={() => {
                document.body.style.overflow = ""; // возвращаем прокрутку при закрытии модального окна
            }}
            className={classes.modal}
        >
            <Button
                className={classes.closeIcon}
                iconRight={IconClose}
                onlyIcon
                iconSize="s"
                size="xs"
                view="clear"
                onClick={() => setOpen(false)}
            />
            <ResponsesEmptyPockets
                title={"Заявка на разработку набора данных"}
                description={"Скоро появится"}
                className={classes.emptyPocket}
            />
            <div className={classes.description}>
                <Text size={"xl"} weight={"bold"}>
                    Заявка на разработку набора данных
                </Text>
                <Text>Скоро появится</Text>
            </div>
            <div className={classes.divider}></div>
            <Button
                size="m"
                width={"full"}
                view="primary"
                label={"Понятно"}
                onClick={() => setOpen(false)}
            />
        </Modal>
    );
};
