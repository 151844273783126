import { FC } from "react";

import { IconArrowLeft } from "@consta/uikit/IconArrowLeft";
import { IconArrowRight } from "@consta/uikit/IconArrowRight";

import { Button } from "@consta/uikit/Button";

import { CarouselButtonsProps } from "./model/CarouselButtons.model";

import "./CarouselButtons.scss";

export const CarouselButtons: FC<CarouselButtonsProps> = ({
    currentSlide,
    slidesLength,
    handlePrevSlide,
    handleNextSlide,
}) => {
    return (
        <div className="carousel-buttons">
            <Button
                onlyIcon
                iconLeft={IconArrowLeft}
                view="ghost"
                form='round'
                size='m'
                onClick={handlePrevSlide}
                disabled={currentSlide === 1}
            />
            <div className="carousel-buttons__count">{currentSlide} из {slidesLength}</div>

            <Button
                onlyIcon
                iconLeft={IconArrowRight}
                view="ghost"
                form='round'
                size='m'
                onClick={handleNextSlide}
                disabled={currentSlide === slidesLength}
            />


        </div>
    );
};
