import "./IdeaBlock.scss";
import { linksConfig } from "../../../../configs/linksConfig";

export const IdeaBlock = () => {
    const basepath =
        process.env.REACT_APP_BASEPATH === "/"
            ? process.env.REACT_APP_BASEPATH
            : `${process.env.REACT_APP_BASEPATH}/`;

    return (
        <div className="idea-block-main">
            <div className="subtitle">О продукте</div>
            <div className="title">Возможности</div>
            <div className="desc">
                Лаборатория данных обеспечивает всем необходимым для эффективного решения
                задач по анализу данных
            </div>
            <div className="items">
                <div className="item">
                    <div>
                        <svg
                            width="48"
                            height="48"
                            viewBox="0 0 48 48"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <rect width="48" height="48" rx="8" fill="#ECF1F4" />
                            <path d="M12 24L27 9L25 24H12Z" fill="#0078D2" />
                            <path d="M36 24L21 39L23 24L36 24Z" fill="#00359B" />
                        </svg>
                    </div>
                    <div className="info">
                        <div className="item-title">Быстрый старт</div>
                        <div className="item-desc">
                            Решайте задачи без инициации проекта и привлечения
                            разработчика
                        </div>
                    </div>
                </div>
                <div className="item">
                    <div>
                        <svg
                            width="48"
                            height="48"
                            viewBox="0 0 48 48"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <rect width="48" height="48" rx="8" fill="#ECF1F4" />
                            <path d="M15 34H33V37H15V34Z" fill="#0078D2" />
                            <path d="M19 30H29V34H19V30Z" fill="#00359B" />
                            <rect
                                x="8"
                                y="11"
                                width="32"
                                height="19"
                                rx="2"
                                fill="#0078D2"
                            />
                            <circle cx="24" cy="17" r="4" fill="white" />
                            <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M31 28C31 24.134 27.866 21 24 21C20.134 21 17 24.134 17 28H31Z"
                                fill="white"
                            />
                        </svg>
                    </div>
                    <div className="info">
                        <div className="item-title">Непрерывное обучение</div>
                        <div className="item-desc">
                            Совершенствуйте свои компетенции по анализу и визуализации
                            данных
                        </div>
                    </div>
                </div>
                <div className="item">
                    <div>
                        <svg
                            width="48"
                            height="48"
                            viewBox="0 0 48 48"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <rect width="48" height="48" rx="8" fill="#ECF1F4" />
                            <path d="M18 8H30V12H18V8Z" fill="#0078D2" />
                            <path d="M20 12H28V17H20V12Z" fill="#00359B" />
                            <circle cx="24" cy="28" r="12" fill="#0078D2" />
                            <path
                                d="M19.5 25.5C19.5 24.3954 20.3954 23.5 21.5 23.5H24.5C25.6046 23.5 26.5 24.3954 26.5 25.5V28.5C26.5 29.6046 25.6046 30.5 24.5 30.5H21.5C20.3954 30.5 19.5 29.6046 19.5 28.5V25.5Z"
                                fill="white"
                            />
                            <path
                                d="M22 32.5H24.5C26.7091 32.5 28.5 30.7091 28.5 28.5V26"
                                stroke="white"
                            />
                        </svg>
                    </div>
                    <div className="info">
                        <div className="item-title">Удобные инструменты</div>
                        <div className="item-desc">
                            Используйте современные ИТ-решения и актуальные данные
                        </div>
                    </div>
                </div>
                <div className="item">
                    <div>
                        <svg
                            width="48"
                            height="48"
                            viewBox="0 0 48 48"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <rect width="48" height="48" rx="8" fill="#ECF1F4" />
                            <path
                                d="M34 8C35.1046 8 36 8.89543 36 10L36 36C36 38.2091 34.2091 40 32 40L14 40C12.8954 40 12 39.1046 12 38L12 10C12 8.89543 12.8954 8 14 8L34 8Z"
                                fill="#0078D2"
                            />
                            <path
                                d="M26 31C27.1046 31 28 31.8954 28 33L28 35.5L28 36C28 38.2091 29.7909 40 32 40L33 40L12 40C9.79086 40 8 38.2091 8 36L8 33C8 31.8954 8.89543 31 10 31L26 31Z"
                                fill="#00359B"
                            />
                            <path
                                d="M16 18C16 16.8954 16.8954 16 18 16H19V23H18C16.8954 23 16 22.1046 16 21V18Z"
                                fill="white"
                            />
                            <path d="M19 16H23V23H19V16Z" fill="#00359B" />
                            <path
                                d="M31 17C32.1046 17 33 17.8954 33 19V20C33 21.1046 32.1046 22 31 22V17Z"
                                fill="#00359B"
                            />
                            <path d="M20 23H22V27H20V23Z" fill="white" />
                            <path
                                d="M23 16L29.6489 13.5067C30.3026 13.2615 31 13.7448 31 14.443V24.557C31 25.2552 30.3026 25.7385 29.6489 25.4933L23 23V16Z"
                                fill="white"
                            />
                        </svg>
                    </div>
                    <div className="info">
                        <div className="item-title">Обмен опытом</div>
                        <div className="item-desc">
                            Расскажите об успешном решении задачи и познакомьтесь с
                            достижениями ваших коллег
                        </div>
                    </div>
                </div>
            </div>
            <div
                className="link"
                onClick={() => window.open(basepath + linksConfig.guide_link)}
            >
                <div className="text">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="48"
                        height="48"
                        viewBox="0 0 48 48"
                        fill="none"
                    >
                        <rect width="48" height="48" rx="8" fill="white" />
                        <path
                            d="M36 12C37.1046 12 38 12.8954 38 14L38 38C38 39.1046 37.1046 40 36 40L18 40C16.8954 40 16 39.1046 16 38L16 14C16 12.8954 16.8954 12 18 12L36 12Z"
                            fill="#00359B"
                        />
                        <path
                            d="M32 8C33.1046 8 34 8.89543 34 10L34 34C34 35.1046 33.1046 36 32 36L14 36C12.8954 36 12 35.1046 12 34L12 10C12 8.89543 12.8954 8 14 8L32 8Z"
                            fill="#0078D2"
                        />
                        <path d="M16 12H30V16H16V12Z" fill="white" />
                        <path d="M16 18H30V22H16V18Z" fill="white" />
                        <path d="M16 24H23V28H16V24Z" fill="white" />
                        <path
                            d="M26 30C26 28.8954 26.8954 28 28 28C29.1046 28 30 28.8954 30 30C30 31.1046 29.1046 32 28 32C26.8954 32 26 31.1046 26 30Z"
                            fill="white"
                        />
                    </svg>
                    Гайд о процессе решения аналитических задач расскажет, как
                    использовать наши инструменты для достижения лучших результатов
                </div>
                <a>
                    Подробнее
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                    >
                        <path
                            d="M10.5942 7.00002L7.29709 3.70291L8.7113 2.2887L14.4226 8.00002L8.7113 13.7113L7.29709 12.2971L10.5942 9.00002H2V7.00002H10.5942Z"
                            fill="#00395C"
                            fillOpacity="0.8"
                        />
                    </svg>
                </a>
            </div>
        </div>
    );
};
