import classes from "./SupportModal.module.css";
import { Button } from "@consta/uikit/Button";
import { Text } from "@consta/uikit/Text";
import React from "react";
import { Modal } from "@consta/uikit/Modal";
import { IconClose } from "@consta/uikit/IconClose";
import { linksConfig } from "../../configs/linksConfig";

export const SupportModal = ({
    mode,
    handlerClose,
    open,
}: {
    mode: "short" | "long";
    handlerClose: () => void;
    open: boolean;
}) => {
    return (
        <Modal
            isOpen={open}
            onClickOutside={handlerClose}
            onEsc={handlerClose}
            className={classes.modal}
            onOpen={() => {
                document.body.style.overflow = "hidden"; // отключаем прокрутку при открытии модального окна
            }}
            onClose={() => {
                document.body.style.overflow = ""; // возвращаем прокрутку при закрытии модального окна
            }}
        >
            <Button
                size={"s"}
                view={"clear"}
                className={classes.closeIcon}
                onClick={handlerClose}
                onlyIcon={true}
                iconRight={IconClose}
            />
            {mode === "long" && <div className={classes.mainImg}></div>}
            <div className={classes.description}>
                <Text size="xl" view="primary" lineHeight="xs" weight={"bold"}>
                    {mode === "long" ? "Получите консультацию" : "Заявка на консультацию"}
                </Text>
                <Text size="l" view="primary" lineHeight="xs" align={"center"}>
                    {mode === "long"
                        ? "Сотрудники лаборатории расскажут с чего начать или как пройти определённый этап процесса — готовы помочь с любым вопросом. Для подачи заявки выберите блок, в котором состоите"
                        : "Выберите блок"}
                </Text>
            </div>
            {mode === "long" && <div className={classes.divider}></div>}
            <div className={classes.buttonsGroup}>
                <Button
                    view={"ghost"}
                    label={"БРД"}
                    onClick={() => window.open(linksConfig.support_brd, "_blank")}
                />
                <Button
                    view={"ghost"}
                    label={"БЛПС"}
                    onClick={() => window.open(linksConfig.support_blps, "_blank")}
                />
                <Button
                    view={"ghost"}
                    label={"КФ"}
                    onClick={() => window.open(linksConfig.support_kf, "_blank")}
                />
            </div>
            {}
        </Modal>
    );
};
