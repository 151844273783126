import { materialTypesItems } from "shared/constants/materialTypes.const";
import { FilterDto } from "store/types/Knowledge.model";
import { KnowledgeFilterTagDto } from "store/types/KnowledgeFilters.model";

export const getComboboxValideValues = (param: string, filters?: FilterDto[]) =>
    filters?.map((item) => {
        return {
            id: item.guid,
            label: item.name,
            param,
            disabled: !item.is_available,
        };
    });

export const getComboboxValideTypesValues = (param: string, filters?: FilterDto[]) =>
    filters?.map((item) => {
        return {
            id: item.guid,
            label:
                materialTypesItems.find((typeItem) => item.guid === typeItem.id)?.label ||
                "",
            param,
            disabled: !item.is_available,
        };
    });

export const getTagsFilterValues = (
    tags?: string[],
    dynamicTags?: KnowledgeFilterTagDto[]
) => {
    return tags?.map((item: string) => {
        const tag = dynamicTags?.find(
            (dynamicTag: { system_name: string }) => dynamicTag.system_name === item
        );
        return {
            id: item,
            label: tag?.name,
            param: "tags",
        };
    });
};
