import { Text } from "@consta/uikit/Text";
import classes from "./DeleteModal.module.css";
import { Button } from "@consta/uikit/Button";
import { ReactNode } from "react";
import { IconTrash } from "@consta/uikit/IconTrash";
import { Modal } from "@consta/uikit/Modal";
import { IconClose } from "@consta/uikit/IconClose";

type Props = {
    isOpen: boolean;
    cancelAction: () => void;
    confirmAction: () => void;
    title: string;
    content?: ReactNode;
    loading?: boolean;
};
export const DeleteModal = ({
    loading,
    confirmAction,
    cancelAction,
    isOpen,
    content,
    title,
}: Props) => {
    return (
        <Modal
            isOpen={isOpen}
            hasOverlay
            onClickOutside={cancelAction}
            onEsc={cancelAction}
            className={classes.modal}
        >
            <Button
                size={"s"}
                view={"clear"}
                className={classes.closeIcon}
                onClick={cancelAction}
                onlyIcon={true}
                iconRight={IconClose}
            />
            <Text
                size="xl"
                view="primary"
                lineHeight="xs"
                weight={"bold"}
                className={classes.header}
            >
                {title}
            </Text>
            <Text view="primary" lineHeight="s" className={classes.text}>
                {content}
            </Text>
            <div className={classes.divider}></div>
            <div className={classes.buttonGroup}>
                <Button
                    view={"ghost"}
                    label={"Отменить"}
                    onClick={cancelAction}
                    disabled={loading}
                />
                <Button
                    view={"primary"}
                    label={"Удалить"}
                    loading={loading}
                    iconLeft={IconTrash}
                    onClick={loading ? undefined : confirmAction}
                    className={classes.deleteButton}
                />
            </div>
        </Modal>
    );
};
