import { Breadcrumbs } from "@consta/uikit/Breadcrumbs";

import { PageRoutes } from "appMenuAndRouting/appRoutes";
import "./HeroBlock.scss";

export const HeroBlock = () => {
    const items = [
        {
            label: "Лаборатория данных",
            href: PageRoutes.DataLab_MainPage,
        },
        {
            label: "FAQ",
            href: PageRoutes.DataLab_Faq,
        },
    ];
    return (
        <div className="hero-block-portfolio">
            <div className="hero-block-portfolio__content faq-header">
                <Breadcrumbs items={items} />
                <div className="hero-block-portfolio__title">FAQ</div>
                <div className="hero-block-portfolio__desc">
                    Получите ответы на интересующие вас вопросы
                </div>
            </div>
        </div>
    );
};
