import { FC, useCallback, useMemo, KeyboardEvent, useState, useRef } from "react";

import { useSearchParams } from "react-router-dom";

import { useGetPortfolioFiltersQuery } from "store/labApi";

import { TextField, TextFieldPropValue } from "@consta/uikit/TextField";
import { Combobox } from "@consta/uikit/Combobox";
import { IconSearchStroked } from "@consta/icons/IconSearchStroked";
import { Tag } from "@consta/uikit/Tag";
import { Button } from "@consta/uikit/Button";
// import { IconClose } from "@consta/uikit/IconClose";
import { IconSortDown } from "@consta/uikit/IconSortDown";
import { IconSortUp } from "@consta/uikit/IconSortUp";
import { ListItem } from "@consta/uikit/ListCanary";
import { IconFunnel } from "@consta/uikit/IconFunnel";

import { getComboboxValideToolsValues } from "shared/utils";
import { getComboboxValideValues } from "shared/utils/filters.utils";

import { SortItemType } from "shared/types/SortType";

import { ComboboxItem, PorfolioFiltersProps } from "./model/PorfolioFilters.model";

import "./PortfolioFilters.scss";
import LeftSideBar, {
    LeftSideBarHandleType,
} from "../../../../../../shared-front/LeftSideBar/LeftSideBar";
import { Collapse } from "@consta/uikit/Collapse";
import { CheckboxGroup } from "@consta/uikit/CheckboxGroup";
import { IconAdd } from "@consta/uikit/IconAdd";
import { Switch } from "@consta/uikit/Switch";
import { IconClose } from "@consta/uikit/IconClose";

const sortItems = [
    {
        label: "Сначала новые",
        id: 1,
        icon: <IconSortDown view="link" />,
        value: "-created_at",
    },
    {
        label: "Сначала старые",
        id: 2,
        icon: <IconSortUp view="link" />,
        value: "created_at",
    },
    {
        label: "А-Я",
        id: 3,
        icon: <IconSortUp view="link" />,
        value: "name",
    },
    {
        label: "Я-А",
        id: 4,
        icon: <IconSortDown view="link" />,
        value: "-name",
    },
];

export const PortfolioFilters: FC<PorfolioFiltersProps> = ({ setPage }) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const [handleSearchState, setHandleSearchState] = useState<string | null>(
        searchParams.get("search")
    );
    const [sortParams, setSortParams] = useState<SortItemType | null>(
        searchParams.get("sort")
            ? sortItems.find((item) => item.value === searchParams.get("sort")) ?? null
            : null
    );
    // const [isViewExpandFilters, setIsViewExpandFilters] = useState(false);
    const refButton = useRef<HTMLButtonElement>(null);
    const refSidebar = useRef<LeftSideBarHandleType>(null);
    const openSidebarClick = () => {
        refSidebar.current?.openSidebar();
    };
    const { data: newFilters } = useGetPortfolioFiltersQuery();

    const blockFilters = getComboboxValideValues("blocks", newFilters?.data.blocks);
    const complexitiesFilters = getComboboxValideValues(
        "complexities",
        newFilters?.data.complexities
    );
    const domainsFilters = getComboboxValideValues("domains", newFilters?.data.domains);
    const toolsFilters = getComboboxValideToolsValues("tools", newFilters?.data.tools);

    const getComboboxValue = useCallback(
        (
            label: string,
            filters?: { id: string; label: string; param?: string | undefined }[]
        ) => {
            const searchParam = searchParams.getAll(label);
            if (!searchParam.length || !filters) {
                return undefined;
            }
            return filters.filter((item) => searchParam.includes(item.id));
        },
        [searchParams]
    );

    const handleChangeFilterParams = (values: ComboboxItem[] | null, type: string) => {
        setSearchParams((current) => {
            current.delete(type);
            values?.forEach((item: ComboboxItem) => {
                current.append(type, item.id);
            });
            return current;
        });
        setPage(1);
    };

    const handleChangeSortParams = (item: SortItemType | null) => {
        setSearchParams((current) => {
            item ? current.set("sort", item.value) : current.delete("sort");
            return current;
        });
        setSortParams(item);
        setPage(1);
    };

    const handleSearch = (value: TextFieldPropValue) => {
        setSearchParams((current) => {
            value ? current.set("search", value) : current.delete("search");
            return current;
        });
        setPage(1);
    };

    const handleSwitch = (checked: boolean) => {
        setSearchParams((current) => {
            checked ? current.set("status", "MODERATION") : current.delete("status");
            return current;
        });
        setPage(1);
    };

    const handleKeyPressSearch = (event: KeyboardEvent<HTMLInputElement>) => {
        if (event.key === "Enter") {
            handleSearch(handleSearchState);
        }
    };
    const searchClick = () => {
        handleSearch(handleSearchState);
    };
    const isAdmin = localStorage.getItem("USER_ROLE") === "ADMIN";

    const handleDeleteTag = useCallback(
        (param: string, value: string) => {
            const valuesCurrentParam = searchParams
                .getAll(param)
                .filter((item) => item !== value);
            setSearchParams((current) => {
                current.delete(param);
                valuesCurrentParam.forEach((value) => {
                    current.append(param, value);
                });
                return current;
            });
            setPage(1);
        },
        [searchParams, setSearchParams, setPage]
    );

    const tags = useMemo(() => {
        const blocksFilterValues = getComboboxValue("blocks", blockFilters) || [];
        const complexitiesFilterValues =
            getComboboxValue("complexities", complexitiesFilters) || [];
        const domainsFilterValues = getComboboxValue("domains", domainsFilters) || [];
        const toolsFilterValues = getComboboxValue("tools", toolsFilters) || [];
        // const tagsFilterValues =
        //     getTagsFilterValues(searchParams.getAll("tags"), newFilters?.tags) || [];

        const allFilters = [
            ...blocksFilterValues,
            ...complexitiesFilterValues,
            ...domainsFilterValues,
            // ...tagsFilterValues,
            ...toolsFilterValues,
        ];

        return allFilters.map((item) => (
            <Tag
                key={item.id}
                mode="cancel"
                onCancel={() => handleDeleteTag(item.param || "", item.id)}
                label={item.label}
            />
        ));
    }, [
        blockFilters,
        complexitiesFilters,
        getComboboxValue,
        handleDeleteTag,
        domainsFilters,
        toolsFilters,
    ]);

    const basepath =
        process.env.REACT_APP_BASEPATH === "/"
            ? "/"
            : `${process.env.REACT_APP_BASEPATH}/`;
    const [checked, setChecked] = useState<boolean>(false);
    const [isBlockOpen, setBlockOpen] = useState<boolean>(false);
    // const [isComplOpen, setComplOpen] = useState<boolean>(false);
    const [isToolOpen, setToolOpen] = useState<boolean>(false);
    const [isDomainOpen, setDomainOpen] = useState<boolean>(false);

    return (
        <div className="knowledge-filters">
            <div>
                <div>
                    <Button
                        className="button-add"
                        size="m"
                        view="primary"
                        iconRight={IconAdd}
                        label="Добавить свой кейс"
                        onClick={() =>
                            window.open(basepath + "portfolio/create", "_blank")
                        }
                    />
                    {isAdmin && (
                        <Switch
                            label="Предложенные кейсы"
                            size="m"
                            view="primary"
                            checked={searchParams.getAll("status")[0] ? true : checked}
                            onChange={({ checked }) => {
                                setChecked(checked);
                                handleSwitch(checked);
                            }}
                        />
                    )}
                    <TextField
                        value={handleSearchState}
                        placeholder="Введите текст"
                        onChange={(e) => setHandleSearchState(e.value)}
                        leftSide={IconSearchStroked}
                        onKeyDown={handleKeyPressSearch}
                        className="search-input"
                        rightSide={() =>
                            handleSearchState?.length ? (
                                <IconClose
                                    style={{
                                        color: "var(--color-control-typo-placeholder)",
                                    }}
                                    size={"s"}
                                    onClick={() => {
                                        setHandleSearchState(null);
                                        searchParams.get("search") && handleSearch(null);
                                    }}
                                />
                            ) : (
                                <></>
                            )
                        }
                    />
                    <Button
                        className="search-button"
                        size="m"
                        form="brick"
                        view="secondary"
                        label="Найти"
                        onClick={searchClick}
                    />
                </div>
                <div>
                    <Combobox
                        className="knowledge-filters__settings-item_sort"
                        placeholder="Сортировка"
                        items={sortItems}
                        value={sortParams || sortItems[0]}
                        onChange={(item) => handleChangeSortParams(item.value)}
                        renderValue={({ item }) => (
                            <ListItem label={item.label} leftSide={item.icon} />
                        )}
                        renderItem={({ item, active, onClick }) => (
                            <ListItem
                                label={item.label}
                                leftSide={item.icon}
                                role="option"
                                aria-selected={active}
                                onClick={onClick}
                            />
                        )}
                    />
                    <Button
                        className="filter-button"
                        size="m"
                        view="ghost"
                        iconLeft={IconFunnel}
                        onlyIcon
                        label="Фильтры"
                        ref={refButton}
                        onClick={openSidebarClick}
                    />
                    <LeftSideBar
                        ref={refSidebar}
                        refButton={refButton}
                        isUseClickOutside={true}
                    >
                        <div className="sidebar-filter">
                            <div>
                                <div className="filter-title">Фильтры</div>
                                <Button
                                    view="ghost"
                                    label="Сбросить фильтры"
                                    onClick={() =>
                                        setSearchParams((prevParams) => {
                                            const sortValue = prevParams.get("sort");
                                            const newSearchParams = new URLSearchParams();
                                            if (sortValue) {
                                                newSearchParams.set("sort", sortValue);
                                            }

                                            return newSearchParams;
                                        })
                                    }
                                />
                            </div>
                            {blockFilters && (
                                <div>
                                    <Collapse
                                        label="Блок"
                                        iconPosition="right"
                                        isOpen={isBlockOpen}
                                        onClick={() => setBlockOpen(!isBlockOpen)}
                                    >
                                        <CheckboxGroup
                                            items={blockFilters}
                                            value={getComboboxValue(
                                                "blocks",
                                                blockFilters
                                            )}
                                            getItemKey={(item) => item.id}
                                            onChange={({ value }) => {
                                                handleChangeFilterParams(value, "blocks");
                                            }}
                                            aria-placeholder="Блок"
                                        />
                                    </Collapse>
                                </div>
                            )}

                            {domainsFilters && (
                                <div>
                                    <Collapse
                                        label="Домен"
                                        iconPosition="right"
                                        isOpen={isDomainOpen}
                                        onClick={() => setDomainOpen(!isDomainOpen)}
                                    >
                                        <CheckboxGroup
                                            items={domainsFilters}
                                            value={getComboboxValue(
                                                "domains",
                                                domainsFilters
                                            )}
                                            getItemKey={(item) => item.id}
                                            onChange={({ value }) => {
                                                handleChangeFilterParams(
                                                    value,
                                                    "domains"
                                                );
                                            }}
                                            aria-placeholder="Вид материала"
                                        />
                                    </Collapse>
                                </div>
                            )}

                            {toolsFilters && (
                                <div>
                                    <Collapse
                                        label="Инструмент"
                                        iconPosition="right"
                                        isOpen={isToolOpen}
                                        onClick={() => setToolOpen(!isToolOpen)}
                                    >
                                        <CheckboxGroup
                                            items={toolsFilters}
                                            value={getComboboxValue(
                                                "tools",
                                                toolsFilters
                                            )}
                                            getItemKey={(item) => item.id}
                                            onChange={({ value }) => {
                                                handleChangeFilterParams(value, "tools");
                                            }}
                                            aria-placeholder="Инструмент"
                                        />
                                    </Collapse>
                                </div>
                            )}
                        </div>
                    </LeftSideBar>
                </div>

                {/*{toolsFilters && (*/}
                {/*    <Combobox*/}
                {/*        className="knowledge-filters__settings-item_tools"*/}
                {/*        items={toolsFilters}*/}
                {/*        value={getComboboxValue("tools", toolsFilters)}*/}
                {/*        onChange={({ value, e: { type } }) => {*/}
                {/*            if (type === "click") {*/}
                {/*                handleChangeFilterParams(value, "tools");*/}
                {/*            }*/}
                {/*        }}*/}
                {/*        placeholder="Инструмент"*/}
                {/*        multiple*/}
                {/*    />*/}
                {/*)}*/}
                {/*{isAdmin && canAddKnowledge && (*/}
                {/*    <Button*/}
                {/*        label="Добавить документ"*/}
                {/*        iconLeft={IconAdd}*/}
                {/*        onClick={() => navigate(`${basepath}knowledge/create`)}*/}
                {/*    />*/}
                {/*)}*/}

                {/*<div className="knowledge-filters__items">*/}
                {/*    <span>*/}
                {/*        {!!total && (*/}
                {/*            <span className="knowledge-filters__items-title">*/}
                {/*                {total}{" "}*/}
                {/*                {enumerate(total.toString(), [*/}
                {/*                    "Результат",*/}
                {/*                    "Результата",*/}
                {/*                    "Результатов",*/}
                {/*                ])}*/}
                {/*            </span>*/}
                {/*        )}*/}
                {/*        {tags}*/}
                {/*    </span>*/}
                {/*    <Button*/}
                {/*        iconRight={IconClose}*/}
                {/*        onlyIcon*/}
                {/*        iconSize="s"*/}
                {/*        size="xs"*/}
                {/*        view="clear"*/}
                {/*        onClick={() => setSearchParams([])}*/}
                {/*    />*/}
                {/*</div>*/}
            </div>
            <div className="tags">{tags}</div>
        </div>
    );
};
