import { FC } from "react";

import classNames from "classnames";

import { divideNumIntoCategories } from "shared/utils";

import { MetricValueBlockProps } from "./model/MetricValueBlock.model";

import "./MetricValueBlock.scss";

export const MetricValueBlock: FC<MetricValueBlockProps> = ({
    value,
    
    entityDescription,
}) => (
    <div className="metric-value-block">
        <div className="metric-value-block__label">Общее количество у блоков:</div>
        <div
            className={classNames("metric-value-block__flex", {
                ["metric-value-block__flex_col"]: !entityDescription,
                ["metric-value-block__flex_row"]: entityDescription,
            })}
        >
            <div className="metric-value-block__value">
                {divideNumIntoCategories(+value)}
            </div>
          
        </div>
        <div className="metric-value-block__entity-description">{entityDescription}</div>
    </div>
);
