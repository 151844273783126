import { FC } from "react";

import { Responses404 } from '@consta/uikit/Responses404';

import "./EmptyPage.scss";

export const EmptyPage: FC<{ title: string }> = () => {
    return (
            <div className='wrap-404'>
                <Responses404 />
            </div>

    );
};
