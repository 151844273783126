import { FC } from "react";

import { Modal } from "@consta/uikit/Modal";

import { Button } from "@consta/uikit/Button";
import { IconClose } from "@consta/uikit/IconClose";

import { ModalWrapperProps } from "./model/ModalWrapper.model";

import "./ModalWrapper.scss";

export const ModalWrapper: FC<ModalWrapperProps> = ({
    isOpen,
    handleClose,
    children,
    title,
}) => (
    <Modal
        isOpen={isOpen}
        hasOverlay
        onClickOutside={handleClose}
        onEsc={handleClose}
        className="modal-wrapper"
        onOpen={() => {
            document.body.style.overflow = "hidden"; // отключаем прокрутку при открытии модального окна
        }}
        onClose={() => {
            document.body.style.overflow = ""; // возвращаем прокрутку при закрытии модального окна
        }}
    >
        <Button
            className="modal-wrapper__close-button"
            iconRight={IconClose}
            onlyIcon
            iconSize="s"
            size="xs"
            view="clear"
            onClick={handleClose}
        />
        <div className="modal-wrapper__title">{title}</div>
        {children}
    </Modal>
);
