// import { IconLineAndBarChart } from "@consta/uikit/IconLineAndBarChart";
// import { IconBag } from "@consta/uikit/IconBag";
// import { IconDocExport } from "@consta/uikit/IconDocExport";

import heroBlockContent from "templateContent/mainPage/heroBlockContent.json";

import "./ForWhomBlock.scss";

export const ForWhomBlock = () => (
        <div className="for-whom-block">
            <div className="for-whom-block__item">
                <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect width="32" height="32" rx="6" fill="#0078D2"/>
                    <path d="M12 24H16V28H12V24Z" fill="white"/>
                    <path d="M24 15H28V28H24V15Z" fill="white"/>
                    <path d="M18 20H22V28H18V20Z" fill="white"/>
                    <circle cx="11" cy="11" r="7" fill="#00359B"/>
                    <path d="M18 11C18 12.3845 17.5895 13.7378 16.8203 14.889C16.0511 16.0401 14.9579 16.9373 13.6788 17.4672C12.3997 17.997 10.9922 18.1356 9.63437 17.8655C8.2765 17.5954 7.02922 16.9287 6.05025 15.9497C5.07128 14.9708 4.4046 13.7235 4.1345 12.3656C3.86441 11.0078 4.00303 9.6003 4.53284 8.32122C5.06266 7.04213 5.95986 5.94888 7.11101 5.17971C8.26215 4.41054 9.61553 4 11 4V11H18Z"
                          fill="white"/>
                    <rect x="12" y="22" width="4" height="2" fill="#00359B"/>
                    <rect x="18" y="18" width="4" height="2" fill="#00359B"/>
                    <rect x="24" y="13" width="4" height="2" fill="#00359B"/>
                </svg>

                {heroBlockContent.forWhomItems[0]}
            </div>
            <div className="for-whom-block__item">
                <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect width="32" height="32" rx="6" fill="#0078D2"/>
                    <path d="M5 16C5 9.92487 9.92487 5 16 5C22.0751 5 27 9.92487 27 16C27 22.0751 22.0751 27 16 27C9.92487 27 5 22.0751 5 16Z" fill="white"/>
                    <path d="M13 18.882V13.118C13 12.3747 13.7823 11.8912 14.4472 12.2236L20.2111 15.1056C20.9482 15.4741 20.9482 16.5259 20.2111 16.8944L14.4472 19.7764C13.7823 20.1088 13 19.6253 13 18.882Z" fill="#00359B"/>
                </svg>
                {heroBlockContent.forWhomItems[1]}
            </div>
            <div className="for-whom-block__item">
                <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect width="32" height="32" rx="6" fill="#0078D2"/>
                    <path d="M5 6H11V27H5V6Z" fill="white"/>
                    <path d="M13 6H19V27H13V6Z" fill="white"/>
                    <path d="M21 6H27V27H21V6Z" fill="white"/>
                    <path d="M5 4H11V11H5V4Z" fill="#00359B"/>
                    <path d="M13 4H19V11H13V4Z" fill="#00359B"/>
                    <path d="M21 4H27V11H21V4Z" fill="#00359B"/>
                    <rect x="4" y="15" width="24" height="2" fill="#0078D2"/>
                    <rect x="4" y="21" width="24" height="2" fill="#0078D2"/>
                </svg>
                {heroBlockContent.forWhomItems[2]}
            </div>
        </div>
);
