import { useEffect, useMemo, useState } from "react";

import moment from "moment";

import {
    useGetManualBlocksQuery,
    useGetCurrentUserRightsQuery,
    useLazyGetAdminBlockQuery,
} from "store/labApi";

import { ManualBlockDto } from "store/types/ManualBlocks.model";

import { ChoiceGroup } from "@consta/uikit/ChoiceGroup";

import { enumerate } from "shared/utils";
import { checkPermission } from "shared/utils/rights.utils";

import { MetricBlock } from "./MetricBlock";
import { Breadcrumbs } from "@consta/uikit/Breadcrumbs";

import { PageRoutes } from "appMenuAndRouting/appRoutes";

import {
    MetricWidgetStaticData,
    blpsMetricRights,
    brdMetricRights,
    cfMetricRights,
} from "./MetricsWidget.const";

import "./MetricsWidget.scss";
import { EmptyPage } from "../../../EmptyPage";

export const MetricsWidget = () => {
    const { data: manualBlocksData } = useGetManualBlocksQuery();
    const [currentBlock, setCurrentBlock] = useState<ManualBlockDto | undefined>();

    const [getAdminBlock, { data: metricBlocksData }] = useLazyGetAdminBlockQuery();

    const { data: userData } = useGetCurrentUserRightsQuery();
    const rightsData = userData?.roles ?? undefined;

    const blocks = manualBlocksData?.data?.filter((item) => {
        switch (item.short_name) {
            case "БРД":
                return checkPermission(brdMetricRights, rightsData);
            case "БЛПС":
                return checkPermission(blpsMetricRights, rightsData);
            case "КФ":
                return checkPermission(cfMetricRights, rightsData);
        }
    });
    // console.log(currentBlock);
    const metrics = useMemo(() => {
        return metricBlocksData?.data.metrics.map((item) => {
            const metricStatic = MetricWidgetStaticData.find(
                (staticItem) => staticItem.key === item.key
            );

            return (
                <MetricBlock
                    key={item._uuid}
                    blockId={currentBlock?._uuid}
                    metricId={item.uuid_metric}
                    value={item.lng_value}
                    sumValue={item.sum_value}
                    title={metricStatic?.title}
                    description={metricStatic?.description}
                    blockName={currentBlock?.short_name}
                    date={moment(item._create_ts).format("DD.MM.YYYY")}
                    entity={enumerate(item.sum_value, metricStatic?.entity)}
                    entityDescription={metricStatic?.entity_description}
                    refetchBlockQuery={() => getAdminBlock(currentBlock?._uuid || "")}
                />
            );
        });
    }, [
        currentBlock?._uuid,
        currentBlock?.short_name,
        metricBlocksData?.data.metrics,
        getAdminBlock,
    ]);

    useEffect(() => {
        if (blocks?.length && !currentBlock) {
            setCurrentBlock(blocks[0]);
        }
    }, [blocks, currentBlock]);

    useEffect(() => {
        currentBlock?._uuid && getAdminBlock(currentBlock._uuid);
    }, [currentBlock?._uuid, getAdminBlock]);

    const items = [
        {
            label: "Лаборатория данных",
            href: PageRoutes.DataLab_MainPage,
        },
        {
            label: "Администрирование метрик",
            href: PageRoutes.DataLab_MetricsPage,
        },
    ];

    if (localStorage.getItem("USER_ROLE") !== "ADMIN") {
        return <EmptyPage title="Страница не найдена" />;
    }

    return (
        <>
            <div className="hero-block-portfolio">
                <div className="hero-block-portfolio__content metrik-header">
                    <Breadcrumbs items={items} />
                    <div className="hero-block-portfolio__title">
                        Администрирование метрик{" "}
                        {blocks && blocks.length === 1 && blocks[0]?.short_name}
                    </div>
                    <div className="hero-block-portfolio__desc">
                        Укажите метрики вашего подразделения
                    </div>
                </div>
            </div>
            <div className="page-content">
                <div className="metrics-widget">
                    {blocks && blocks.length > 1 && (
                        <ChoiceGroup
                            value={currentBlock}
                            onChange={({ value }) => setCurrentBlock(value)}
                            items={blocks}
                            getItemLabel={(item) => item.short_name}
                            name="blocks"
                            className="metrics-widget__choice-group"
                        />
                    )}
                    {metrics}
                </div>
            </div>
        </>
    );
};
