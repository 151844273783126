import { createContext, useCallback, useRef } from "react";

import { ScrollContextProps, ScrollProviderProps } from "./ScrollContext.model";

export const ScrollContext = createContext<ScrollContextProps>({
    updateRefStore: () => null,
    scrollToStore: () => null,
    updateRefTools: () => null,
    scrollToTools: () => null,
    updateRefEducation: () => null,
    scrollToEducation: () => null,
    updateRefСonsultations: () => null,
    scrollToСonsultations: () => null,
    updateRefPortfolio: () => null,
    scrollToPortfolio: () => null,
    updateRefIncreasingCompetencies: () => null,
    scrollToIncreasingCompetencies: () => null,
    updateRefData: () => null,
    scrollToData: () => null,
});

export const ScrollProvider = ({ children }: ScrollProviderProps) => {
    const refStoreContainer = useRef<null | HTMLDivElement>(null);
    const refToolsContainer = useRef<null | HTMLDivElement>(null);
    const refEducationContainer = useRef<null | HTMLDivElement>(null);
    const refСonsultationsContainer = useRef<null | HTMLDivElement>(null);
    const refPortfolioContainer = useRef<null | HTMLDivElement>(null);

    const refIncreasingCompetencies = useRef<null | HTMLDivElement>(null);
    const refData = useRef<null | HTMLDivElement>(null);

    const updateRefStore = useCallback((value: HTMLDivElement | null) => {
        refStoreContainer.current = value;
    }, []);

    const updateRefTools = useCallback((value: HTMLDivElement | null) => {
        refToolsContainer.current = value;
    }, []);

    const updateRefEducation = useCallback((value: HTMLDivElement | null) => {
        refEducationContainer.current = value;
    }, []);

    const updateRefСonsultations = useCallback((value: HTMLDivElement | null) => {
        refСonsultationsContainer.current = value;
    }, []);

    const updateRefPortfolio = useCallback((value: HTMLDivElement | null) => {
        refPortfolioContainer.current = value;
    }, []);

    const updateRefIncreasingCompetencies = useCallback(
        (value: HTMLDivElement | null) => {
            refIncreasingCompetencies.current = value;
        },
        []
    );

    const updateRefData = useCallback((value: HTMLDivElement | null) => {
        refData.current = value;
    }, []);

    const scrollToStore = useCallback(() => {
        if (refStoreContainer.current?.offsetTop) {
            window.scrollTo({
                behavior: "smooth",
                top: refStoreContainer.current?.offsetTop - 67,
            });
        }
    }, []);

    const scrollToTools = useCallback(() => {
        if (refToolsContainer.current?.offsetTop) {
            window.scrollTo({
                behavior: "smooth",
                top: refToolsContainer.current?.offsetTop - 67,
            });
        }
    }, []);

    const scrollToEducation = useCallback(() => {
        if (refEducationContainer.current?.offsetTop) {
            window.scrollTo({
                behavior: "smooth",
                top: refEducationContainer.current?.offsetTop - 67,
            });
        }
    }, []);

    const scrollToСonsultations = useCallback(() => {
        if (refСonsultationsContainer.current?.offsetTop) {
            window.scrollTo({
                behavior: "smooth",
                top: refСonsultationsContainer.current?.offsetTop - 67,
            });
        }
    }, []);

    const scrollToPortfolio = useCallback(() => {
        if (refPortfolioContainer.current?.offsetTop) {
            window.scrollTo({
                behavior: "smooth",
                top: refPortfolioContainer.current?.offsetTop - 67,
            });
        }
    }, []);

    const scrollToIncreasingCompetencies = useCallback(() => {
        if (refIncreasingCompetencies.current?.offsetTop) {
            window.scrollTo({
                behavior: "smooth",
                top: refIncreasingCompetencies.current?.offsetTop - 67,
            });
        }
    }, []);

    const scrollToData = useCallback(() => {
        if (refData.current?.offsetTop) {
            window.scrollTo({
                behavior: "smooth",
                top: refData.current?.offsetTop - 67,
            });
        }
    }, []);

    const value = {
        updateRefStore,
        scrollToStore,
        updateRefTools,
        scrollToTools,
        updateRefEducation,
        scrollToEducation,
        updateRefСonsultations,
        scrollToСonsultations,
        updateRefPortfolio,
        scrollToPortfolio,
        scrollToIncreasingCompetencies,
        updateRefIncreasingCompetencies,
        updateRefData,
        scrollToData,
    };

    return <ScrollContext.Provider value={value}>{children}</ScrollContext.Provider>;
};
