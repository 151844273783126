import { FC, useState } from "react";

import { useGetManualBlocksQuery, useGetFaqQuery } from "store/labApi";

import { ChoiceGroup } from "@consta/uikit/ChoiceGroup";
// import { TextField } from "@consta/uikit/TextField";
// import { IconSearch } from "@consta/uikit/IconSearch";
import { Grid, GridItem } from "@consta/uikit/Grid";

import { FaqWidgetItem } from "./components/FaqWidgetItem";

import "./FaqWidget.scss";

export const FaqWidget:  FC<any> = ({showFilters}) => {
    const firstItem = {
        id: "",
        label: "Все",
    };
    const [currentBlock, setCurrentBlock] = useState<{ label: string; id: string }>(
        firstItem
    );

    const { data: manualBlocksData } = useGetManualBlocksQuery();
    const { data: faqData } = useGetFaqQuery(
        currentBlock.id ? `?filter[blocks]=${currentBlock.id}` : ""
    );

    const blocks = manualBlocksData?.data.map((block) => {
        return { id: block._uuid, label: block.short_name };
    });
    blocks?.unshift(firstItem);

    return (
        <div className="faq-widget">
            {blocks && showFilters && (
                <div>
                    <ChoiceGroup
                        value={currentBlock}
                        onChange={({ value }) => {
                            setCurrentBlock(value);
                        }}
                        items={blocks}
                        getItemLabel={(item) => item.label}
                        name="blocks"
                        className="metrics-widget__choice-group"
                    />
                </div>
            )}

            <Grid cols="12">
                {/*<GridItem col={8} colStart={1}>*/}
                {/*    <TextField*/}
                {/*        leftSide={IconSearch}*/}
                {/*        placeholder="Поиск"*/}
                {/*        width="full"*/}
                {/*        className="faq-widget__search-field"*/}
                {/*    />*/}
                {/*</GridItem>*/}
                {faqData?.map((item, index) => (
                    <GridItem col={12} colStart={1} key={item._uuid}>
                        <FaqWidgetItem label={(index+1 +'').padStart(2, '0') +'.' + ' ' + item.question} content={item.answer} />
                    </GridItem>
                ))}
            </Grid>
        </div>
    );
};
