import { Middleware } from "@reduxjs/toolkit";
import { addNotification } from "../general";
import { AppDispatch } from "../index";

const errorMiddleware: Middleware =
    ({ dispatch }: { dispatch: AppDispatch }) =>
    (next) =>
    (action) => {
        if (action.type.endsWith("/rejected") && action.error.name !== "AbortError") {
            const responseUrl = action?.meta?.baseQueryMeta?.request?.url;
            const message =
                action?.payload?.data?.error ?? action.error?.message ?? "ошибка";
            dispatch(
                addNotification({
                    message: message,
                    status: "alert",
                    responseCode: action.payload?.status,
                    responseUrl: responseUrl,
                })
            );
        }

        return next(action);
    };

export default errorMiddleware;
