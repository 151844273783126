import { useGetMetricsQuery } from "store/labApi";
import { ForWhomBlock } from "./components/ForWhomBlock";
import { HeroMainUsersBlock } from "./components/HeroMainUsersBlock";
// import { FlasksBlock } from "./components/FlasksBlock";

import { MetricKey } from "shared/constants/metrics.const";
import { divideNumIntoCategories } from "shared/utils";

import { MetricTypeDto } from "store/types/Metrics.model";

import heroBlockContent from "templateContent/mainPage/heroBlockContent.json";

import "./HeroBlock.scss";

export const HeroBlock = () => {
    const { data } = useGetMetricsQuery(undefined, { refetchOnMountOrArgChange: true });
    const usersCountValue = data?.find(
        (metric: MetricTypeDto) => metric.key === MetricKey.STORE_USER_COUNT
    )?.value;

    return (
        <div className="hero-block-main">
            <div className='grey-bg'></div>
            <div  className="hero-block-main__content">
                <div className="hero-block-main__title">{heroBlockContent.title}</div>
                <div className="hero-block-main__desc">
                    {heroBlockContent.desctiption}
                </div>
                {/*<FlasksBlock />*/}
                {usersCountValue && (
                    <HeroMainUsersBlock
                        usersCount={divideNumIntoCategories(+usersCountValue)}
                    />
                )}
            </div>
            <div className='for-who'>
                <ForWhomBlock />
            </div>
        </div>
    );
};
