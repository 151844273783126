import { FC, KeyboardEvent, useCallback, useMemo, useRef, useState } from "react";

import { useSearchParams } from "react-router-dom";
// useNavigate
import { useGetCurrentUserRightsQuery, useGetKnowledgeFiltersQuery } from "store/labApi";
// useGetСurrentUserRightsQuery
import { TextField, TextFieldPropValue } from "@consta/uikit/TextField";
import { Combobox } from "@consta/uikit/Combobox";
import { IconSearchStroked } from "@consta/icons/IconSearchStroked";
import { Tag } from "@consta/uikit/Tag";
import { Button } from "@consta/uikit/Button";
// import { IconAdd } from "@consta/uikit/IconAdd";
// import { IconClose } from "@consta/uikit/IconClose";
import { IconSortDown } from "@consta/uikit/IconSortDown";
import { IconSortUp } from "@consta/uikit/IconSortUp";
import { ListItem } from "@consta/uikit/ListCanary";

import { getComboboxValideToolsValues } from "shared/utils";
import { checkPermission } from "shared/utils/rights.utils";
import { FilterType } from "shared/types/FiltersType";
import { SortItemType } from "shared/types/SortType";

import { createKnowledgeRights } from "./KnowledgeFilters.const";

import {
    getComboboxValideTypesValues,
    getComboboxValideValues,
    getTagsFilterValues,
} from "./KnowledgeFilters.utils";

import { ComboboxItem, KnowledgeFiltersProps } from "./model/KnowledgeFilters.model";

import "./KnowledgeFilters.scss";
// import { IconFilter } from "@consta/uikit/IconFilter";
import { Collapse } from "@consta/uikit/Collapse";
import { IconFunnel } from "@consta/uikit/IconFunnel";
import LeftSideBar, {
    LeftSideBarHandleType,
} from "../../../../../../shared-front/LeftSideBar/LeftSideBar";
import { CheckboxGroup } from "@consta/uikit/CheckboxGroup";
import { IconAdd } from "@consta/uikit/IconAdd";
import { IconClose } from "@consta/uikit/IconClose";

const excelTag = {
    name: "Excel",
    ord: 0,
    system_name: "SYSTEM_EXCEL",
    icon_name: null,
    icon_style: null,
    _alter_ts: null,
    _alter_user: null,
    _create_ts: null,
    _create_user: "",
    _uuid: "excel_front_unknown",
};
const processTag = {
    name: "Процесс",
    ord: 0,
    system_name: "SYSTEM_PROCESS",
    icon_name: null,
    icon_style: null,
    _alter_ts: null,
    _alter_user: null,
    _create_ts: null,
    _create_user: "",
    _uuid: "process_front_unknown",
};
const applicationTag = {
    name: "Аналитическое приложение",
    ord: 0,
    system_name: "SYSTEM_ANALYTICAL_APPLICATION",
    icon_name: null,
    icon_style: null,
    _alter_ts: null,
    _alter_user: null,
    _create_ts: null,
    _create_user: "",
    _uuid: "application_front_unknown",
};
const taskSettingTag = {
    name: "Постановка задач",
    ord: 0,
    system_name: "SYSTEM_TASK_STAGING",
    icon_name: null,
    icon_style: null,
    _alter_ts: null,
    _alter_user: null,
    _create_ts: null,
    _create_user: "",
    _uuid: "taskSetting_front_unknown",
};

const sortItems = [
    {
        label: "Сначала новые",
        id: 1,
        icon: <IconSortDown view="link" />,
        value: "-created_at",
    },
    {
        label: "Сначала старые",
        id: 2,
        icon: <IconSortUp view="link" />,
        value: "created_at",
    },
    {
        label: "А-Я",
        id: 3,
        icon: <IconSortUp view="link" />,
        value: "subject",
    },
    {
        label: "Я-А",
        id: 4,
        icon: <IconSortDown view="link" />,
        value: "-subject",
    },
    {
        label: "По рейтингу",
        id: 5,
        icon: <IconSortDown view="link" />,
        value: "-rating",
    },
];

export const KnowledgeFilters: FC<KnowledgeFiltersProps> = ({
    // total,
    availableFilters,
}) => {
    const basepath =
        process.env.REACT_APP_BASEPATH === "/"
            ? "/"
            : `${process.env.REACT_APP_BASEPATH}/`;

    const refButton = useRef<HTMLButtonElement>(null);
    const refSidebar = useRef<LeftSideBarHandleType>(null);
    const openSidebarClick = () => {
        refSidebar.current?.openSidebar();
    };
    const [searchParams, setSearchParams] = useSearchParams();
    const [handleSearchState, setHandleSearchState] = useState<string | null>(
        searchParams.get("search")
    );
    const [sortParams, setSortParams] = useState<SortItemType | null>(
        searchParams.get("sort")
            ? sortItems.find((item) => item.value === searchParams.get("sort")) ?? null
            : null
    );

    // const navigate = useNavigate();

    const { data: newFilters } = useGetKnowledgeFiltersQuery();
    const { data: userData } = useGetCurrentUserRightsQuery();
    const rightsData = userData?.roles ?? undefined;

    const blockFilters = getComboboxValideValues("blocks", availableFilters?.blocks);
    const complexitiesFilters = getComboboxValideValues(
        "complexities",
        availableFilters?.complexities
    );
    const typesFilters = getComboboxValideTypesValues("types", availableFilters?.types);
    const toolsFilters = getComboboxValideToolsValues("tools", availableFilters?.tools);

    const getComboboxValue = useCallback(
        (label: string, filters?: FilterType[]) => {
            const searchParam = searchParams.getAll(label);
            if (!searchParam.length || !filters) {
                return undefined;
            }
            return filters.filter((item: FilterType) => searchParam.includes(item.id));
        },
        [searchParams]
    );

    const handleChangeFilterParams = (values: ComboboxItem[] | null, type: string) => {
        setSearchParams((current) => {
            current.delete(type);
            values?.forEach((item: ComboboxItem) => {
                current.append(type, item.id);
            });
            return current;
        });
    };

    const handleChangeSortParams = (item: SortItemType | null) => {
        setSearchParams((current) => {
            item ? current.set("sort", item.value) : current.delete("sort");
            return current;
        });
        setSortParams(item);
    };

    const handleSearch = (value: TextFieldPropValue) => {
        setSearchParams((current) => {
            value ? current.set("search", value) : current.delete("search");
            return current;
        });
    };

    const handleKeyPressSearch = (event: KeyboardEvent<HTMLInputElement>) => {
        if (event.key === "Enter") {
            handleSearch(handleSearchState);
        }
    };
    const searchClick = () => {
        handleSearch(handleSearchState);
    };

    const handleDeleteTag = useCallback(
        (param: string, value: string) => {
            const valuesCurrentParam = searchParams
                .getAll(param)
                .filter((item) => item !== value);
            setSearchParams((current) => {
                current.delete(param);
                valuesCurrentParam.forEach((value) => {
                    current.append(param, value);
                });
                return current;
            });
        },
        [searchParams, setSearchParams]
    );

    const tags = useMemo(() => {
        const blocksFilterValues = getComboboxValue("blocks", blockFilters) || [];
        const complexitiesFilterValues =
            getComboboxValue("complexities", complexitiesFilters) || [];
        const typesFilterValues = getComboboxValue("types", typesFilters) || [];
        const toolsFilterValues = getComboboxValue("tools", toolsFilters) || [];
        // фильтры tags не приходят, попросили поставить такой "костыль" для корректного отображения
        const tagsFilterValues =
            getTagsFilterValues(
                searchParams.getAll("tags"),
                newFilters?.tags
                    ? [
                          ...newFilters.tags,
                          excelTag,
                          applicationTag,
                          processTag,
                          taskSettingTag,
                      ]
                    : [excelTag, applicationTag, processTag, taskSettingTag]
            ) || [];

        const allFilters = [
            ...blocksFilterValues,
            ...complexitiesFilterValues,
            ...typesFilterValues,
            ...tagsFilterValues,
            ...toolsFilterValues,
        ];

        return allFilters.map((item) => (
            <Tag
                key={item.id}
                mode="cancel"
                onCancel={() => handleDeleteTag(item.param, item.id)}
                label={item.label || ""}
            />
        ));
    }, [
        blockFilters,
        complexitiesFilters,
        getComboboxValue,
        handleDeleteTag,
        newFilters?.tags,
        searchParams,
        typesFilters,
        toolsFilters,
    ]);

    const [isBlockOpen, setBlockOpen] = useState<boolean>(false);
    const [isComplOpen, setComplOpen] = useState<boolean>(false);
    const [isTypeOpen, setTypeOpen] = useState<boolean>(false);
    const [isToolOpen, setToolOpen] = useState<boolean>(false);

    //
    const isAdmin = localStorage.getItem("USER_ROLE") === "ADMIN";
    const canAddKnowledge = checkPermission(createKnowledgeRights, rightsData);

    return (
        <div className="knowledge-filters">
            <div>
                <div>
                    {isAdmin && canAddKnowledge && (
                        <Button
                            className="button-add"
                            size="m"
                            view="primary"
                            iconRight={IconAdd}
                            label="Добавить"
                            onClick={() =>
                                window.open(basepath + "knowledge/create", "_blank")
                            }
                        />
                    )}
                    <TextField
                        value={handleSearchState}
                        placeholder="Введите текст"
                        onChange={(e) => setHandleSearchState(e.value)}
                        leftSide={IconSearchStroked}
                        onKeyDown={handleKeyPressSearch}
                        className="search-input"
                        rightSide={() =>
                            handleSearchState?.length ? (
                                <IconClose
                                    style={{
                                        color: "var(--color-control-typo-placeholder)",
                                    }}
                                    size={"s"}
                                    onClick={() => {
                                        setHandleSearchState(null);
                                        searchParams.get("search") && handleSearch(null);
                                    }}
                                />
                            ) : (
                                <></>
                            )
                        }
                    />
                    <Button
                        className="search-button"
                        size="m"
                        form="brick"
                        view="secondary"
                        label="Найти"
                        onClick={searchClick}
                    />
                </div>
                <div>
                    <Combobox
                        className="knowledge-filters__settings-item_sort"
                        placeholder="Сортировка"
                        items={sortItems}
                        value={sortParams || sortItems[0]}
                        onChange={(item) => handleChangeSortParams(item.value)}
                        renderValue={({ item }) => (
                            <ListItem label={item.label} leftSide={item.icon} />
                        )}
                        renderItem={({ item, active, onClick }) => (
                            <ListItem
                                label={item.label}
                                leftSide={item.icon}
                                role="option"
                                aria-selected={active}
                                onClick={onClick}
                            />
                        )}
                    />
                    <Button
                        className="filter-button"
                        size="m"
                        view="ghost"
                        iconLeft={IconFunnel}
                        label="Фильтры"
                        ref={refButton}
                        onClick={openSidebarClick}
                    />
                    <LeftSideBar
                        ref={refSidebar}
                        refButton={refButton}
                        isUseClickOutside={true}
                    >
                        <div className="sidebar-filter">
                            <div>
                                <div className="filter-title">Фильтры</div>
                                <Button
                                    view="ghost"
                                    label="Сбросить фильтры"
                                    onClick={() =>
                                        setSearchParams((prevParams) => {
                                            const sortValue = prevParams.get("sort");
                                            const newSearchParams = new URLSearchParams();
                                            if (sortValue) {
                                                newSearchParams.set("sort", sortValue);
                                            }

                                            return newSearchParams;
                                        })
                                    }
                                />
                            </div>
                            {blockFilters && (
                                <div>
                                    <Collapse
                                        label="Блок"
                                        iconPosition="right"
                                        isOpen={isBlockOpen}
                                        onClick={() => setBlockOpen(!isBlockOpen)}
                                    >
                                        <CheckboxGroup
                                            items={blockFilters}
                                            value={getComboboxValue(
                                                "blocks",
                                                blockFilters
                                            )}
                                            onChange={({ value }) => {
                                                handleChangeFilterParams(value, "blocks");
                                            }}
                                            //placeholder="Блок"
                                            aria-placeholder="Блок"
                                            getItemKey={(item) => item.id}
                                        />
                                    </Collapse>
                                </div>
                            )}
                            {complexitiesFilters && (
                                <div>
                                    <Collapse
                                        label="Сложность"
                                        iconPosition="right"
                                        isOpen={isComplOpen}
                                        onClick={() => setComplOpen(!isComplOpen)}
                                    >
                                        <CheckboxGroup
                                            items={complexitiesFilters}
                                            value={getComboboxValue(
                                                "complexities",
                                                complexitiesFilters
                                            )}
                                            onChange={({ value }) => {
                                                handleChangeFilterParams(
                                                    value,
                                                    "complexities"
                                                );
                                            }}
                                            aria-placeholder="Сложность"
                                            getItemKey={(item) => item.id}
                                        />
                                    </Collapse>
                                </div>
                            )}
                            {typesFilters && (
                                <div>
                                    <Collapse
                                        label="Вид материала"
                                        iconPosition="right"
                                        isOpen={isTypeOpen}
                                        onClick={() => setTypeOpen(!isTypeOpen)}
                                    >
                                        <CheckboxGroup
                                            items={typesFilters}
                                            value={getComboboxValue(
                                                "types",
                                                typesFilters
                                            )}
                                            onChange={({ value }) => {
                                                handleChangeFilterParams(value, "types");
                                            }}
                                            aria-placeholder="Вид материала"
                                            getItemKey={(item) => item.id}
                                        />
                                    </Collapse>
                                </div>
                            )}

                            {toolsFilters && (
                                <div>
                                    <Collapse
                                        label="Инструмент"
                                        iconPosition="right"
                                        isOpen={isToolOpen}
                                        onClick={() => setToolOpen(!isToolOpen)}
                                    >
                                        <CheckboxGroup
                                            items={toolsFilters}
                                            value={getComboboxValue(
                                                "tools",
                                                toolsFilters
                                            )}
                                            onChange={({ value }) => {
                                                handleChangeFilterParams(value, "tools");
                                            }}
                                            aria-placeholder="Инструмент"
                                            getItemKey={(item) => item.id}
                                        />
                                    </Collapse>
                                </div>
                            )}
                        </div>
                    </LeftSideBar>
                </div>

                {/*{toolsFilters && (*/}
                {/*    <Combobox*/}
                {/*        className="knowledge-filters__settings-item_tools"*/}
                {/*        items={toolsFilters}*/}
                {/*        value={getComboboxValue("tools", toolsFilters)}*/}
                {/*        onChange={({ value, e: { type } }) => {*/}
                {/*            if (type === "click") {*/}
                {/*                handleChangeFilterParams(value, "tools");*/}
                {/*            }*/}
                {/*        }}*/}
                {/*        placeholder="Инструмент"*/}
                {/*        multiple*/}
                {/*    />*/}
                {/*)}*/}
                {/*{isAdmin && canAddKnowledge && (*/}
                {/*    <Button*/}
                {/*        label="Добавить документ"*/}
                {/*        iconLeft={IconAdd}*/}
                {/*        onClick={() => navigate(`${basepath}knowledge/create`)}*/}
                {/*    />*/}
                {/*)}*/}

                {/*<div className="knowledge-filters__items">*/}
                {/*    <span>*/}
                {/*        {!!total && (*/}
                {/*            <span className="knowledge-filters__items-title">*/}
                {/*                {total}{" "}*/}
                {/*                {enumerate(total.toString(), [*/}
                {/*                    "Результат",*/}
                {/*                    "Результата",*/}
                {/*                    "Результатов",*/}
                {/*                ])}*/}
                {/*            </span>*/}
                {/*        )}*/}
                {/*        {tags}*/}
                {/*    </span>*/}
                {/*    <Button*/}
                {/*        iconRight={IconClose}*/}
                {/*        onlyIcon*/}
                {/*        iconSize="s"*/}
                {/*        size="xs"*/}
                {/*        view="clear"*/}
                {/*        onClick={() => setSearchParams([])}*/}
                {/*    />*/}
                {/*</div>*/}
            </div>
            <div className="tags">{tags}</div>
        </div>
    );
};
