import { useEffect } from "react";


import { MetricsWidget } from "./components/MetricsWidget";


export const MetricsPage = () => {
    useEffect(() => {
        document.title = "Администрирование метрик";
    }, []);
    return (
        <>       
          
                <MetricsWidget />
           
        </>
    );
};
