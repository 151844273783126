export const getKnowledgesQueryParams = (searchString: [string, string][]) => {
    if (!searchString.length) {
        return "&sort=-created_at";
    }
    const resultParams: { [key: string]: string } = {};
    searchString.reduce((acc, [type, value]) => {
        if (acc[type]) {
            acc[type] += `,${value}`;
        } else {
            acc[type] = value;
        }
        return acc;
    }, resultParams);

    return Object.keys(resultParams)
        .map((key) => {
            if (key === "sort") {
                return `&sort=${resultParams[key]}`;
            }
            return `&filter[${key}]=${resultParams[key]}`;
        })
        .join("");
};
