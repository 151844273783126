import "./FaqBlock.scss";
import { Button } from "@consta/uikit/Button";
import { IconForward } from "@consta/uikit/IconForward";
import { FaqWidget } from "../../../FaqPage/components/FaqWidget";

export const FaqBlock = () => {
    const basepath =
            process.env.REACT_APP_BASEPATH === "/"
                    ? process.env.REACT_APP_BASEPATH
                    : `${process.env.REACT_APP_BASEPATH}/`;
    return (
            <div className='main-page-faq'>
                <div className='main-page-faq-title'>
                    <div>Ответы на частые вопросы</div>
                    <Button size='m' view='ghost' iconRight={IconForward} form='round' label='Смотреть все'
                            onClick={() => window.open(`${basepath}faq`)}/>
                </div>
                <FaqWidget/>
            </div>
    );
};
