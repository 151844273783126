import { FC } from "react";

import { Controller } from "react-hook-form";

import { TextField } from "@consta/uikit/TextField";

import { IconTrash } from "@consta/uikit/IconTrash";

import { HelpdeskFieldsBlockProps } from "./model/EmailFieldsBlock.model";

import "./HelpdeskFieldsBlock.scss";

export const HelpdeskFieldsBlock: FC<HelpdeskFieldsBlockProps> = ({
    emailType,
    index,
    control,
    removeBlock,
}) => {
    const pattern = /(https:\/\/)([а-яА-Яa-zA-Z0-9_-])/;
    return (
        <div className="helpdesk-fields-block">
            <div className="helpdesk-fields-block__title">
                {emailType ? "Почта" : "Ссылка"} №{index + 1}
                <IconTrash
                    onClick={() => removeBlock(index)}
                    size="xs"
                    className="helpdesk-fields-block__title-icon "
                />
            </div>
            {emailType && (
                <>
                    <Controller
                        name={`helpdesks.${index}.name`}
                        control={control}
                        rules={{
                            validate: ({ value }) => {
                                if (!value) return "Введите заголовок";
                            },
                        }}
                        render={({ field, fieldState }) => {
                            return (
                                <TextField
                                    required
                                    placeholder="Заголовок"
                                    label="Отображение почты"
                                    width="full"
                                    className={fieldState.error ? "form-error" : ""}
                                    {...field}
                                    value={field.value.value}
                                />
                            );
                        }}
                    />
                    <Controller
                        name={`helpdesks.${index}.link`}
                        control={control}
                        rules={{
                            validate: ({ value }) => {
                                if (!value) return "Вставьте почтовый адрес";
                                if (
                                    !value.match(
                                        /^[a-zA-Zа-яА-Я0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Zа-яА-Я0-9-]+(?:\.[a-zA-Zа-яА-Я0-9-]+)*$/
                                    )
                                )
                                    return "not email";
                            },

                            //validate: (value) => !value.value.match(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/) ? 'errir'
                        }}
                        render={({ field, fieldState }) => {
                            return (
                                <TextField
                                    required
                                    placeholder="Вставьте почтовый адрес"
                                    label="Почтовый адрес"
                                    width="full"
                                    {...field}
                                    onChange={field.onChange}
                                    className={fieldState.error ? "form-error" : ""}
                                    value={field.value.value}
                                />
                            );
                        }}
                    />
                </>
            )}
            {!emailType && (
                <>
                    <Controller
                        name={`helpdesks.${index}.name`}
                        control={control}
                        rules={{
                            validate: ({ value }) => {
                                if (!value) return "введите заголовок";
                            },
                        }}
                        render={({ field, fieldState }) => {
                            return (
                                <TextField
                                    required
                                    placeholder="Заголовок"
                                    label="Заголовок ссылки"
                                    width="full"
                                    {...field}
                                    value={field.value.value}
                                    className={fieldState.error ? "form-error" : ""}
                                />
                            );
                        }}
                    />
                    <Controller
                        name={`helpdesks.${index}.link`}
                        control={control}
                        rules={{
                            validate: ({ value }) => {
                                if (!value) return "required";
                                if (!value.match(pattern)) return "not email";
                            },
                        }}
                        render={({ field, fieldState }) => {
                            return (
                                <TextField
                                    required
                                    placeholder="Вставьте ссылку на ресурс"
                                    label="Ссылка"
                                    width="full"
                                    {...field}
                                    onChange={field.onChange}
                                    className={fieldState.error ? "form-error" : ""}
                                    value={field.value.value}
                                />
                            );
                        }}
                    />
                </>
            )}
        </div>
    );
};
