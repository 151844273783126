import React from "react";

import { PageRoutes } from "./appRoutes";
import labIco from "../shared-front/AppHeader/assets/ico_datalab.svg";

export const appSideBarMenuItems = {
    label: "Лаборатория данных",
    href: PageRoutes.DataLab,
    icon: <img src={labIco} alt={""} />,
    children: [
        { label: "Главная", href: PageRoutes.DataLab_MainPage },
        {
            label: "Магазин данных",
            extUrl: PageRoutes.DataLab_ShopDataPage,
            disabled: true,
        },
        { label: "Инструменты", href: PageRoutes.DataLab_ToolsPage },
        { label: "Обучение", extUrl: PageRoutes.DataLab_EducationPage, disabled: true },
        { label: "База Знаний", href: PageRoutes.DataLab_KnowledgesPage },
        { label: "Портфолио", href: PageRoutes.DataLab_PortfolioPage },
        { label: "FAQ", href: PageRoutes.DataLab_Faq },
    ],
};
