import { EmailDto, ResourceDto } from "store/types/Team.model";

export const getdDefaultFormValueForHelpdesk = (helpdesks: EmailDto[] | ResourceDto[]) =>
    helpdesks.map((item) => {
        return {
            name: { value: item.name },
            link: { value: item.link },
            type: item.type,
        };
    });
