import { useState, FC } from "react";

import { Collapse } from "@consta/uikit/Collapse";

import { FaqWidgetItemProps } from "./model/FaqWidgetItem.model";

import "./FaqWidgetItem.scss";

export const FaqWidgetItem: FC<FaqWidgetItemProps> = ({ label, content }) => {
    const [isOpen, setIsOpen] = useState(false);
    return (
        <Collapse
            label={label}
            isOpen={isOpen}
            onClick={() => setIsOpen(!isOpen)}
            iconPosition="right"
            className="faq-widget-item"
        >
            <div className="faq-widget-item__content">{content}</div>
        </Collapse>
    );
};
