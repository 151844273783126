interface Block {
    block: string;
    tools: Tool[];
}

interface Tool {
    uuid: string;
    link: string;
}

export const createUpdateAdminBlocksParams = (params: {
    [key: string]: { value: string };
}): Block[] => {
    const blocks: { [key: string]: Block } = {};
    for (const key in params) {
        const [tool, block] = key.split(" ");
        if (!blocks[block]) {
            blocks[block] = { block: block.slice(6), tools: [] };
        }
        if (params[key].value) {
            blocks[block].tools.push({ uuid: tool.slice(5), link: params[key].value });
        }
    }
    return Object.values(blocks);
};
