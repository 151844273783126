import { FC, useMemo, useState } from "react";

import { useNavigate } from "react-router-dom";

import { PageRoutes } from "appMenuAndRouting/appRoutes";

import moment from "moment";
import "moment/locale/ru";
import { PortfolioItemProps } from "./model/PortfolioItem.model";

import "./PortfolioItem.scss";
import { Tag } from "@consta/uikit/Tag";
import { Button } from "@consta/uikit/Button";
import parse from "html-react-parser";
import { IconEdit } from "@consta/uikit/IconEdit";
import { IconTrash } from "@consta/uikit/IconTrash";
import {
    useDeletePortfolioMutation,
    useGetCurrentUserRightsQuery,
} from "../../../../../../../../store/labApi";
import { checkPermission } from "../../../../../../../../shared/utils/rights.utils";
import {
    blpsKnowledgesRights,
    brdKnowledgesRights,
    cfKnowledgesRights,
} from "../../../../../../../KnowledgePage/components/KnowledgeBlock/components/KnowledgeItems/KnowledgeItem/KnowledgeItem.const";
import { DeleteModal } from "../../../../../../../../components/DeleteModal/DeleteModal";

export const PortfolioItem: FC<PortfolioItemProps> = ({
    id,
    mainImage,
    title,
    description,
    blockTitle,
    toolIcon,
    date,
    toolName,
}) => {
    // const isAdmin = localStorage.getItem("USER_ROLE") === "ADMIN";

    const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false);
    const [deletePortfolio, { isLoading: isLoadingDeletePortfolio }] =
        useDeletePortfolioMutation();

    const { data: userData } = useGetCurrentUserRightsQuery();
    const rightsData = userData?.roles ?? undefined;

    const canEditPortfolio = useMemo(() => {
        return (
            (blockTitle === "БРД" && checkPermission(brdKnowledgesRights, rightsData)) ||
            (blockTitle === "БЛПС" &&
                checkPermission(blpsKnowledgesRights, rightsData)) ||
            (blockTitle === "КФ" && checkPermission(cfKnowledgesRights, rightsData))
        );
    }, [blockTitle, rightsData]);

    const isAdmin = localStorage.getItem("USER_ROLE") === "ADMIN";

    const navigate = useNavigate();
    const IconDelete = () => (
        <IconTrash size={"s"} style={{ margin: "0 auto", color: "red" }} />
    );

    const basepath =
        process.env.REACT_APP_BASEPATH === "/"
            ? "/"
            : `${process.env.REACT_APP_BASEPATH}/`;

    return (
        <div className="portfolio-item">
            <div
                className="header-img"
                onClick={() => navigate(`${PageRoutes.DataLab_PortfolioPage}/${id}`)}
            >
                <img
                    className="main-img"
                    src={`${process.env.REACT_APP_API_URL}/images/${mainImage}`}
                />
            </div>
            <div className="portfolio-card-content">
                <div className="content-header">
                    <div>
                        <img
                            src={`${process.env.REACT_APP_API_URL}/images/${toolIcon}`}
                        />
                        {toolName}
                    </div>
                    <Tag size="s" label={blockTitle} mode={"info"}></Tag>
                </div>

                <div
                    className="content-title"
                    onClick={() => navigate(`${PageRoutes.DataLab_PortfolioPage}/${id}`)}
                >
                    {title}
                </div>
                <div
                    className="content-text"
                    onClick={() => navigate(`${PageRoutes.DataLab_PortfolioPage}/${id}`)}
                >
                    {parse(description || "")}
                </div>
                <div className="content-bottom">
                    <div className="portfolio-edit-buttons">
                        <Button
                            size="s"
                            view="primary"
                            form="round"
                            label="Подробнее"
                            onClick={() =>
                                navigate(`${PageRoutes.DataLab_PortfolioPage}/${id}`)
                            }
                        />
                        {isAdmin && canEditPortfolio && (
                            <>
                                <Button
                                    size="s"
                                    view="ghost"
                                    form="round"
                                    onlyIcon
                                    iconRight={IconEdit}
                                    onClick={() => {
                                        window.location.replace(
                                            `${basepath}portfolio/edit/${id}`
                                        );
                                    }}
                                />
                                <Button
                                    size="s"
                                    view="ghost"
                                    form="round"
                                    onlyIcon
                                    iconRight={IconDelete}
                                    onClick={() => setIsOpenDeleteModal(true)}
                                />
                                <DeleteModal
                                    isOpen={isOpenDeleteModal}
                                    cancelAction={() => setIsOpenDeleteModal(false)}
                                    confirmAction={async () => {
                                        await deletePortfolio(id);
                                        window.location.replace(`${basepath}portfolio`);
                                    }}
                                    title={`Удалить кейс "${title}"?`}
                                    content={`Вы действительно хотите удалить кейс “${title}” и все данные в нем?`}
                                    loading={isLoadingDeletePortfolio}
                                />
                            </>
                        )}
                    </div>

                    <div> {moment(date).locale("ru").format("DD MMMM YYYY")}</div>
                </div>
            </div>
        </div>
    );
};
