import { formatType } from "shared/constants/formatTypes.const";

export const materialTypesItems = [
    {
        label: "Видео",
        id: formatType.VIDEO,
    },
    {
        label: "Статья",
        id: formatType.INTERNAL,
    },
    {
        label: "Ссылка",
        id: formatType.LINK,
    },
    {
        label: "Файл",
        id: formatType.FILE,
    },
];
