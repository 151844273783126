import React from "react";
import classes from "./TextInfo.module.css";

const TextInfo = () => {
    return (
        <div className={classes.container}>
            После отправки заявки кейс уходит на модерацию. После рассмотрения заявки кейс
            будет опубликован в портфолио
        </div>
    );
};

export default TextInfo;
