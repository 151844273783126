import { useContext, useEffect, useRef, useState } from "react";

import { useGetMetricsQuery } from "store/labApi";

import { divideNumIntoCategories, enumerate } from "shared/utils";

import { MetricKey } from "shared/constants/metrics.const";

import { ScrollContext } from "pages/MainPage/utils/ScrollContext";

import "./IncreasingCompetenciesBlock.scss";
import { Button } from "@consta/uikit/Button";
import { IconForward } from "@consta/uikit/IconForward";
import { SupportModal } from "../../../../components/SupportModal/SupportModal";
import { PageRoutes } from "../../../../appMenuAndRouting/appRoutes";

export const IncreasingCompetenciesBlock = () => {
    const { data } = useGetMetricsQuery(undefined, { refetchOnMountOrArgChange: true });

    const { updateRefEducation, updateRefIncreasingCompetencies } =
        useContext(ScrollContext);

    const refEducation = useRef<HTMLDivElement>(null);
    const refIncreasingCompetencies = useRef<HTMLDivElement>(null);

    const courseLearnedCountValue =
        data?.find((metric) => metric.key === MetricKey.COURSE_LEARNED_COUNT)?.value ??
        "";

    const helpdeskCallCountValue =
        data?.find((metric) => metric.key === MetricKey.HELPDESK_CALL_COUNT)?.value ?? "";

    const knowledgeCallCountValue = data?.find(
        (metric) => metric.key === MetricKey.KNOWLEDGE_COUNT
    )?.value;

    const basepath =
        process.env.REACT_APP_BASEPATH === "/"
            ? process.env.REACT_APP_BASEPATH
            : `${process.env.REACT_APP_BASEPATH}/`;

    useEffect(() => {
        updateRefEducation(refEducation.current);
        updateRefIncreasingCompetencies(refIncreasingCompetencies.current);
    });

    const [isRequestModalOpen, setIsRequestModalOpen] = useState<boolean>(false);

    return (
        <div className="increasing-competencies-block">
            <div className="competence-wrap">
                <div className="subtitle">На каждом шаге</div>
                <div className="title">Повышайте компетенции</div>
                <div className="desc">
                    Наши курсы и гайды научат выбирать, обрабатывать, анализировать и
                    визуализировать данные
                </div>
            </div>
            <div className="competition-items">
                <div className="knowledge">
                    <div className="c-item-head">
                        <div
                            className="c-title"
                            onClick={() => window.open(basepath + `knowledge`, "_blank")}
                        >
                            База знаний
                        </div>
                        <div className="c-desc">
                            Изучите обучающие материалы и инструкции, которые помогут вам
                            при работе в Лаборатории данных.
                        </div>
                        <Button
                            size="m"
                            view="clear"
                            form="default"
                            iconRight={IconForward}
                            label="Открыть"
                            onClick={() => window.open(basepath + `knowledge`, "_blank")}
                        />
                    </div>
                    <div className="bg"></div>
                    <div className="count-text">
                        <div className="number">
                            {divideNumIntoCategories(+(knowledgeCallCountValue ?? ""))}
                        </div>
                        <div className="number-desc">
                            {enumerate(knowledgeCallCountValue || "", [
                                "Материал доступен",
                                "Материала доступны",
                                "Материалов доступны",
                            ])}{" "}
                            для изучения
                        </div>
                    </div>
                </div>
                <div>
                    <div className="c-item-head">
                        <div
                            className="c-title"
                            onClick={() =>
                                window.open(PageRoutes.DataLab_EducationPage, "_blank")
                            }
                        >
                            Обучение
                        </div>
                        <div className="c-desc">
                            Не знаете, с чего начать работу в Лаборатории данных?
                            Записывайтесь на онлайн-курсы обучения.
                        </div>
                        <Button
                            size="m"
                            view="clear"
                            form="default"
                            iconRight={IconForward}
                            // label="Открыть"
                            label="Скоро появится"
                            disabled
                            onClick={() =>
                                window.open(PageRoutes.DataLab_EducationPage, "_blank")
                            }
                        />
                    </div>
                    <div className="bg"></div>
                    <div className="count-text">
                        <div className="number">
                            {divideNumIntoCategories(+courseLearnedCountValue)}
                        </div>
                        <div className="number-desc">
                            {enumerate(courseLearnedCountValue || "", [
                                "Пользователь прошел",
                                "Пользователя прошли",
                                "Пользователей прошли",
                            ])}{" "}
                            обучение
                        </div>
                    </div>
                </div>
            </div>
            <div className="big-consultation">
                <div className="b-content">
                    <div className="b-title">Получите консультацию</div>
                    <div className="b-desc">
                        Сотрудники лаборатории расскажут с чего начать или как пройти
                        определённый этап процесса — готовы помочь с любым вопросом
                    </div>
                    <div className="b-number">
                        {divideNumIntoCategories(+helpdeskCallCountValue)}{" "}
                        {enumerate(courseLearnedCountValue || "", [
                            "Обращение",
                            "Обращения",
                            "Обращений",
                        ])}
                    </div>
                    <div className="b-number-desc">
                        <div className="b-number-desc-desc">
                            Все решенные: о процессах, визуализации, расчетах, доступах и
                            многом другом
                        </div>
                        <Button
                            size="m"
                            view="primary"
                            form="round"
                            label="Оставить заявку"
                            onClick={() => setIsRequestModalOpen(true)}
                        />
                        <SupportModal
                            mode={"short"}
                            handlerClose={() => setIsRequestModalOpen(false)}
                            open={isRequestModalOpen}
                        />
                    </div>
                </div>
            </div>
            {/*<ConsultationCard value={helpdeskCallCountValue}/>*/}
        </div>
    );
};
