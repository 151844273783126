import { useEffect } from "react";

import { useLocation } from "react-router-dom";

import { Breadcrumbs } from "@consta/uikit/Breadcrumbs";

import { PageRoutes } from "appMenuAndRouting/appRoutes";

import { PageContentWrapper } from "components/PageContentWrapper";
import { FormMutatePortfolio } from "pages/PortfolioCreatePage/components/FormMutatePortfolio";

export const PortfolioEditPage = () => {
    const { pathname } = useLocation();

    const pathnameAsArray = pathname.split("/");
    const portfolioId = pathnameAsArray[pathnameAsArray.length - 1];

    const items = [
        {
            label: "Лаборатория данных",
            href: PageRoutes.DataLab_MainPage,
        },
        {
            label: "Портфолио",
            href: PageRoutes.DataLab_PortfolioPage,
        },
        {
            label: "Редактирование портфолио",
            href: PageRoutes.DataLab_PortfolioCreatePage,
        },
    ];

    useEffect(() => {
        document.title = "Редактирование портфолио";
    }, []);

    return (
        <PageContentWrapper className="portfolio-mutate-page">
            <Breadcrumbs items={items} />
                <div className="portfolio-mutate-page__title">Редактирование кейса </div>
                <FormMutatePortfolio id={portfolioId} />
        </PageContentWrapper>
    );
};
