import { FC } from "react";

import { enumerate } from "shared/utils";

import { HeroMainUsersBlockProps } from "./model/HeroMainUsersBlock.model";

import "./HeroMainUsersBlock.scss";

export const HeroMainUsersBlock: FC<HeroMainUsersBlockProps> = ({ usersCount }) => (
    <div className="hero-block-main__users">
        <div className="hero-block-main__users-row">
            <span className="hero-block-main__users-count">{usersCount}</span>
        </div>
        <div className="hero-block-main__users-text">
            {enumerate(usersCount, ["Пользователь", "Пользователя", "Пользователей"])} уже работают  в Лаборатории данных
        </div>
    </div>
);
