import { PageRoutes } from "./appRoutes";

// объект PageRoutes мутирует при получении данных из /public/assets/config.json,
// для получения актуальны данных требуется массив ссылок должен формироваться
// в процессе вызова функции
export const getAppHeaderMenuItems = () => [
    { label: "Главная", href: PageRoutes.DataLab_MainPage },
    {
        label: "Инструменты",
        href: PageRoutes.DataLab_ToolsPage,
    },
    {
        label: "Обучение",
        extUrl: PageRoutes.DataLab_EducationPage,
        disabled: true,
    },
    {
        label: "База знаний",
        href: PageRoutes.DataLab_KnowledgesPage,
    },
    {
        label: "Портфолио",
        href: PageRoutes.DataLab_PortfolioPage,
    },
    {
        label: "FAQ",
        href: PageRoutes.DataLab_Faq,
    },
];
export const getAppHeaderAdminMenuItems = () => [
    ...getAppHeaderMenuItems(),
    {
        label: "Метрики",
        href: PageRoutes.DataLab_MetricsPage,
    },
];
