import { useEffect } from "react";
import { Breadcrumbs } from "@consta/uikit/Breadcrumbs";

import { PageContentWrapper } from "components/PageContentWrapper";
import { FormEditKnowledge } from "./components/FormEditKnowledge";

export const KnowledgeEditPage = () => {
    const basepath =
        process.env.REACT_APP_BASEPATH === "/"
            ? "/"
            : `${process.env.REACT_APP_BASEPATH}/`;

    const items = [
        {
            label: "Лаборатория данных",
            href: `${process.env.REACT_APP_BASEPATH}`,
        },
        {
            label: "База знаний",
            href: `${basepath}knowledge`,
        },
        {
            label: "Редактирование материала",
            href: `${basepath}knowledge/edit`,
        },
    ];

    useEffect(() => {
        document.title = "База знаний";
    }, []);

    return (
        <PageContentWrapper className="knowledge-mutate-page">
            <Breadcrumbs items={items} />

                <div className="knowledge-mutate-page__title">
                    Редактирование материала
                </div>
                <FormEditKnowledge />

        </PageContentWrapper>
    );
};
