import { FC, useContext, useEffect, useRef, useState } from "react";

import { useGetMainPagePortfoliosQuery, useGetMetricsQuery } from "store/labApi";

import Slider from "react-slick";

import { PortfolioCard } from "./components/PortfolioCard";
// import { BlockWrapper } from "components/BlockWrapper";
// import { PortfolioPlugBlock } from "./components/PortfolioPlugBlock";
// import { CarouselButtons } from "./components/CarouselButtons";
import { ScrollContext } from "pages/MainPage/utils/ScrollContext";

import { Button } from "@consta/uikit/Button";

// import gradient_cup from "assets/icons/icn_gradient-cup.svg";
//
// import portfolioBlockContent from "templateContent/mainPage/portfolioBlockContent.json";
import "./PortfolioBlock.scss";
// import { MetricTypeDto } from "../../../../store/types/Metrics.model";
// import { MetricKey } from "../../../../shared/constants/metrics.const";
import { IconBackward } from "@consta/uikit/IconBackward";
import { IconForward } from "@consta/uikit/IconForward";
import { MetricTypeDto } from "../../../../store/types/Metrics.model";
import { MetricKey } from "../../../../shared/constants/metrics.const";
import { divideNumIntoCategories, enumerate } from "../../../../shared/utils";

export const PortfolioBlock: FC<{ portfolioCount: string | undefined }> = () => {
    const { data: metricsData } = useGetMetricsQuery(undefined, {
        refetchOnMountOrArgChange: true,
    });
    const portfolioCountValue =
        metricsData?.find(
            (metric: MetricTypeDto) => metric.key === MetricKey.PORTFOLIO_COUNT
        )?.value ?? "0";

    const { updateRefPortfolio } = useContext(ScrollContext);

    const basepath =
        process.env.REACT_APP_BASEPATH === "/"
            ? process.env.REACT_APP_BASEPATH
            : `${process.env.REACT_APP_BASEPATH}/`;

    const refPorfolio = useRef<HTMLDivElement>(null);
    const sliderRef = useRef<Slider>(null);

    const { data } = useGetMainPagePortfoliosQuery(undefined, {
        refetchOnMountOrArgChange: true,
    });

    const [currentSlide, setCurrentSlide] = useState(0);

    const portfolioLength = data?.length || 0;
    const settings = {
        infinite: true,
        speed: 300,
        slidesToScroll: 1,
        variableWidth: true,
        slide: ".portfolio-card",
        draggable: false,
    };

    useEffect(() => {
        updateRefPortfolio(refPorfolio.current);
    });

    return (
        <div className="slider-area">
            <div className="portfolio-block">
                <div className="portfolio-slider">
                    <Slider
                        {...settings}
                        beforeChange={(currentSlide, nextSlide) =>
                            setCurrentSlide(nextSlide)
                        }
                        // afterChange={setCurrentSlide}
                        ref={sliderRef}
                    >
                        {data?.map((item, index) => (
                            <PortfolioCard
                                key={item.guid}
                                id={item.guid}
                                image={item.main_image}
                                name={item.name}
                                description={item.description}
                                blockImage={item.tool.uuid_image}
                                blockTitle={item.block?.s_name}
                                toolImage={item.tool.uuid_image}
                                toolName={item.tool.name}
                                toolIcon={item.tool.uuid_image_icon}
                                date={item._create_ts}
                                isCurrentSLide={currentSlide === index}
                            />
                        ))}
                    </Slider>
                </div>
                <div className="buttons">
                    <Button
                        size="m"
                        view="ghost"
                        form="round"
                        onlyIcon
                        iconRight={IconBackward}
                        onClick={() => sliderRef.current?.slickPrev()}
                    ></Button>
                    <span>
                        {currentSlide + 1} &nbsp; из &nbsp; {portfolioLength}
                    </span>
                    <Button
                        size="m"
                        view="ghost"
                        form="round"
                        onlyIcon
                        iconRight={IconForward}
                        onClick={() => sliderRef.current?.slickNext()}
                    ></Button>
                </div>
                <div className="portfolio-text">
                    <div className="title">
                        {`${divideNumIntoCategories(+portfolioCountValue)} ${enumerate(
                            portfolioCountValue,
                            [
                                "Реализованный кейс",
                                "Реализованных кейсов",
                                "Реализованных кейсов",
                            ]
                        )}`}
                    </div>
                    <div className="desc">
                        {`${enumerate(portfolioCountValue, [
                            "Опубликован",
                            "Опубликовано",
                            "Опубликовано",
                        ])} в портфолио и ${enumerate(portfolioCountValue, [
                            "доступен",
                            "доступно",
                            "доступно",
                        ])} для изучения`}
                    </div>
                    <Button
                        size="m"
                        view="primary"
                        form="round"
                        label="Смотреть все"
                        onClick={() => window.open(`${basepath}portfolio`, "_blank")}
                    />
                </div>
            </div>
            <div className="bg"></div>
        </div>
    );
};
