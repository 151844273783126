import { createIcon } from '@consta/uikit/createIcon';

const svg = () => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M16 19.619C19.3751 19.619 21.5 16.0331 21.5 12.8095C21.5 9.58598 21.0926 6 16 6C10.9074 6 10.5 9.58598 10.5 12.8095C10.5 16.0331 12.6249 19.619 16 19.619Z"
      fill="#00395C"
      fillOpacity="0.8"
    />
    <path
      d="M14.7778 22.0952C8.05556 22.0952 5 24.6373 5 32L27 32C27 23.746 22.7222 22.0952 17.2222 22.0952H14.7778Z"
      fill="#00395C"
      fillOpacity="0.8"
    />
  </svg>
);

export const IconCustomUser = createIcon({
  m: svg,
  s: svg,
  xs: svg,
  name: 'IconCustomUser',
});
