import { FC, useEffect, useState } from "react";

import { useUpdateMetricMutation } from "store/labApi";

import { TextField } from "@consta/uikit/TextField";
import { IconCheck } from "@consta/uikit/IconCheck";
import { Button } from "@consta/uikit/Button";
import { Informer } from "@consta/uikit/Informer";
import { ErrorType } from "shared/types/ErrorType";

import { MetricValueBlock } from "./components/MetricValueBlock";

import { MetricBlockProps } from "./model/MetricBlock.model";

import "./MetricBlock.scss";

export const MetricBlock: FC<MetricBlockProps> = ({
    title,
    value,
    sumValue,
    date,
    description,
    blockName,
    entity,
    entityDescription,
    blockId,
    metricId,
    refetchBlockQuery,
}) => {
    const [updatedValue, setUpdatedValue] = useState<string | null>(null);
    const updatedValueIsValid =
        !!updatedValue &&
        !Number.isNaN(Number(updatedValue)) &&
        Number(updatedValue) > -1 &&
        Number(updatedValue) <= 100000 &&
        Number.isInteger(Number(updatedValue));
    const [hasError, setHasError] = useState(false);

    const [updateMetric, { error }] = useUpdateMetricMutation();

    const handleChange = (value: string | null) => {
        let inputValue = value?.replace(/[^\d]/g, "") ?? ""; // Удаляем все недопустимые символы
        if (inputValue.startsWith("0") && inputValue.length > 1) {
            inputValue = inputValue.slice(1); // Удаляем ведущий ноль
        }

        if (Number(inputValue) > 100000) {
            inputValue = "100000"; // Ограничиваем максимальное значение
        }

        setUpdatedValue(inputValue);
    };

    const handleUpdateMetric = async () => {
        await updateMetric({
            id: blockId,
            body: {
                metrics: [
                    {
                        uuid_metric: metricId,
                        lng_value: Number(updatedValue),
                    },
                ],
            },
        })
            .unwrap()
            .catch((er) => {
                console.log(er);
            });
        refetchBlockQuery();
        setUpdatedValue(null);
    };

    useEffect(() => {
        if (error) {
            setHasError(true);
            setTimeout(() => {
                setHasError(false);
            }, 7000);
        }
    }, [error]);

    return (
        <>
            <div className="metric-block">
                <div className="metric-block__left-column">
                    {hasError && error && (
                        <Informer
                            status="alert"
                            view="filled"
                            title="Ошибка в изменении метрики"
                            label={"data" in error ? (error as ErrorType).data.error : ""}
                            style={{
                                position: "absolute",
                                right: "10px",
                                bottom: "10px",
                                width: "250px",
                            }}
                        />
                    )}
                    <div className="metric-block__title">{title}</div>

                    <div className="metric-block__description">{description}</div>

                    <div className="metric-block__textfield">
                        <TextField
                            value={updatedValue}
                            onChange={(args) => {
                                handleChange(args.value);
                            }}
                            // обнаружил что ломается инпут при type="number" если ввести сначала цифры, потом знак "минус"
                            // type="number"
                            placeholder="Введите новое значение"
                            label={`Текущее значения ${blockName}: ${value} от ${date}`}
                        />
                        {updatedValueIsValid && (
                            <Button
                                className="metric-block__textfield-button"
                                iconRight={IconCheck}
                                onlyIcon
                                size="s"
                                view="primary"
                                onClick={handleUpdateMetric}
                            />
                        )}
                    </div>
                </div>
                <div className="metric-block__right-column">
                    <MetricValueBlock
                        value={sumValue}
                        entity={entity}
                        entityDescription={entityDescription}
                    />
                </div>
            </div>
        </>
    );
};
