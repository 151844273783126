import { FC, KeyboardEvent, useEffect, useRef, useState } from "react";

import { Controller, useForm } from "react-hook-form";

import { useCreateUserMutation, useLazyGetExternalUsersQuery } from "store/labApi";

import { UserSelect } from "@consta/uikit/UserSelect";
import { TextField } from "@consta/uikit/TextField";
import { cnMixSpace } from "@consta/uikit/MixSpace";
import { Button } from "@consta/uikit/Button";
import { Informer } from "@consta/uikit/Informer";

import { createParams } from "./CreateContactModal.utils";

import { ModalWrapper } from "shared/components/ModalWrapper";

import { ErrorType } from "shared/types/ErrorType";

import { getExternalEmployees } from "shared/utils/users.utils";

import {
    CreateContactModalProps,
    CreateContactModalSubmitData,
} from "./model/CreateContactModal.model";

import "../MutateContactModal.scss";

import useDebouncedFunction from "../../../../../../../../shared/utils/debounce.ustils";
import classNames from "classnames";

export const CreateContactModal: FC<CreateContactModalProps> = ({
    title,
    isOpen,
    handleClose,
    blockId,
    refetch,
}) => {
    const { handleSubmit, control, setValue, clearErrors } = useForm();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [hasError, setHasError] = useState(false);
    const [users, setUsers] = useState<
        | {
              label: string;
              subLabel: string;
              avatarUrl: string;
              id: string;
          }[]
        | undefined
    >([]);

    const [getUsers] = useLazyGetExternalUsersQuery();
    const abortRef = useRef<any | undefined>();

    const [createUser, { error, isLoading: saveLoading }] = useCreateUserMutation();

    const onSubmit = async (data: CreateContactModalSubmitData) => {
        const params = createParams(data, blockId);
        await createUser(params).unwrap();
        refetch();
        extendedHandleClose();
    };

    const extendedHandleClose = () => {
        handleClose();
        setUsers(undefined);
        setValue("employee", undefined);
        setValue("position", undefined);
        clearErrors();
    };

    const sendRequest = useDebouncedFunction(
        async (input) => {
            try {
                const request = getUsers(input.value);
                abortRef.current = request;
                const { data } = await request.unwrap();
                setUsers(getExternalEmployees(data?.models));
                setIsLoading(false);
            } catch (error) {
                if ((error as Error)?.name !== "AbortError") throw error;
            } finally {
                abortRef.current = undefined;
            }
        },
        500,
        true
    );

    const handleSearchEmployee = async (event: KeyboardEvent<HTMLDivElement>) => {
        const input = event.target as HTMLInputElement;
        if (input.value.length < 2) {
            setUsers([]);
            setIsLoading(false);
        } else {
            abortRef.current?.abort();
            setIsLoading(true);
            sendRequest(input);
        }
    };

    useEffect(() => {
        if (error) {
            setHasError(true);
            setTimeout(() => {
                setHasError(false);
            }, 7000);
        }
    }, [error]);

    return (
        <>
            <ModalWrapper title={title} isOpen={isOpen} handleClose={extendedHandleClose}>
                {hasError && (
                    <Informer
                        status="alert"
                        view="filled"
                        title="Ошибка в создании контакта"
                        label={(error as ErrorType)?.data.error}
                    />
                )}
                <form onSubmit={handleSubmit(onSubmit)} className="mutate-contact-form">
                    <Controller
                        name="employee"
                        control={control}
                        rules={{
                            required: true,
                            validate: (value) => value?.value !== null,
                        }}
                        render={({ field, fieldState }) => {
                            return (
                                <UserSelect
                                    placeholder="Выберите сотрудника"
                                    label="Выбрать контакт"
                                    required
                                    items={users || []}
                                    {...field}
                                    searchFunction={() => true}
                                    isLoading={isLoading}
                                    value={field.value?.value}
                                    onKeyUp={(e) => handleSearchEmployee(e)}
                                    className={classNames(
                                        cnMixSpace({
                                            mB: "l",
                                        }),
                                        fieldState.error ? "form-error" : ""
                                    )}
                                    status={fieldState.error ? "alert" : undefined}
                                />
                            );
                        }}
                    />
                    <Controller
                        name="position"
                        control={control}
                        rules={{
                            required: true,
                            validate: (value) => value?.value !== null,
                        }}
                        render={({ field, fieldState }) => {
                            return (
                                <TextField
                                    required
                                    width="full"
                                    label="Роль"
                                    placeholder="Введите роль"
                                    {...field}
                                    value={field.value?.value}
                                    className={fieldState.error ? "form-error" : ""}
                                    status={fieldState.error ? "alert" : undefined}
                                />
                            );
                        }}
                    />

                    <div className="mutate-contact-form__buttons">
                        <Button
                            type="button"
                            label="Отменить"
                            onClick={extendedHandleClose}
                            view="ghost"
                        />
                        <Button
                            type="submit"
                            label="Сохранить"
                            className={cnMixSpace({
                                mL: "m",
                            })}
                            loading={saveLoading}
                        />
                    </div>
                </form>
            </ModalWrapper>
        </>
    );
};
