import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { Controller, FormProvider, useForm } from "react-hook-form";

import { useCreateKnowledgeMutation, useGetKnowledgeFiltersQuery } from "store/labApi";

import { TextField } from "@consta/uikit/TextField";
import { Combobox } from "@consta/uikit/Combobox";
import { Button } from "@consta/uikit/Button";
import { cnMixSpace } from "@consta/uikit/MixSpace";
import { Informer } from "@consta/uikit/Informer";
import { Loader } from "@consta/uikit/Loader";

import { createParams, getComboboxValideValuesShared } from "shared/utils";
import { MaterialField } from "shared/components/MaterialField";
import { materialTypesItems } from "shared/constants/materialTypes.const";
import { ErrorType } from "shared/types/ErrorType";
import { FormMutateKnowledgeSubmitType } from "shared/types/mutateKnowledgeType";

import "./FormCreateKnowledge.scss";
import { ChoiceGroup } from "@consta/uikit/ChoiceGroup";
import { PageRoutes } from "../../../../appMenuAndRouting/appRoutes";

export const FormCreateKnowledge = () => {
    const navigate = useNavigate();

    const [hasError, setHasError] = useState(false);
    const methods = useForm({
        mode: "onChange",
    });
    const control = methods.control;
    const handleSubmit = methods.handleSubmit;
    const formState = methods.formState;
    const isDirtyForm = formState.isDirty;
    const isValidForm = formState.isValid;

    const [createKnowledge, { error, isLoading: isLoadingCreateKnowledge }] =
        useCreateKnowledgeMutation();

    const { data, isLoading: isLoadingFilters } = useGetKnowledgeFiltersQuery();
    const blocksItems = getComboboxValideValuesShared(data?.blocks);
    const complexitiesItems = getComboboxValideValuesShared(data?.complexities);
    const toolsItems = getComboboxValideValuesShared(data?.tools);
    const tagsItems = getComboboxValideValuesShared(data?.tags);
    const basepath =
        process.env.REACT_APP_BASEPATH === "/"
            ? "/"
            : `${process.env.REACT_APP_BASEPATH}/`;

    const onSubmit = async (data: FormMutateKnowledgeSubmitType) => {
        if (!data.tags) {
            delete data.tags;
        }
        const params = createParams(data);
        await createKnowledge(params).unwrap();
        !error && navigate(basepath + "knowledge");
    };

    useEffect(() => {
        if (error) {
            setHasError(true);
            setTimeout(() => {
                setHasError(false);
            }, 7000);
        }
    }, [error]);

    if (isLoadingFilters || isLoadingCreateKnowledge) {
        return <Loader />;
    }

    return (
        <>
            {hasError && error && (
                <Informer
                    status="alert"
                    view="filled"
                    title="Ошибка в создании документа"
                    label={"data" in error ? (error as ErrorType).data.error : ""}
                />
            )}
            <FormProvider {...methods}>
                <form onSubmit={handleSubmit(onSubmit)} className="form-create-document">
                    <div className="form-title">Тип материала</div>
                    <div className="choice-group">
                        <Controller
                            name="material_type"
                            control={control}
                            defaultValue={{
                                value: materialTypesItems[1],
                            }}
                            render={({ field }) => {
                                return (
                                    <ChoiceGroup
                                        items={materialTypesItems}
                                        multiple={false}
                                        {...field}
                                        value={field.value?.value}
                                        size="m"
                                    />
                                );
                            }}
                        />
                    </div>
                    <div className="form-title">О материале</div>
                    <div className="form-container">
                        <Controller
                            name="title"
                            control={control}
                            rules={{
                                required: true,
                                validate: (value) => value?.value !== null,
                            }}
                            defaultValue={{ value: null }}
                            render={({ field, fieldState }) => {
                                return (
                                    <TextField
                                        required
                                        className={fieldState.error ? "form-error" : ""}
                                        placeholder="Введите название"
                                        label="Название"
                                        {...field}
                                        value={field.value?.value}
                                    />
                                );
                            }}
                        />
                        <Controller
                            name="description"
                            control={control}
                            render={({ field }) => (
                                <TextField
                                    placeholder="Введите описание"
                                    label="Описание"
                                    type="textarea"
                                    rows={6}
                                    // className={cnMixSpace({
                                    //     mB: "xl",
                                    // })}
                                    {...field}
                                    value={field.value?.value}
                                />
                            )}
                        />

                        {blocksItems?.length && (
                            <Controller
                                name="belonging"
                                control={control}
                                rules={{
                                    required: true,
                                    validate: (value) => value?.value !== null,
                                }}
                                defaultValue={{ value: null }}
                                render={({ field, fieldState }) => {
                                    return (
                                        <Combobox
                                            required
                                            placeholder="Выберите блок"
                                            label="Принадлежность к блоку"
                                            multiple
                                            items={blocksItems}
                                            className={
                                                fieldState.error ? "form-error" : ""
                                            }
                                            {...field}
                                            value={field.value?.value}
                                        />
                                    );
                                }}
                            />
                        )}
                        {complexitiesItems?.length && (
                            <Controller
                                name="complexity"
                                control={control}
                                rules={{
                                    required: true,
                                    validate: (value) => value?.value !== null,
                                }}
                                defaultValue={{ value: null }}
                                render={({ field, fieldState }) => {
                                    return (
                                        <Combobox
                                            required
                                            placeholder="Выберите сложность"
                                            label="Сложность материала"
                                            items={complexitiesItems}
                                            className={
                                                fieldState.error ? "form-error" : ""
                                            }
                                            {...field}
                                            value={field.value?.value}
                                        />
                                    );
                                }}
                            />
                        )}
                        {toolsItems?.length && (
                            <Controller
                                name="tool"
                                control={control}
                                rules={{
                                    required: true,
                                    validate: (value) => value?.value !== null,
                                }}
                                defaultValue={{ value: null }}
                                render={({ field, fieldState }) => {
                                    return (
                                        <Combobox
                                            required
                                            placeholder="Выберите используемый инструмент"
                                            label="Используемый инструмент"
                                            items={toolsItems}
                                            className={
                                                fieldState.error ? "form-error" : ""
                                            }
                                            {...field}
                                            value={field.value?.value}
                                        />
                                    );
                                }}
                            />
                        )}
                        {tagsItems?.length && (
                            <Controller
                                name="tags"
                                control={control}
                                render={({ field }) => {
                                    return (
                                        <Combobox
                                            placeholder="Выберите тег"
                                            label="Теги"
                                            multiple
                                            items={tagsItems}
                                            // className={cnMixSpace({
                                            //     mB: "xl",
                                            // })}
                                            {...field}
                                            value={field.value?.value}
                                        />
                                    );
                                }}
                            />
                        )}

                        <MaterialField control={control} />

                        <div className="form-create-document__button_group">
                            <Button
                                label="Отмена"
                                view={"ghost"}
                                onClick={() =>
                                    navigate(PageRoutes.DataLab_KnowledgesPage)
                                }
                                className={cnMixSpace({ mR: "xs" })}
                            />
                            <Button
                                label="Добавить документ"
                                type="submit"
                                className="form-create-document__button"
                                disabled={!isDirtyForm || !isValidForm}
                            />
                        </div>
                    </div>
                </form>
            </FormProvider>
        </>
    );
};
