import { FC, useRef, useState } from "react";
// import { Badge } from "@consta/uikit/Badge";
// import { PlugBlock } from "shared/components/PlugBlock";
import { ToolCardProps } from "./model/ToolCard.model";
import "./ToolCard.scss";
import { Tag } from "@consta/uikit/Tag";
import { Button } from "@consta/uikit/Button";
import { IconList } from "@consta/uikit/IconList";
import { ContextMenu } from '@consta/uikit/ContextMenu';


export const ToolCard: FC<ToolCardProps> = ({
                                                links,
                                                description,
                                                icon,
                                                isSanction,
                                                isSoon,
                                                id,
                                                // isLast,
                                                link,
                                                name,
                                            }) => {
    const ref = useRef<HTMLButtonElement>(null);
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const contextItems: { label: string, link: string }[] = [];

    const basepath =
            process.env.REACT_APP_BASEPATH === "/"
                    ? process.env.REACT_APP_BASEPATH
                    : `${process.env.REACT_APP_BASEPATH}/`;

    if (links.length) {
        links.forEach(el => {
            contextItems.push({
                label: el.s_name,
                link: el.block_tool_link
            })
        });
        contextItems.push({
            label: 'База знаний',
            link: basepath + `knowledge/?tools=${id}`
        })
        // console.log(contextItems);
    }
    const getItemOnClick = (item: { label: string, link: string }) => {
        return () => window.open(item.link);
    };

    return (
            <div className="tool-card">
                <div className="tool-card__top">
                    <div>
                        <img
                                src={`${process.env.REACT_APP_API_URL}/images/${icon}`}
                                className="tool-card__image"
                        />
                        {name}
                    </div>
                    {isSoon ? (
                            <a href={link} target='_blank' rel="noreferrer">Открыть справочник</a>
                    ) : (!!links?.length && (
                                    <>
                                        <Button
                                                ref={ref}
                                                size='s'
                                                view='secondary'
                                                form='round'
                                                iconRight={IconList}
                                                label='Открыть'
                                                onClick={() => setIsOpen(!isOpen)}
                                        ></Button>
                                        <ContextMenu
                                                isOpen={isOpen}
                                                items={contextItems}
                                                anchorRef={ref}
                                                size='s'
                                                getItemOnClick={getItemOnClick}
                                                onClickOutside={() => setIsOpen(false)}
                                                direction="downStartLeft"
                                        />
                                    </>
                            )
                    )}

                </div>
                <div className="tool-card__text">{description}</div>
                <div className="tool-card__bottom">

                    {!isSoon ? (
                            !!links?.length && (
                                    <>
                                        <div className="tool-card__bottom-buttons">
                                            {links.map((item) => (
                                                    <Tag
                                                            key={item.guid}
                                                            label={item.s_name}
                                                            size='s'
                                                            onClick={() => window.open(item.block_tool_link)}
                                                    />
                                            ))}
                                        </div>
                                    </>

                            )
                    ) : (
                            <></>
                    )}
                    {isSanction ? (
                            <div className="sanctions">
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <path d="M14 2L12 12H20L10 22L12 12H4L14 2Z" fill="#FF005C"/>
                                </svg>
                                Санкционный

                            </div>
                    ) : (
                            <></>
                            // <Badge label="импортонезависимый" status="success"/>
                    )}
                </div>
            </div>
    )
}
