import { FC, useCallback, useMemo, useState, useEffect } from "react";

import { Controller, useFormContext, useWatch } from "react-hook-form";

import { DragNDropField } from "@consta/uikit/DragNDropField";
import { TextField } from "@consta/uikit/TextField";
import { Attachment } from "@consta/uikit/Attachment";
import { IconTrash } from "@consta/uikit/IconTrash";

import { FieldTitle } from "./FieldTitle";

import { CustomEditor } from "shared/components/CustomEditor";
import { formatType } from "shared/constants/formatTypes.const";

import { FileDto } from "store/types/Knowledge.model";

import { MaterialFieldProps } from "./model/MaterialField.model";

import "./MaterialField.scss";

export const MaterialField: FC<MaterialFieldProps> = ({control, type, file}) => {
    const [files, setFiles] = useState<File[] | FileDto[]>([]);
    const [videos, setVideos] = useState<File[] | FileDto[]>([]);
    const [articleText, setArticleText] = useState<string>('');

    const {setValue, resetField, getValues, trigger} = useFormContext();

    const materialType = useWatch({
        control,
        name: "material_type",
    });

    const handleDragAndDrop = useCallback(
            (value: File[], setState: (arg0: File[]) => void) => {
                setValue("material_file", {value: value[0]});
                setState(value);
                trigger("material_file");
            },
            [setValue, trigger]
    );

    useEffect(() => {
        const file = getValues("material_file")?.value;
        const fileType = file?.path.split(".").pop();
        if (file) {
            fileType === "mp4" ? setVideos([file]) : setFiles([file]);
        }
    }, [getValues, setValue]);

    useEffect(() => {
        const text = getValues("material_article")?.value;
        setArticleText(text);

    }, [getValues, setValue]);

    useEffect(() => {
        if (file) {
            file.type === "mp4" ? setVideos([file]) : setFiles([file]);
        }
    }, [file]);
    // console.log(type, file, materialType?.value?.id );
    const materialFieldContent = useMemo(() => {
        switch (materialType?.value?.id || type) {
            case formatType.FILE:
                return (
                        <>
                            {!files.length && (
                                    <>
                                        <FieldTitle text="Добавить вложение"/>
                                        <Controller
                                                name="material_file"
                                                control={control}
                                                rules={{
                                                    required: true,
                                                    validate: (value) => value?.value !== null,
                                                }}
                                                defaultValue={{value: files[0] || null}}
                                                render={({field}) => {
                                                    return (
                                                            <DragNDropField
                                                                    accept={[
                                                                        ".xls",
                                                                        ".doc",
                                                                        ".pdf",
                                                                        ".docx",
                                                                        ".xlsx",
                                                                        ".zip",
                                                                        ".rar",
                                                                    ]}
                                                                    onDropFiles={(value) =>
                                                                            handleDragAndDrop(value, setFiles)
                                                                    }
                                                                    {...field}
                                                            >
                                                                {({openFileDialog}) => (
                                                                        <>
                                                                            <div className="material-field__title">
                                                                                Перетащите видео или{" "}
                                                                                <span
                                                                                        className="material-field__title_link"
                                                                                        onClick={openFileDialog}
                                                                                >
                                                                загрузите
                                                            </span>
                                                                            </div>
                                                                            <div className="material-field__text">
                                                                                Поддерживаемые форматы: XLS,
                                                                                DOC, PDF, XLSX, ZIP, RAR
                                                                            </div>
                                                                        </>
                                                                )}
                                                            </DragNDropField>
                                                    );
                                                }}
                                        />
                                    </>
                            )}
                            {files.map((file) => (
                                    <Attachment
                                            key={file.name}
                                            fileName={file.name}
                                            fileExtension={file.name.match(/\.(?!.*\.)(\w*)/)?.[1]}
                                            fileDescription={file.type}
                                            buttonIcon={IconTrash}
                                            onButtonClick={() => {
                                                resetField("material_file");
                                                setFiles([]);
                                            }}
                                    />
                            ))}
                        </>
                );
            case formatType.INTERNAL:
                return (
                        <>
                            <FieldTitle text="Текст статьи"/>
                            <Controller
                                    name="material_article"
                                    control={control}
                                    rules={{
                                        required: true,
                                        validate: (value) => value?.value !== null,
                                    }}
                                    defaultValue={{value: articleText}}
                                    render={({field}) => {
                                        return (
                                                <CustomEditor
                                                        onChange={field.onChange}
                                                        value={field.value}
                                                />
                                        );
                                    }}
                            />
                        </>
                );
            case formatType.LINK:
                return (
                        <Controller
                                name="material_link"
                                control={control}
                                rules={{
                                    required: true,
                                    validate: (value) => value?.value !== null,
                                }}
                                defaultValue={{value: null}}
                                render={({field}) => {
                                    return (
                                            <TextField
                                                    placeholder="Вставьте ссылку"
                                                    label="Ссылка на материал"
                                                    width="full"
                                                    required
                                                    {...field}
                                                    value={field.value?.value}
                                            />
                                    );
                                }}
                        />
                );
            case formatType.VIDEO:
            default:
                return (
                        <>
                            {!videos.length && (
                                    <>
                                        <FieldTitle text="Загрузить видео"/>
                                        <Controller
                                                name="material_file"
                                                control={control}
                                                rules={{
                                                    required: true,
                                                    validate: (value) => value?.value !== null,
                                                }}
                                                defaultValue={{value: videos[0] || null}}
                                                render={({field}) => {
                                                    return (
                                                            <DragNDropField
                                                                    accept={[".mp4"]}
                                                                    onDropFiles={(value) =>
                                                                            handleDragAndDrop(value, setVideos)
                                                                    }
                                                                    {...field}
                                                            >
                                                                {({openFileDialog}) => (
                                                                        <>
                                                                            <div className="material-field__title">
                                                                                Перетащите файл или{" "}
                                                                                <span
                                                                                        className="material-field__title_link"
                                                                                        onClick={openFileDialog}
                                                                                >
                                                                загрузите
                                                            </span>
                                                                            </div>
                                                                            <div className="material-field__text">
                                                                                Поддерживаемые форматы: mp4
                                                                            </div>
                                                                        </>
                                                                )}
                                                            </DragNDropField>
                                                    );
                                                }}
                                        />
                                    </>
                            )}
                            {videos.map((file) => (
                                    <Attachment
                                            key={file.name}
                                            fileName={file.name}
                                            fileExtension={file.name.match(/\.(?!.*\.)(\w*)/)?.[1]}
                                            fileDescription={file.type}
                                            buttonIcon={IconTrash}
                                            onButtonClick={() => {
                                                resetField("material_file");
                                                setVideos([]);
                                            }}
                                    />
                            ))}
                        </>
                );
        }
    }, [
        control,
        files,
        handleDragAndDrop,
        materialType?.value?.id,
        resetField,
        type,
        videos,
        articleText
    ]);
    //
    // if (type) {
    //     if (type === formatType.LINK) {
    //         return (
    //             <Controller
    //                 name="material_link"
    //                 control={control}
    //                 rules={{
    //                     required: true,
    //                     validate: (value) => value?.value !== null,
    //                 }}
    //                 defaultValue={{ value: null }}
    //                 render={({ field }) => {
    //                     return (
    //                         <TextField
    //                             className="material-field__link"
    //                             placeholder="Вставьте ссылку"
    //                             label="Ссылка на материал"
    //                             width="full"
    //                             required
    //                             {...field}
    //                             value={field.value?.value}
    //                         />
    //                     );
    //                 }}
    //             />
    //         );
    //     } else {
    //         return null;
    //     }
    // }
    return <div className="material-field">{materialFieldContent}</div>;
};
