import { FC, useState, KeyboardEvent, RefObject, useRef } from "react";

import { Controller } from "react-hook-form";

import { UserSelect } from "@consta/uikit/UserSelect";

import { User } from "@consta/uikit/User";

import { useLazyGetExternalUsersQuery } from "store/labApi";

import { getExternalEmployees, getMimeType } from "shared/utils/users.utils";

import { UserSelectType } from "shared/types/UserSelectType";

import { DevelopersWidgetProps } from "./model/DevelopersWidget.model";

import useDebouncedFunction from "../../../../../../shared/utils/debounce.ustils";

import "./DevelopersWidget.scss";
import { imgConfig } from "../../../../../../configs/imgConfig";

export const DevelopersWidget: FC<DevelopersWidgetProps> = ({ control, user, id }) => {
    const [formUsers, setFormUsers] = useState<UserSelectType[] | undefined>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [getUsers] = useLazyGetExternalUsersQuery();
    const abortRef = useRef<any | undefined>();

    const sendRequest = useDebouncedFunction(
        async (input) => {
            try {
                const request = getUsers(input.value);
                abortRef.current = request;
                const { data } = await request.unwrap();
                setFormUsers(getExternalEmployees(data?.models));
                setIsLoading(false);
            } catch (error) {
                // if ((error as Error)?.name !== "AbortError") throw error;
            } finally {
                abortRef.current = undefined;
            }
        },
        500,
        true
    );

    const handleSearchEmployee = async (event: KeyboardEvent<HTMLDivElement>) => {
        const input = event.target as HTMLInputElement;
        if (input.value.length < 2) {
            setFormUsers([]);
            setIsLoading(false);
        } else {
            abortRef.current?.abort();
            setIsLoading(true);
            sendRequest(input);
        }
    };

    return (
        <div className="developers-widget">
            <Controller
                name="developers"
                control={control}
                defaultValue={{
                    value: id
                        ? {
                              label:
                                  user?.last_name +
                                  " " +
                                  user?.first_name +
                                  " " +
                                  user?.middle_name,
                              subLabel: user?.email,
                              id: user?.sso_id,
                              avatarUrl: user?.image
                                  ? `data:${getMimeType(
                                        user.image
                                    )};base64,${user.image.replace(/\s+/g, "")}`
                                  : `${process.env.REACT_APP_API_URL}/images/${imgConfig.defaultAvatarUuid}`,
                          }
                        : null,
                }}
                rules={{
                    required: true,
                    validate: (value) => value?.value !== null,
                }}
                render={({ field: { ref, ...field }, fieldState }) => {
                    const refObject = ref as unknown; // возможно потребуется дополнительные проверки типизации
                    return (
                        <UserSelect
                            required
                            placeholder="Выберите сотрудника"
                            label="Автор кейса"
                            items={formUsers || []}
                            {...field}
                            inputRef={refObject as RefObject<HTMLInputElement>}
                            searchFunction={() => true}
                            value={field.value?.value}
                            isLoading={isLoading}
                            onKeyUp={(e) => handleSearchEmployee(e)}
                            className={fieldState.error ? "form-error" : ""}
                            status={fieldState.error ? "alert" : undefined}
                            renderValue={({ item }) => {
                                return (
                                    <User
                                        view="ghost"
                                        name={item.label}
                                        avatarUrl={item.avatarUrl}
                                        info={item.subLabel}
                                    />
                                );
                            }}
                        />
                    );
                }}
            />
        </div>
    );
};
