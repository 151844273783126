import { FC } from "react";

import { useFormContext } from "react-hook-form";
import {
    DragDropContext,
    Draggable,
    DraggableLocation,
    Droppable,
} from "react-beautiful-dnd";

import { ReviewItem } from "./components/ReviewItem";

import { ReviewType } from "../../model/ReviewsWidget.model";
import { ReviewsPlacementOrderProps } from "./model/ReviewsPlacementOrder.model";

import "./ReviewsPlacementOrder.scss";

export const ReviewsPlacementOrder: FC<ReviewsPlacementOrderProps> = ({ reviews }) => {
    const { setValue } = useFormContext();

    if (!reviews?.length) {
        return null;
    }

    const onDragEnd = ({
        destination,
        source,
    }: {
        destination?: DraggableLocation | null;
        source: DraggableLocation;
    }) => {
        if (!destination) return;
        const newItems = Array.from(reviews);
        const [reorderedItem] = newItems.splice(source.index, 1);
        newItems.splice(destination.index, 0, reorderedItem);
        setValue("reviews", newItems);
    };

    return (
        <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="reviews">
                {(provided) => (
                    <div
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                        className="reviews-placement-order"
                    >
                        {reviews?.map((review: ReviewType, index: number) => (
                            <Draggable
                                key={index}
                                draggableId={index.toString()}
                                index={index}
                            >
                                {(provided) => (
                                    <div
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                        ref={provided.innerRef}
                                    >
                                        <ReviewItem
                                            key={index}
                                            review={review}
                                            currentIndex={index + 1}
                                        />
                                    </div>
                                )}
                            </Draggable>
                        ))}
                        {provided.placeholder}
                    </div>
                )}
            </Droppable>
        </DragDropContext>
    );
};
