import { FC } from "react";

import { FieldTitleProps } from "./FieldTitle.model";

import "./FieldTitle.scss";

export const FieldTitle: FC<FieldTitleProps> = ({ text }) => (
    <div className="Text Text_lineHeight_m Text_size_m Text_view_secondary FieldLabel TextField-Label TextField-Label_labelPosition_top field-title">
        {text}
        <span className="FieldLabel-Star">*</span>
    </div>
);
