import { FC, useState } from "react";

import { Button } from "@consta/uikit/Button";
import { IconAdd } from "@consta/uikit/IconAdd";
import { IconEdit } from "@consta/uikit/IconEdit";

import { CreateContactModal } from "./components/CreateContactModal";
import { UserCard } from "./components/UserCard";
import { EditHelpdesksModal } from "./components/EditHelpdesksModal";

import { useGetCurrentUserRightsQuery } from "store/labApi";

import { checkPermission } from "shared/utils/rights.utils";

import {
    editBlpsHelpdesksRights,
    editBlpsTeamRights,
    editBrdHelpdesksRights,
    editBrdTeamRights,
    editCfHelpdesksRights,
    editCfTeamRights,
} from "./TeamBlock.const";

import { TeamBlockProps } from "./model/TeamBlockProps.model";

import "./TeamBlock.scss";

export const TeamBlock: FC<TeamBlockProps> = ({
    title,
    items,
    emails,
    resources,
    contactShortName,
    blockId,
    refetch,
}) => {
    const [isOpenCreateContactModal, setIsOpenCreateContactModal] = useState(false);
    const [isOpenEditEmailsModal, setIsOpenEditEmailsModal] = useState(false);
    const [isOpenEditLinksModal, setIsOpenEditLinksModal] = useState(false);
    const [collapsed, setCollapse] = useState(false);

    const { data: userData } = useGetCurrentUserRightsQuery();
    const rightsData = userData?.roles ?? undefined;

    const isAdmin = localStorage.getItem("USER_ROLE") === "ADMIN";

    let canEditUsers = false;

    switch (contactShortName) {
        case "БРД":
            canEditUsers = isAdmin && checkPermission(editBrdTeamRights, rightsData);
            break;
        case "БЛПС":
            canEditUsers = isAdmin && checkPermission(editBlpsTeamRights, rightsData);
            break;
        case "КФ":
            canEditUsers = isAdmin && checkPermission(editCfTeamRights, rightsData);
            break;
    }

    let canEditHelpdesks = false;

    switch (contactShortName) {
        case "БРД":
            canEditHelpdesks =
                isAdmin && checkPermission(editBrdHelpdesksRights, rightsData);
            break;
        case "БЛПС":
            canEditHelpdesks =
                isAdmin && checkPermission(editBlpsHelpdesksRights, rightsData);
            break;
        case "КФ":
            canEditHelpdesks =
                isAdmin && checkPermission(editCfHelpdesksRights, rightsData);
            break;
    }

    return (
        <>
            <div className="team-block">
                <div className="info">
                    <div className="team-title">{title + ` ${contactShortName}`}</div>
                    {canEditUsers && (
                        <Button
                            className="team-block__title-button"
                            label="Добавить контакт"
                            view="secondary"
                            size="xs"
                            iconSize="xs"
                            iconLeft={IconAdd}
                            onClick={() => setIsOpenCreateContactModal(true)}
                        />
                    )}
                    <div className="team-block__contacts">
                        <div className="team-block__contacts-text">
                            {/*<div>По вопросам пишите нам на почту</div>*/}
                            {canEditHelpdesks && (
                                <Button
                                    iconRight={IconEdit}
                                    onlyIcon
                                    iconSize="s"
                                    size="xs"
                                    view="clear"
                                    onClick={() => setIsOpenEditEmailsModal(true)}
                                />
                            )}
                        </div>
                        {!!emails?.length && (
                            <span>
                                <div className="team-block__contacts-emails">
                                    {emails.map((email) => (
                                        <a
                                            href={`mailto:${email.link}`}
                                            className="team-block__contacts-link"
                                            key={email.guid}
                                            rel="noreferrer"
                                            target="_blank"
                                        >
                                            {email.name}
                                        </a>
                                    ))}
                                </div>
                            </span>
                        )}
                        <span className="team-block__email-block">
                            {canEditHelpdesks && (
                                <div className="team-block__contacts-text resource">
                                    <Button
                                        iconRight={IconEdit}
                                        onlyIcon
                                        iconSize="s"
                                        size="xs"
                                        view="clear"
                                        onClick={() => setIsOpenEditLinksModal(true)}
                                    />
                                </div>
                            )}
                            <div className="team-block__contacts-resources">
                                {resources?.map((resource) => (
                                    <a
                                        href={resource.link}
                                        className="team-block__contacts-link"
                                        key={resource.guid}
                                        rel="noreferrer"
                                        target="_blank"
                                    >
                                        {resource.name}
                                    </a>
                                ))}
                            </div>
                        </span>
                    </div>
                </div>
                <div className="team-block__users">
                    {items?.map((item, index) => (
                        <UserCard
                            key={item.guid}
                            name={item.name}
                            position={item.position}
                            collapsed={
                                (!collapsed && index < 4) ||
                                collapsed ||
                                items?.length < 5
                            }
                            email={item.user?.email}
                            image={item.user?.guid_avatar}
                            userId={item.user?._guid}
                            hasRight={canEditUsers}
                            refetch={refetch}
                        />
                    ))}
                    {items?.length && items?.length > 4 && (
                        <Button
                            size="m"
                            view="ghost"
                            label={collapsed ? "Скрыть" : "Показать еще"}
                            form="default"
                            onClick={() => setCollapse(!collapsed)}
                        />
                    )}
                </div>
            </div>
            <CreateContactModal
                title={`Добавить контакт ${contactShortName}`}
                isOpen={isOpenCreateContactModal}
                handleClose={() => setIsOpenCreateContactModal(false)}
                blockId={blockId}
                refetch={refetch}
            />
            {emails && (
                <EditHelpdesksModal
                    type="EMAIL"
                    isOpen={isOpenEditEmailsModal}
                    handleClose={() => setIsOpenEditEmailsModal(false)}
                    helpdesks={emails}
                    blockId={blockId}
                    refetch={refetch}
                />
            )}
            {resources && (
                <EditHelpdesksModal
                    type="RESOURCE"
                    isOpen={isOpenEditLinksModal}
                    handleClose={() => setIsOpenEditLinksModal(false)}
                    helpdesks={resources}
                    blockId={blockId}
                    refetch={refetch}
                />
            )}
        </>
    );
};
