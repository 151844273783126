import { FormMutateKnowledgeSubmitType } from "shared/types/mutateKnowledgeType";
import { FilterDto } from "store/types/Knowledge.model";
import { FilterToolDto } from "store/types/KnowledgeFilters.model";

type DataItem = {
    _uuid: string;
    name: string;
    short_name?: string;
};

export const enumerate = (count: string, words: string[] | undefined) => {
    if (!words) {
        return "";
    }
    let countAsNumber = Number(count);
    if (countAsNumber > 100) countAsNumber = countAsNumber % 100;
    if (countAsNumber <= 20 && countAsNumber >= 10) return words[2];
    if (countAsNumber > 20) countAsNumber = countAsNumber % 10;
    return countAsNumber === 1
        ? words[0]
        : countAsNumber > 1 && countAsNumber < 5
        ? words[1]
        : words[2];
};

export const getComboboxValideValuesShared = <T extends DataItem>(
    data?: T[],
    param?: string
) => {
    return data?.map((item) => {
        return {
            id: item._uuid,
            label: item.short_name || item.name,
            param,
        };
    });
};

export const getComboboxValideToolsValues = (
    param: string,
    data?: FilterDto[] | FilterToolDto[]
) => {
    return data?.map((item) => {
        if ("_uuid" in item) {
            return {
                id: item._uuid,
                label: item.name,
                imageIconId: item?.uuid_image_icon,
                imageId: item.uuid_image,
                param,
            };
        } else {
            return {
                id: item.guid,
                label: item.name,
                param,
            };
        }
    });
};

export const createParams = (data: FormMutateKnowledgeSubmitType) => {
    const type = data.material_type?.value.id;
    const formData = new FormData();

    const blocks = data.belonging?.value.map((item) => item.id);
    const tags = data.tags?.value?.map((item: any) => item.id);

    formData.append("type", data.material_type?.value.id || "");
    formData.append("subject", data.title?.value ? data.title?.value : "");
    formData.append(
        "description",
        data.description?.value ? data.description?.value : ""
    );
    formData.append(
        "uuid_complexity",
        data.complexity?.value.id ? data.complexity?.value.id : ""
    );
    formData.append("uuid_tool", data.tool?.value.id ? data.tool?.value.id : "");
    blocks &&
        blocks.forEach((block: string) => {
            formData.append("blocks[]", block);
        });
    tags
        ? tags.forEach((tag: string) => {
              formData.append("tags[]", tag);
          })
        : formData.append("tags[]", "");

    if ((type === "FILE" || type === "VIDEO") && data.material_file?.value) {
        formData.append("file", data.material_file.value);
    } else if (type === "LINK") {
        formData.append(
            "link",
            data.material_link?.value ? data.material_link.value : ""
        );
    } else if (type === "INTERNAL" && data.material_article) {
        formData.append(
            "body",
            typeof data.material_article === "object"
                ? data.material_article?.value
                : data.material_article
        );
    }

    return formData;
};

export const divideNumIntoCategories = (num: number) => {
    const options = {
        useGrouping: true,
        minimumFractionDigits: 0,
    };
    return num.toLocaleString("ru-RU", options);
};
