import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { GetConfig } from "./components/GetConfig/GetConfig";

import App from "components/App/App";

import store from "store";

import { ScrollProvider } from "pages/MainPage/utils/ScrollContext/ScrollContext";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import "./index.scss";

const root = createRoot(document.getElementById("root") as HTMLElement);

root.render(
    <GetConfig>
        <Provider store={store}>
            <ScrollProvider>
                <App />
            </ScrollProvider>
        </Provider>
    </GetConfig>
);
