import React, { useRef, useState } from "react";
import { Button } from "@consta/uikit/Button";
import { ContextMenu, ContextMenuItemDefault } from "@consta/uikit/ContextMenu";
import { Switch } from "@consta/uikit/Switch";
import { IconMarket } from "../../assets/icons/tsx/IconMarket";
import classes from "./HeaderExtraActions.module.css";
import { IconSupport } from "../../assets/icons/tsx/IconSupport";
import { IconCustomUser } from "../../assets/icons/tsx/IconCustomUser";
import { SupportModal } from "../SupportModal/SupportModal";
import { linksConfig } from "../../configs/linksConfig";
import { useGetCurrentUserRightsQuery } from "../../store/labApi";
import { checkPermission } from "../../shared/utils/rights.utils";
import { settingViewRights } from "../LabSettings/LabSettings.const";
import { getMimeType } from "../../shared/utils/users.utils";
import { useLocation } from "react-router-dom";
import { PageRoutes } from "../../appMenuAndRouting/appRoutes";
import icnSettingsCustom from "../../assets/icons/icn_settings_custom.svg";
import classNames from "classnames";

export const HeaderExtraActions = () => {
    const location = useLocation();
    const [openSupport, setOpenSupport] = useState<boolean>(false);
    const [isOpenModeSwitcher, setIsOpenModeSwitcher] = useState<boolean>(false);
    const buttonRef = useRef(null);
    const { data: userData } = useGetCurrentUserRightsQuery();
    const rightsData = userData?.roles ?? undefined;
    const isAdminMode = localStorage.getItem("USER_ROLE") === "ADMIN";
    const disableSwitcher = checkPermission(settingViewRights, rightsData)
        ? false
        : !isAdminMode;

    const handlerNavigateToMarket = () => {
        window.open(linksConfig.new_market, "_blank");
    };

    const handlerSupport = () => {
        setOpenSupport(true);
    };

    const contextItems: ContextMenuItemDefault[] = [
        {
            label: "Контент-менеджер",
            disabled: disableSwitcher,
            rightSide: (
                <Switch
                    size="m"
                    checked={isAdminMode}
                    onChange={({ checked }) => {
                        localStorage.setItem("USER_ROLE", checked ? "ADMIN" : "USER");
                        if (
                            location.pathname === PageRoutes.DataLab_MetricsPage &&
                            !checked
                        ) {
                            window.location.replace(PageRoutes.DataLab_MainPage);
                        } else {
                            window.location.reload();
                        }
                    }}
                    key="Switch"
                    disabled={disableSwitcher}
                />
            ),
        },
    ];

    let img: string | undefined = undefined;
    if (userData?.image) {
        const mimeType = getMimeType(userData.image);
        img = userData.image
            ? `data:${mimeType};base64,${userData.image.replace(/\s+/g, "")}`
            : "";
    }

    return (
        <div className={classes.container}>
            <Button
                size="s"
                view={"ghost"}
                iconLeft={IconMarket}
                iconSize="s"
                form="round"
                label={"Магазин данных"}
                onClick={handlerNavigateToMarket}
                className={classes.buttonMarket}
                disabled
            />
            <Button
                size="s"
                view={"ghost"}
                iconLeft={IconSupport}
                iconSize="s"
                onlyIcon
                form="round"
                onClick={handlerSupport}
                className={classes.increaseIcon24}
            />
            <div className={classes.userButtonContainer}>
                <Button
                    size="s"
                    view={"ghost"}
                    iconLeft={
                        img
                            ? () => (
                                  <img
                                      src={img}
                                      alt={""}
                                      width={"100%"}
                                      height={"100%"}
                                  />
                              )
                            : IconCustomUser
                    }
                    iconSize="s"
                    onlyIcon
                    form="round"
                    onClick={() => setIsOpenModeSwitcher(!isOpenModeSwitcher)}
                    ref={buttonRef}
                    className={classNames(
                        classes.increaseIcon32,
                        userData?.image ? classes.userButton : undefined
                    )}
                />
                {isAdminMode && (
                    <div className={classes.userButtonIcon}>
                        <img src={icnSettingsCustom} alt={""} />
                    </div>
                )}
            </div>

            <ContextMenu
                isOpen={isOpenModeSwitcher}
                items={contextItems}
                anchorRef={buttonRef}
                onClickOutside={() => setIsOpenModeSwitcher(false)}
                direction="leftUp"
                style={{ zIndex: 1001 }}
            />
            <SupportModal
                mode={"long"}
                open={openSupport}
                handlerClose={() => setOpenSupport(false)}
            />
        </div>
    );
};
