import { PageContentWrapper } from "components/PageContentWrapper";
import { HeroBlock } from "./components/HeroBlock";
import { KnowledgeBlock } from "./components/KnowledgeBlock";
import { useEffect } from "react";

export const KnowledgePage = () => {
    useEffect(() => {
       document.title = 'База знаний | Лаборатория данных';
    });
    return (
            <>
                <HeroBlock/>
                <PageContentWrapper>
                    <KnowledgeBlock/>
                </PageContentWrapper>
            </>
    );
}
