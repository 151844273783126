import { useGetMetricsQuery } from "store/labApi";

import { Breadcrumbs } from "@consta/uikit/Breadcrumbs";

import { MetricKey } from "shared/constants/metrics.const";
import { enumerate } from "shared/utils";

import heroBlockContent from "templateContent/knowledgePage/heroBlockContent.json";

import "./HeroBlock.scss";

export const HeroBlock = () => {
    const { data } = useGetMetricsQuery(undefined, { refetchOnMountOrArgChange: true });

    const knowledgeCallCountValue = data?.find(
        (metric) => metric.key === MetricKey.KNOWLEDGE_COUNT
    )?.value;

    const basepath =
        process.env.REACT_APP_BASEPATH === "/"
            ? "/"
            : `${process.env.REACT_APP_BASEPATH}/`;

    const items = [
        {
            label: "Лаборатория данных",
            href: `${basepath}`,
        },
        {
            label: "База знаний",
            href: `${basepath}knowledge`,
        },
    ];

    return (
        <div className="hero-block-portfolio">
            <div className="hero-block-portfolio__content knowledge-header">
                <Breadcrumbs items={items} />
                <div className="hero-block-portfolio__title">
                    {heroBlockContent.title}
                </div>
                <div className="hero-block-portfolio__desc">
                    {heroBlockContent.description}
                </div>

                <div className="metrics">
                    {knowledgeCallCountValue && (
                        <div>
                            <div className="number">
                                {knowledgeCallCountValue}{" "}
                                {enumerate(knowledgeCallCountValue, [
                                    "Материал",
                                    "Материала",
                                    "Материалов",
                                ])}
                            </div>
                            <div className="number-desc">готовы для изучения</div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};
