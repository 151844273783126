import { useEffect } from "react";

import { PageRoutes } from "appMenuAndRouting/appRoutes";

import { Breadcrumbs } from "@consta/uikit/Breadcrumbs";

import { PageContentWrapper } from "components/PageContentWrapper";
import { FormCreateKnowledge } from "./components/FormCreateKnowledge";

export const KnowledgeCreatePage = () => {
    const items = [
        {
            label: "Лаборатория данных",
            href: PageRoutes.DataLab_MainPage,
        },
        {
            label: "База знаний",
            href: PageRoutes.DataLab_KnowledgesPage,
        },
        {
            label: "Добавление документа",
            href: PageRoutes.DataLab_KnowledgeCreatePage,
        },
    ];

    useEffect(() => {
        document.title = "База знаний";
    }, []);
    return (
            <PageContentWrapper className="knowledge-mutate-page">
                <Breadcrumbs items={items}/>
                <div className="knowledge-mutate-page__title">Добавление материала</div>
                <FormCreateKnowledge/>
            </PageContentWrapper>
    );
};
