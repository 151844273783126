import { FC } from "react";

import { useFormContext, useWatch } from "react-hook-form";

import moment from "moment";

import { IconDraggable } from "@consta/uikit/IconDraggable";
import { Button } from "@consta/uikit/Button";
import { IconTrash } from "@consta/uikit/IconTrash";

import { ImagesOrderItemProps } from "./model/ImagesOrderItem.model";

import "./ImagesOrderItem.scss";

export const ImagesOrderItem: FC<ImagesOrderItemProps> = ({
    image,
    fieldName,
    currentIndex,
    control,
}) => {
    const { setValue, trigger } = useFormContext();

    const images = useWatch({
        control,
        name: fieldName,
    });

    return (
        <div className="images-order-item">
            {fieldName !== "avatar" && <IconDraggable size="xs" />}
            <img
                src={
                    image instanceof File
                        ? URL.createObjectURL(image)
                        : `${process.env.REACT_APP_API_URL}/images/${image._uuid}`
                }
                alt={""}
                className="images-order-item__image"
            />
            <div className="images-order-item__content">
                <div className="images-order-item__content-title">
                    {image instanceof File ? image.name : image.origin_file_name}
                </div>
                <div className="images-order-item__content-description">
                    {(image instanceof File
                        ? image.size / (1024 * 1024)
                        : image.origin_file_size / (1024 * 1024)
                    ).toFixed(2) + " Мб "}
                    {moment(image instanceof File ? undefined : image._create_ts).format(
                        "DD.MM.YYYY HH.mm"
                    )}
                </div>
            </div>
            <Button
                onlyIcon
                iconRight={IconTrash}
                size="xs"
                view="ghost"
                className="images-order-item__button"
                type="button"
                onClick={() => {
                    setValue(fieldName, {
                        value: images.value.filter(
                            (item: File, index: number) => index !== currentIndex
                        ),
                    });
                    trigger(fieldName);
                }}
            />
        </div>
    );
};
