import React, { FC, useEffect, useMemo, useState } from "react";

import { useNavigate } from "react-router-dom";

import { Controller, FormProvider, useForm } from "react-hook-form";

import { Loader } from "@consta/uikit/Loader";
import { ErrorType } from "shared/types/ErrorType";
import { TextField } from "@consta/uikit/TextField";
import { cnMixSpace } from "@consta/uikit/MixSpace";
import { Combobox } from "@consta/uikit/Combobox";
import { Grid } from "@consta/uikit/Grid";
import { ImagesWidget } from "./components/ImagesWidget";
import { DevelopersWidget } from "./components/DevelopersWidget";
import { ReviewsWidget } from "./components/ReviewsWidget";
// import { SubmitWidget } from "./components/SubmitWidget";
import {
    useCreatePortfolioMutation,
    useEditPortfolioMutation,
    useGetCurrentUserRightsQuery,
    useGetPortfolioFiltersQuery,
    useLazyGetPortfolioItemQuery,
} from "store/labApi";

import { createParams } from "./FormMutatePortfolio.utils";

import { getComboboxValideValues } from "shared/utils/filters.utils";
//import { getExternalEmployees } from "shared/utils/users.utils";
import { getComboboxValideToolsValues } from "shared/utils";
// import { UserSelectType } from "shared/types/UserSelectType";
import {
    FormMutatePortfolioProps,
    FormMutatePortfolioSubmitType,
} from "./model/FormMutatePortfolio.model";

import { PageRoutes } from "appMenuAndRouting/appRoutes";
import { Button } from "@consta/uikit/Button";
import { CustomEditor } from "../../../../shared/components/CustomEditor";
import classNames from "classnames";
import { cnMixFlex } from "@consta/uikit/MixFlex";
import { PortfolioPreviewModal } from "./components/PortfolioPreviewModal/PortfolioPreviewModal";
import { checkPermission } from "../../../../shared/utils/rights.utils";
import {
    blpsToolsRights,
    brdToolsRights,
    cfToolsRights,
} from "../../../MainPage/components/ToolsBlock/components/EditToolsModal/EditToolsModal.const";
import { useAppDispatch } from "../../../../store";
import { addNotification } from "../../../../store/general";
import TextInfo from "./components/TextInfo/TextInfo";

export const FormMutatePortfolio: FC<FormMutatePortfolioProps> = ({ id }) => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const [showPreview, setShowPreview] = useState<boolean>(false);

    const methods = useForm({
        mode: "onChange",
    });
    const control = methods.control;
    const handleSubmit = methods.handleSubmit;

    const [createPortfolio, { error: createError, isLoading: isLoadingCreatePortfolio }] =
        useCreatePortfolioMutation();
    const [editPortfolio, { error: editError, isLoading: isLoadingEditPortfolio }] =
        useEditPortfolioMutation();
    const [getPortfolio, { data: portfolioData, isLoading: isLoadingGetPortfolio }] =
        useLazyGetPortfolioItemQuery();

    const { data: portfolioFilters, isLoading: isLoadingFilters } =
        useGetPortfolioFiltersQuery();

    const { data: userData } = useGetCurrentUserRightsQuery();
    const rightsData = userData?.roles ?? undefined;

    const blockName = methods.watch("block")?.value?.label;

    const hasBlockRight = useMemo<boolean>(() => {
        switch (blockName) {
            case "БРД":
                return checkPermission(brdToolsRights, rightsData);
            case "БЛПС":
                return checkPermission(blpsToolsRights, rightsData);
            case "КФ":
                return checkPermission(cfToolsRights, rightsData);
        }
        return false;
    }, [blockName, rightsData]);

    const submitButtonName = id
        ? "Сохранить"
        : hasBlockRight
        ? "Опубликовать"
        : "Отправить заявку";

    const blockFilters = getComboboxValideValues("blocks", portfolioFilters?.data.blocks);
    // const complexitiesFilters = getComboboxValideValues(
    //     "complexities",
    //     portfolioFilters?.data.complexities
    // );
    const domainsFilters = getComboboxValideValues(
        "domains",
        portfolioFilters?.data.domains
    );
    const toolsFilters = getComboboxValideToolsValues(
        "tools",
        portfolioFilters?.data.tools
    );

    const onSubmit = async (data: FormMutatePortfolioSubmitType) => {
        const params = createParams(data);
        const message = id
            ? "Изменения успешно сохранены"
            : hasBlockRight
            ? "Кейс успешно опубликован в Портфолио"
            : "Ваша заявка на публикацию успешно отправлена";
        try {
            id
                ? await editPortfolio({ id: id, body: params }).unwrap()
                : await createPortfolio(params).unwrap();
            dispatch(addNotification({ message, status: "success" }));
            !createError && !editError && navigate(PageRoutes.DataLab_PortfolioPage);
        } catch (error) {
            console.log(error);
        }
    };

    const error = createError || editError;

    useEffect(() => {
        if (error) {
            dispatch(
                addNotification({
                    status: "alert",
                    message: `Ошибка в ${id ? "редактировании" : "создании"} портфолио ${
                        "data" in error ? (error as ErrorType).data.error : ""
                    }`,
                })
            );
        }
    }, [error, dispatch, id]);

    useEffect(() => {
        id && getPortfolio(id);
    }, [getPortfolio, id]);

    if (isLoadingFilters || isLoadingGetPortfolio) {
        return <Loader />;
    }

    const pattern = /(https:\/\/)([а-яА-Яa-zA-Z0-9_-])/;
    return (
        <FormProvider {...methods}>
            <form onSubmit={handleSubmit(onSubmit)} className="form-mutate-portfolio">
                <Grid cols="12" gap="m">
                    <div className="form-mutate-portfolio__body">
                        <div className="portfiolio-h2">О кейсе</div>
                        <div
                            className={cnMixFlex({
                                gap: "l",
                                direction: "column",
                                align: "stretch",
                            })}
                        >
                            <Controller
                                name="name"
                                control={control}
                                rules={{
                                    required: true,
                                    validate: (value) => value?.value !== null,
                                }}
                                defaultValue={{
                                    value: id ? portfolioData?.data.name : null,
                                }}
                                render={({ field: { ref, ...field }, fieldState }) => {
                                    return (
                                        <TextField
                                            required
                                            placeholder="Введите название"
                                            label="Название"
                                            type="textarea"
                                            rows={5}
                                            width="full"
                                            className={
                                                fieldState.error ? "form-error" : ""
                                            }
                                            status={
                                                fieldState.error ? "alert" : undefined
                                            }
                                            {...field}
                                            inputRef={ref}
                                            value={field.value?.value}
                                        />
                                    );
                                }}
                            />

                            {blockFilters?.length && (
                                <Controller
                                    name="block"
                                    control={control}
                                    rules={{
                                        required: true,
                                        validate: (value) => value?.value !== null,
                                    }}
                                    defaultValue={{
                                        value: id
                                            ? {
                                                  id: portfolioData?.data.block._uuid,
                                                  label: portfolioData?.data.block
                                                      .short_name,
                                                  param: "blocks",
                                              }
                                            : null,
                                    }}
                                    render={({
                                        field: { ref, ...field },
                                        fieldState,
                                    }) => {
                                        return (
                                            <Combobox
                                                required
                                                placeholder="Выберите блок"
                                                label="Блок"
                                                items={blockFilters}
                                                className={
                                                    fieldState.error ? "form-error" : ""
                                                }
                                                status={
                                                    fieldState.error ? "alert" : undefined
                                                }
                                                {...field}
                                                inputRef={ref}
                                                value={field.value?.value}
                                            />
                                        );
                                    }}
                                />
                            )}

                            {domainsFilters?.length && (
                                <Controller
                                    name="domains"
                                    control={control}
                                    rules={{
                                        required: true,
                                        validate: (value) => value?.value !== null,
                                    }}
                                    defaultValue={{
                                        value: id
                                            ? getComboboxValideValues(
                                                  "domains",
                                                  portfolioData?.data.domains
                                              )
                                            : null,
                                    }}
                                    render={({
                                        field: { ref, ...field },
                                        fieldState,
                                    }) => {
                                        return (
                                            <Combobox
                                                required
                                                placeholder="Выберите домены"
                                                label="Домен"
                                                multiple
                                                items={domainsFilters}
                                                className={
                                                    fieldState.error ? "form-error" : ""
                                                }
                                                status={
                                                    fieldState.error ? "alert" : undefined
                                                }
                                                {...field}
                                                inputRef={ref}
                                                value={field.value?.value}
                                            />
                                        );
                                    }}
                                />
                            )}

                            {toolsFilters?.length && (
                                <Controller
                                    name="tool"
                                    control={control}
                                    rules={{
                                        required: true,
                                        validate: (value) => value?.value !== null,
                                    }}
                                    defaultValue={{
                                        value: id
                                            ? {
                                                  id: portfolioData?.data.tool._uuid,
                                                  label: portfolioData?.data.tool.name,
                                                  param: "tools",
                                                  imageIconId:
                                                      portfolioData?.data.tool
                                                          .uuid_image_icon,
                                                  imageId:
                                                      portfolioData?.data.tool.uuid_image,
                                              }
                                            : null,
                                    }}
                                    render={({
                                        field: { ref, ...field },
                                        fieldState,
                                    }) => {
                                        return (
                                            <Combobox
                                                required
                                                placeholder="Выберите инструмент"
                                                label="Используемый инструмент"
                                                items={toolsFilters}
                                                className={
                                                    fieldState.error ? "form-error" : ""
                                                }
                                                status={
                                                    fieldState.error ? "alert" : undefined
                                                }
                                                {...field}
                                                inputRef={ref}
                                                value={field.value?.value}
                                            />
                                        );
                                    }}
                                />
                            )}

                            <Controller
                                name="link"
                                control={control}
                                defaultValue={{
                                    value: id
                                        ? portfolioData?.data.application_link
                                        : null,
                                }}
                                rules={{
                                    validate: ({ value }) => {
                                        if (!value) return true;
                                        if (!value.match(pattern)) return "not link";
                                    },
                                }}
                                render={({ field: { ref, ...field }, fieldState }) => (
                                    <TextField
                                        placeholder="Введите ссылку на приложение"
                                        label="Ссылка на приложение"
                                        width="full"
                                        className={fieldState.error ? "form-error" : ""}
                                        {...field}
                                        inputRef={ref}
                                        value={field.value?.value}
                                    />
                                )}
                            />

                            <DevelopersWidget
                                control={control}
                                user={portfolioData?.data.user}
                                id={id}
                            />
                        </div>
                        <div className="portfiolio-h2">Контент кейса</div>
                        <Controller
                            name="description"
                            control={control}
                            rules={{
                                required: true,
                                validate: (value) => value?.value !== null,
                            }}
                            defaultValue={{
                                value: id ? portfolioData?.data.description : null,
                            }}
                            render={({ field: { ref, ...field }, fieldState }) => {
                                return (
                                    <>
                                        <div
                                            className={`Select Select_labelPosition_top Select_size_m Select_view_default Select_type_combobox MixSpace MixSpace_mB_xs ${
                                                fieldState.error ? "form-error" : ""
                                            }`}
                                        >
                                            <label className="Text Text_lineHeight_m Text_size_m Text_view_secondary FieldLabel Select-Label Select-Label_labelPosition_top">
                                                Описание
                                                <span className="FieldLabel-Star">*</span>
                                            </label>
                                        </div>
                                        <CustomEditor
                                            onChange={field.onChange}
                                            value={field.value}
                                            refC={ref}
                                            fieldState={fieldState}
                                            placeholder={"Добавьте описание"}
                                        />
                                    </>
                                );
                            }}
                        />
                        <Controller
                            name="goals"
                            control={control}
                            rules={{
                                required: true,
                                validate: (value) => value?.value !== null,
                            }}
                            defaultValue={{
                                value: id ? portfolioData?.data.goals : null,
                            }}
                            render={({ field, fieldState }) => (
                                // <TextField
                                //     required
                                //     placeholder="Плейсхолдер"
                                //     label="Цели и задачи"
                                //     type="textarea"
                                //     rows={5}
                                //     width="full"
                                //     className={cnMixSpace({
                                //         mB: "xl",
                                //     })}
                                //     {...field}
                                //     value={field.value?.value}
                                // />
                                <>
                                    <div
                                        className={`Select Select_labelPosition_top Select_size_m Select_view_default Select_type_combobox MixSpace MixSpace_mB_xs ${
                                            fieldState.error ? "form-error" : ""
                                        }`}
                                    >
                                        <label className="Text Text_lineHeight_m Text_size_m Text_view_secondary FieldLabel Select-Label Select-Label_labelPosition_top">
                                            Цели и задачи
                                            <span className="FieldLabel-Star">*</span>
                                        </label>
                                    </div>
                                    <CustomEditor
                                        onChange={field.onChange}
                                        value={field.value}
                                        refC={field.ref}
                                        fieldState={fieldState}
                                        placeholder={"Укажите цели и задачи"}
                                    />
                                </>
                            )}
                        />
                        <Controller
                            name="results"
                            control={control}
                            rules={{
                                required: true,
                                validate: (value) => value?.value !== null,
                            }}
                            defaultValue={{
                                value: id ? portfolioData?.data.results : null,
                            }}
                            render={({ field, fieldState }) => (
                                // <TextField
                                //     required
                                //     placeholder="Плейсхолдер"
                                //     label="Результаты"
                                //     type="textarea"
                                //     rows={5}
                                //     width="full"
                                //     className={cnMixSpace({
                                //         mB: "xl",
                                //     })}
                                //     {...field}
                                //     value={field.value?.value}
                                // />
                                <>
                                    <div
                                        className={`Select Select_labelPosition_top Select_size_m Select_view_default Select_type_combobox MixSpace MixSpace_mB_xs ${
                                            fieldState.error ? "form-error" : ""
                                        }`}
                                    >
                                        <label className="Text Text_lineHeight_m Text_size_m Text_view_secondary FieldLabel Select-Label Select-Label_labelPosition_top">
                                            Результаты
                                            <span className="FieldLabel-Star">*</span>
                                        </label>
                                    </div>
                                    <CustomEditor
                                        onChange={field.onChange}
                                        value={field.value}
                                        refC={field.ref}
                                        fieldState={fieldState}
                                        placeholder={"Расскажите о результатах"}
                                    />
                                </>
                            )}
                        />

                        <Controller
                            name="users"
                            control={control}
                            rules={{
                                required: true,
                                validate: (value) => value?.value !== null,
                            }}
                            defaultValue={{
                                value: id ? portfolioData?.data.users : null,
                            }}
                            render={({ field: { ref, ...field }, fieldState }) => (
                                <TextField
                                    required
                                    placeholder={`Укажите пользователей приложения, например:\nСпециалисты, Руководство, Высшее руководство  `}
                                    label="Пользователи"
                                    type="textarea"
                                    rows={5}
                                    width="full"
                                    className={classNames(
                                        cnMixSpace({
                                            mB: "xl",
                                        }),
                                        fieldState.error ? "form-error" : ""
                                    )}
                                    status={fieldState.error ? "alert" : undefined}
                                    {...field}
                                    inputRef={ref}
                                    value={field.value?.value}
                                />
                            )}
                        />
                        {/*{complexitiesFilters?.length && (*/}
                        {/*    <Controller*/}
                        {/*        name="complexity"*/}
                        {/*        control={control}*/}
                        {/*        defaultValue={{*/}
                        {/*            value: id*/}
                        {/*                ? {*/}
                        {/*                      id: portfolioData?.data.complexity*/}
                        {/*                          ._uuid,*/}
                        {/*                      label: portfolioData?.data.complexity*/}
                        {/*                          .name,*/}
                        {/*                      param: "complexities",*/}
                        {/*                  }*/}
                        {/*                : null,*/}
                        {/*        }}*/}
                        {/*        render={({ field }) => {*/}
                        {/*            return (*/}
                        {/*                <Combobox*/}
                        {/*                    placeholder="Выберите сложность"*/}
                        {/*                    label="Сложность"*/}
                        {/*                    items={complexitiesFilters}*/}
                        {/*                    className={cnMixSpace({*/}
                        {/*                        mB: "xl",*/}
                        {/*                    })}*/}
                        {/*                    {...field}*/}
                        {/*                    value={field.value?.value}*/}
                        {/*                />*/}
                        {/*            );*/}
                        {/*        }}*/}
                        {/*    />*/}
                        {/*)}*/}
                        <div className="Select Select_labelPosition_top Select_size_m Select_view_default Select_type_combobox MixSpace MixSpace_mB_xs">
                            <label className="Text Text_lineHeight_m Text_size_m Text_view_secondary FieldLabel Select-Label Select-Label_labelPosition_top">
                                Планы на развитие
                            </label>
                        </div>
                        <Controller
                            name="plans"
                            control={control}
                            defaultValue={{
                                value: id ? portfolioData?.data.plans : null,
                            }}
                            render={({ field }) => {
                                return (
                                    <CustomEditor
                                        onChange={field.onChange}
                                        value={field.value}
                                        placeholder={"Расскажите о планах на развитие"}
                                    />
                                );
                            }}
                        />

                        <Controller
                            name="screenshots"
                            control={control}
                            defaultValue={{
                                value: id ? portfolioData?.data.images : null,
                            }}
                            rules={{
                                required: true,
                                validate: (value) => {
                                    return (
                                        value?.value !== null &&
                                        value?.value !== undefined &&
                                        value?.value?.length !== 0
                                    );
                                },
                            }}
                            render={({ field, fieldState }) => {
                                return (
                                    <ImagesWidget
                                        control={control}
                                        images={portfolioData?.data.images}
                                        fieldState={fieldState}
                                        refC={field.ref}
                                    />
                                );
                            }}
                        />

                        <ReviewsWidget
                            control={control}
                            reviews={portfolioData?.data.comments}
                        />
                        {!id && !hasBlockRight && <TextInfo />}
                        <div className={cnMixSpace({ mT: "2xl" })}>
                            <Button
                                view="ghost"
                                label="Отмена"
                                onClick={() => navigate(PageRoutes.DataLab_PortfolioPage)}
                                className={cnMixSpace({ mR: "xs" })}
                            />
                            {/*предпросмотр доступен только при создании нового портфолио
                            на развитие: сделать предпросмотр на смешанных данных с бэка и фронта (при редактировании портфолио)
                            */}

                            {!id && (
                                <Button
                                    view="ghost"
                                    label="Предпросмотр"
                                    onClick={() => setShowPreview(true)}
                                    className={cnMixSpace({ mR: "xs" })}
                                />
                            )}

                            <Button
                                label={submitButtonName}
                                type="submit"
                                className="form-create-document__button"
                                loading={
                                    isLoadingCreatePortfolio || isLoadingEditPortfolio
                                }
                            />
                        </div>
                    </div>
                    {/*<SubmitWidget control={control} />*/}
                </Grid>
                {showPreview && (
                    <PortfolioPreviewModal
                        isOpen={showPreview}
                        onClose={() => setShowPreview(false)}
                        getFormData={methods.getValues}
                        formErrors={methods.formState.errors}
                        submit={() => {
                            setShowPreview(false);
                            handleSubmit(onSubmit)();
                        }}
                    />
                )}
            </form>
        </FormProvider>
    );
};
