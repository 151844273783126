import React, { ReactNode, useRef, useState } from "react";
import { ModalTemplate } from "./ModalTemplate/ModalTemplate";
import {
    DeepRequired,
    FieldErrorsImpl,
    FieldValues,
    GlobalError,
    UseFormGetValues,
} from "react-hook-form";
import { Text } from "@consta/uikit/Text";
import { cnMixFlex } from "@consta/uikit/MixFlex";
import icn_TechResponse from "../../../../../../assets/icons/icn_TechResponse.svg";
import icn_quote from "../../../../../../assets/icons/icn_quote.svg";
import moment from "moment/moment";
import { Tag } from "@consta/uikit/Tag";
import Slider from "react-slick";
import { CarouselButtons } from "../../../../../PortfolioItemPage/components/PortfolioSlider/components/CarouselButtons";
import { cnMixSpace } from "@consta/uikit/MixSpace";
import parse from "html-react-parser";
import classes from "./PortfolioPreviewModal.module.css";
import { Avatar } from "@consta/uikit/Avatar";
import { imgConfig } from "../../../../../../configs/imgConfig";

type Props = {
    isOpen: boolean;
    onClose: () => void;
    getFormData: UseFormGetValues<FieldValues>;
    submit: () => void;
    formErrors: Partial<FieldErrorsImpl<DeepRequired<FieldValues>>> & {
        root?: Record<string, GlobalError> & GlobalError;
    };
};

export const PortfolioPreviewModal = (props: Props) => {
    const data = props.getFormData();

    const toolImageId: string = data?.tool?.value?.imageIconId ?? "";
    const toolName: ReactNode = data?.tool?.value?.label ?? (
        <span className={classes.textWithWarning}>Инструмент не выбран</span>
    );
    const domains: ReactNode = data?.domains?.value
        ?.map((domain: { id: string; label: string }) => domain.label)
        .join(", ") ?? <span className={classes.textWithWarning}>Домен не выбран</span>;
    const name: ReactNode = data?.name?.value ?? (
        <span className={classes.textWithWarning}>Название портфолио не заполнено</span>
    );
    const author: ReactNode = data?.developers?.value?.label ?? (
        <span className={classes.textWithWarning}>Автор не выбран</span>
    );
    const blockName: string | undefined = data?.block?.value?.label;
    const images: File[] = (data?.screenshots?.value as File[]) ?? [];
    const description: string | null =
        data?.description?.value === null ? null : data?.description ?? null;
    const goals: string | null = data?.goals?.value === null ? null : data?.goals ?? null;
    const results: string | null =
        data?.results?.value === null ? null : data?.results ?? null;
    const users: ReactNode = data?.users?.value ?? (
        <span
            className={classes.textWithWarning}
        >{`Раздел "Пользователи" не заполнен`}</span>
    );
    const plans: string | null = data?.plans?.value === null ? null : data?.plans ?? null;

    return (
        <ModalTemplate
            title={"Предпросмотр"}
            isOpen={props.isOpen}
            onClose={props.onClose}
            hideFooter
        >
            <div className={classes.container}>
                <ToolAndDomain
                    toolImageId={toolImageId}
                    toolName={toolName}
                    domains={domains}
                />
                <Text size={"xl"} weight={"semibold"}>
                    {name}
                </Text>
                <Text view={"secondary"}>
                    {`${moment().format("LL ")}`}
                    {author}
                </Text>
                <div>
                    <Tag
                        mode={"info"}
                        className={blockName ? undefined : classes.textWithWarning}
                        label={blockName ?? "Блок не выбран"}
                    />
                </div>
                <ImageSlider images={images} />
                <ComplexBlock
                    title={"Описание"}
                    description={description}
                    errorText={'Раздел "Описание" не заполнен'}
                />
                <ComplexBlock
                    title={"Цели и задачи"}
                    description={goals}
                    errorText={'Раздел "Цели и задачи" не заполнен'}
                />
                <ComplexBlock
                    title={"Результаты"}
                    description={results}
                    errorText={'Раздел "Результаты" не заполнен'}
                />
                <SimpleBlock title={"Пользователи"} description={users} />
                {plans && (
                    <ComplexBlock title={"Планы на развитие"} description={plans} />
                )}
                {data?.reviews && (
                    <div className={cnMixSpace({ mT: "5xl" })}>
                        {data?.reviews.map(
                            (
                                review: {
                                    image?: File | null;
                                    user: {
                                        label: string;
                                        position: string | null | undefined;
                                        avatarUrl: string; // base64
                                    };
                                    body: string;
                                },
                                index: number,
                                array: any[]
                            ) => {
                                return (
                                    <div key={index}>
                                        <Reviews
                                            image={review?.user.avatarUrl}
                                            name={review.user.label}
                                            position={review.user.position}
                                            body={review.body}
                                        />
                                        {index !== array.length - 1 && (
                                            <div className={classes.divider}></div>
                                        )}
                                    </div>
                                );
                            }
                        )}
                    </div>
                )}
            </div>
        </ModalTemplate>
    );
};

const ToolAndDomain = ({
    toolImageId,
    toolName,
    domains,
}: {
    toolImageId: string;
    toolName: ReactNode;
    domains: ReactNode;
}) => {
    return (
        <div className={classes.toolAndDomain}>
            <div className={classes.tool}>
                {toolImageId && (
                    <img
                        src={`${process.env.REACT_APP_API_URL}/images/${toolImageId}`}
                        height={"24px"}
                        alt={""}
                    />
                )}
                <Text className={classes.toolText}>{toolName}</Text>
            </div>
            <div className={classes.domain}>
                <img src={icn_TechResponse} alt={""} />
                <Text truncate>{domains}</Text>
            </div>
        </div>
    );
};

const ImageSlider = ({ images }: { images: File[] }) => {
    const sliderRef = useRef<Slider>(null);

    const [currentSlide, setCurrentSlide] = useState(0);

    const portfolioLength = images?.length || 0;

    return !images.length ? (
        <Text className={classes.textWithWarning}>Изображения не добавлены</Text>
    ) : (
        <div className={cnMixSpace({ mT: "xl", mB: "xl" })}>
            <Slider
                slidesToShow={1}
                slidesToScroll={1}
                afterChange={setCurrentSlide}
                ref={sliderRef}
                infinite={false}
            >
                {images?.map((image) => (
                    <div key={image.name}>
                        <img
                            src={URL.createObjectURL(image)}
                            alt={""}
                            height={"380px"}
                            width={"100%"}
                            style={{ objectFit: "contain" }}
                        />
                        {/*скрываем до реализации*/}
                        {/*<Text className={cnMixSpace({ mT: "m" })}>*/}
                        {/*    Описание текущего скриншота (пока не доступно)*/}
                        {/*</Text>*/}
                    </div>
                ))}
            </Slider>
            <CarouselButtons
                handleNextSlide={() => sliderRef.current?.slickNext()}
                handlePrevSlide={() => sliderRef.current?.slickPrev()}
                currentSlide={currentSlide + 1}
                slidesLength={portfolioLength}
            />
        </div>
    );
};

const SimpleBlock = ({
    title,
    description,
}: {
    title: string;
    description: ReactNode;
}) => {
    return (
        <div className={classes.blockContainer}>
            <Text weight={"bold"}>{title}</Text>
            <Text>{description}</Text>
        </div>
    );
};

const ComplexBlock = ({
    title,
    description,
    errorText,
}: {
    title: string;
    description: string | null | undefined;
    errorText?: string;
}) => {
    return (
        <div className={classes.blockContainer}>
            <Text weight={"bold"}>{title}</Text>
            {description ? <div>{parse(description)}</div> : <></>}
            {!description && errorText ? (
                <Text className={classes.textWithWarning}>{errorText}</Text>
            ) : (
                <></>
            )}
        </div>
    );
};

const Reviews = ({
    image,
    name,
    position,
    body,
}: {
    name: string;
    image: string;
    position?: string | null;
    body: string;
}) => {
    return (
        <div className={cnMixFlex({ direction: "row", gap: "m" })}>
            <img src={icn_quote} alt={""} />
            <div className={cnMixFlex({ direction: "column", gap: "l" })}>
                <div className={cnMixFlex({ direction: "row", gap: "xs" })}>
                    <Avatar
                        className={cnMixSpace({ mR: "m" })}
                        size="l"
                        name={"имя"}
                        url={
                            image
                                ? image
                                : `${process.env.REACT_APP_API_URL}/images/${imgConfig.defaultAvatarUuid}`
                        }
                    />
                    <div>
                        <Text>{name}</Text>
                        <Text view={"secondary"}>{position}</Text>
                    </div>
                </div>
                <Text>{body}</Text>
            </div>
        </div>
    );
};
