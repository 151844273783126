import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { SnackBarItemStatus } from "@consta/uikit/SnackBar";
import { ReactNode } from "react";

export type Notification = {
    key: number;
    message: ReactNode;
    status: SnackBarItemStatus;
    responseCode?: number | string;
    responseUrl?: string;
};

export type StateType = {
    notifications: Notification[];
};

const initialState: StateType = {
    notifications: [],
};

export const generalSlice = createSlice({
    name: "general",
    initialState,
    reducers: {
        addNotification: (state, action: PayloadAction<Omit<Notification, "key">>) => {
            const key = state.notifications.length + 1;
            state.notifications.push({
                key,
                message: action.payload.message,
                status: action.payload.status || "alert",
                responseCode: action.payload.responseCode,
                responseUrl: action.payload.responseUrl,
            });
        },
        deleteNotification: (state, action: PayloadAction<number>) => {
            state.notifications = state.notifications.filter(
                (n) => n.key !== action.payload
            );
        },
    },
});

export const { addNotification, deleteNotification } = generalSlice.actions;

export default generalSlice.reducer;
