import { useContext, useEffect, useRef } from "react";

import { useGetToolsQuery } from "store/labApi";

// import { useGetСurrentUserRightsQuery } from "store/labApi";

import { ScrollContext } from "pages/MainPage/utils/ScrollContext";

import { ToolCard } from "./components/ToolCard";
import { EditToolsModal } from "./components/EditToolsModal";

// import { editToolsRights } from "./ToolsBlock.const";
// import { checkPermission } from "shared/utils/rights.utils";
import "./ToolsBlock.scss";
type Props ={
    isOpenEditModal: boolean;
    setIsOpenEditModal: (value: boolean) => void;
}

export const ToolsBlock = ({isOpenEditModal,setIsOpenEditModal}:Props) => {
    const {updateRefTools} = useContext(ScrollContext);
    const refTools = useRef<HTMLDivElement>(null);

    const {data: toolsData, refetch: refetchTools} = useGetToolsQuery(undefined, {
        refetchOnMountOrArgChange: true,
    });

    // const {data} = useGetСurrentUserRightsQuery();
    //
    // const canEditToolBlock = checkPermission(editToolsRights, data);
    // const isAdmin = localStorage.getItem('USER_ROLE') === 'ADMIN';

    useEffect(() => {
        updateRefTools(refTools.current);
    });

    return (
            <>
                <div className='main-tools'>
                    {toolsData?.map((tool, index: number) => (
                            <ToolCard
                                    key={tool?.guid}
                                    id={tool?.guid}
                                    links={tool?.block_links}
                                    description={tool?.description}
                                    name={tool?.name}
                                    icon={tool?.uuid_image_icon}
                                    isSanction={tool?.sanctions}
                                    isSoon={tool?.soon}
                                    isLast={
                                            index === toolsData?.length - 1 &&
                                            toolsData?.length % 2 !== 0
                                    }
                                    link={tool?.link}
                            />
                    ))}
                </div>

                <EditToolsModal
                        isOpen={isOpenEditModal}
                        setIsOpenModal={setIsOpenEditModal}
                        refetchTools={refetchTools}
                />
            </>
    );
};
