import { FC } from "react";
import { KnowledgeItem } from "./KnowledgeItem";

import { KnowledgeItemsProps } from "./model/KnowledgeItems.model";

import "./KnowledgeItems.scss";

export const KnowledgeItems: FC<KnowledgeItemsProps> = ({
    knowledges,
    totalPages,
    currentPage,
    setPage,
}) => {
    const handleChangePage = () => {
        currentPage && setPage(currentPage + 1);
    };

    return (
        <div className="knowledge-items">
            {knowledges?.length ? (
                <>
                    <>
                        {knowledges?.map((item) => (
                            <KnowledgeItem
                                key={item.guid}
                                id={item.guid}
                                likes={item.likes}
                                complexity={item.complexity}
                                description={item.description}
                                title={item.subject}
                                image={item.tool?.uuid_image_icon}
                                toolName={item.tool?.name}
                                blocks={item.blocks}
                                type={item.type}
                                link={item.link}
                                fileId={item.uuid_file}
                                liked={item.liked}
                            />
                        ))}
                    </>
                    {!!totalPages && !!currentPage && totalPages !== currentPage && (
                        <span
                            className="knowledge-items__link"
                            onClick={handleChangePage}
                        >
                            Показать еще
                        </span>
                    )}
                </>
            ) : (
                <div className="knowledge-items__empty-list">Ничего не найдено</div>
            )}
        </div>
    );
};
