import { configureStore } from "@reduxjs/toolkit";
import { labApi } from "./labApi";
import general from "./general";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import errorMiddleware from "./middleware/errorMiddleware";

const store = configureStore({
    reducer: {
        [labApi.reducerPath]: labApi.reducer,
        general,
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware().concat(labApi.middleware, errorMiddleware),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;

export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export default store;
