import { useGetMetricsQuery } from "store/labApi";
// import { useNavigate } from "react-router-dom";

import { Breadcrumbs } from "@consta/uikit/Breadcrumbs";

// import { PageRoutes } from "appMenuAndRouting/appRoutes";

import { MetricKey } from "shared/constants/metrics.const";
import { divideNumIntoCategories, enumerate } from "shared/utils";
// import {divideNumIntoCategories} from  'shared/utils';
import heroBlockContent from "templateContent/portfolioPage/heroBlockContent.json";

import "./HeroBlock.scss";

export const HeroBlock = () => {
    const { data } = useGetMetricsQuery(undefined, { refetchOnMountOrArgChange: true });
    const portfolioCount = data?.find(
        (metric) => metric.key === MetricKey.PORTFOLIO_COUNT
    )?.value;

    const appCount = data?.find((metric) => metric.key === MetricKey.BI_APP_COUNT)?.value;
    // const navigate = useNavigate();

    console.log(appCount);
    const basepath =
        process.env.REACT_APP_BASEPATH === "/"
            ? "/"
            : `${process.env.REACT_APP_BASEPATH}/`;

    const items = [
        {
            label: "Лаборатория данных",
            href: `${basepath}`,
        },
        {
            label: "Портфолио",
            href: `${basepath}portfolio`,
        },
    ];

    return (
        <div className="hero-block-portfolio">
            <div className="hero-block-portfolio__content">
                <Breadcrumbs items={items} />
                <div className="hero-block-portfolio__title">
                    {heroBlockContent.title}
                </div>
                <div className="hero-block-portfolio__desc">
                    Ознакомьтесь c кейсами, созданными вашими коллегами c помощью
                    современных инструментов для самостоятельной аналитики
                </div>

                <div className="metrics">
                    {appCount && (
                        <div>
                            <div className="number">
                                {divideNumIntoCategories(+appCount)}
                            </div>
                            <div className="number-desc">
                                {" "}
                                {enumerate(appCount, [
                                    "Приложение",
                                    "Приложения",
                                    "Приложений",
                                ])}
                            </div>
                        </div>
                    )}
                    {portfolioCount && (
                        <div>
                            <div className="number">
                                {divideNumIntoCategories(+portfolioCount)}
                            </div>
                            <div className="number-desc">
                                {" "}
                                {enumerate(portfolioCount, ["Кейс", "Кейса", "Кейсов"])}
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};
