export enum MetricKey {
    /* главная - "повышайте компетенции" - база знаний
     * база знаний - вверху страницы */
    KNOWLEDGE_COUNT = "KNOWLEDGE_COUNT",
    /* главная - "повышайте компетенции" - пользователей прошли обучение */
    COURSE_LEARNED_COUNT = "COURSE_LEARNED_COUNT",
    /* главная - "повышайте компетенции" - количество обращений */
    HELPDESK_CALL_COUNT = "HELPDESK_CALL_COUNT",
    /* главная - шаг4 */
    /* портфолио - вверху страницы - кейсов */
    PORTFOLIO_COUNT = "PORTFOLIO_COUNT",
    /* главная - вверху страницы */
    STORE_USER_COUNT = "STORE_USER_COUNT",
    STORE_SYSTEM_COUNT = "STORE_SYSTEM_COUNT",
    STORE_DATASET_COUNT = "STORE_DATASET_COUNT",
    STORE_APPLICATION_COUNT = "STORE_APPLICATION_COUNT",
    /*портфолио - вверху страницы - приложений*/
    BI_APP_COUNT = "BI_APP_COUNT",
    /*инструменты общее количество приложений для инструмента */
    STORE_APPLICATION_PIX_COUNT = "STORE_APPLICATION_PIX_COUNT",
    /*инструменты общее количество приложений для инструмента */
    STORE_APPLICATION_PYTHON_COUNT = "STORE_APPLICATION_PYTHON_COUNT",
    /*инструменты общее количество приложений для инструмента */
    STORE_APPLICATION_QLIK_COUNT = "STORE_APPLICATION_QLIK_COUNT",

    "COURSE_COUNT" = "COURSE_COUNT",
}
