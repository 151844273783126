import { createIcon } from '@consta/uikit/createIcon';

const svg = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="icon/customer-support">
      <path
        id="Vector"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.9851 5.71439C11.5073 5.70577 11.0326 5.79144 10.588 5.9665C10.1434 6.14156 9.73765 6.40259 9.39404 6.73463C9.05043 7.06667 8.77567 7.46321 8.58548 7.90156C8.3953 8.33991 8.29342 8.81146 8.28569 9.28923V14.2858C8.28569 14.5889 8.16528 14.8796 7.95095 15.0939C7.73663 15.3082 7.44594 15.4286 7.14284 15.4286H5.71427C5.25962 15.4286 4.82359 15.248 4.5021 14.9265C4.18061 14.605 4 14.169 4 13.7143V11.4286C4 10.974 4.18061 10.538 4.5021 10.2165C4.82359 9.89498 5.25962 9.71437 5.71427 9.71437H6.57141V9.26866C6.58184 8.56625 6.73053 7.87278 7.009 7.22785C7.28747 6.58292 7.69026 5.99917 8.19436 5.50992C8.69846 5.02068 9.294 4.63553 9.94697 4.37648C10.5999 4.11742 11.2975 3.98953 11.9999 4.00012C12.7023 3.98953 13.4 4.11742 14.0529 4.37648C14.7059 4.63553 15.3014 5.02068 15.8055 5.50992C16.3096 5.99917 16.7124 6.58292 16.9909 7.22785C17.2694 7.87278 17.4181 8.56625 17.4285 9.26866V9.71437H18.2856C18.7403 9.71437 19.1763 9.89498 19.4978 10.2165C19.8193 10.538 19.9999 10.974 19.9999 11.4286V13.7143C19.9999 14.169 19.8193 14.605 19.4978 14.9265C19.1763 15.248 18.7403 15.4286 18.2856 15.4286H17.4285V16C17.4286 16.7723 17.1444 17.5176 16.6301 18.0937C16.1157 18.6698 15.4073 19.0364 14.6399 19.1235C14.4909 19.3895 14.2736 19.611 14.0104 19.7651C13.7473 19.9192 13.4478 20.0003 13.1428 20H11.4285C10.9739 20 10.5378 19.8194 10.2163 19.4979C9.89486 19.1764 9.71425 18.7404 9.71425 18.2857C9.71425 17.8311 9.89486 17.3951 10.2163 17.0736C10.5378 16.7521 10.9739 16.5715 11.4285 16.5715H13.1428C13.7622 16.5715 14.3051 16.8995 14.6056 17.392C14.9206 17.3196 15.2018 17.1426 15.4031 16.8897C15.6045 16.6369 15.7141 16.3233 15.7142 16V9.28808C15.7064 8.80786 15.6034 8.33395 15.4113 7.89375C15.2192 7.45356 14.9417 7.05581 14.595 6.72349C14.2482 6.39118 13.839 6.1309 13.391 5.95769C12.9431 5.78449 12.4652 5.70179 11.9851 5.71439Z"
        fill="#00395C"
        fillOpacity="0.8"
      />
    </g>
  </svg>
);

export const IconSupport = createIcon({
  m: svg,
  s: svg,
  xs: svg,
  name: 'IconSupport',
});
