import { FC } from "react";

import { useFormContext, useWatch } from "react-hook-form";

import {
    DragDropContext,
    Draggable,
    DraggableLocation,
    Droppable,
} from "react-beautiful-dnd";

import { ImagesOrderItem } from "./components/ImagesOrderItem";

import { ImagesPlacementOrderProps } from "./model/ImagesPlacementOrder.model";

import "./ImagesPlacementOrder.scss";

export const ImagesPlacementOrder: FC<ImagesPlacementOrderProps> = ({
    fieldName,
    control,
}) => {
    const { setValue } = useFormContext();

    const images = useWatch({
        control,
        name: fieldName,
    });

    if (!images?.value?.length) {
        return null;
    }

    const onDragEnd = ({
        destination,
        source,
    }: {
        destination?: DraggableLocation | null;
        source: DraggableLocation;
    }) => {
        if (!destination) return;
        const newItems = Array.from(images.value);
        const [reorderedItem] = newItems.splice(source.index, 1);
        newItems.splice(destination.index, 0, reorderedItem);
        setValue(fieldName, { value: newItems });
    };

    return (
        <div className="image-placement-order">
            <div className="image-placement-order__title">Порядок размещения</div>
            <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId={fieldName}>
                    {(provided) => (
                        <div {...provided.droppableProps} ref={provided.innerRef}>
                            {images.value.map((image: File, index: number) => (
                                <Draggable
                                    key={index}
                                    draggableId={index.toString()}
                                    index={index}
                                >
                                    {(provided) => (
                                        <div
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                            ref={provided.innerRef}
                                            className="image-placement-order__item"
                                        >
                                            <ImagesOrderItem
                                                key={index}
                                                image={image}
                                                fieldName={fieldName}
                                                currentIndex={index}
                                                control={control}
                                            />
                                        </div>
                                    )}
                                </Draggable>
                            ))}
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
            </DragDropContext>
        </div>
    );
};
