import { FC, useMemo, useState } from "react";

import {
    useDeleteKnowledgeMutation,
    useGetCurrentUserRightsQuery,
    useGetMetricsQuery,
    useRateKnowledgeMutation,
} from "store/labApi";

import { IconThumbUp } from "@consta/icons/IconThumbUp";
import { IconPlay } from "@consta/icons/IconPlay";
import { Modal } from "@consta/uikit/Modal";
import { Button } from "@consta/uikit/Button";

import { checkPermission } from "shared/utils/rights.utils";
import { materialTypesItems } from "shared/constants/materialTypes.const";
import { formatType } from "shared/constants/formatTypes.const";

import {
    blpsKnowledgesRights,
    brdKnowledgesRights,
    cfKnowledgesRights,
} from "./KnowledgeItem.const";

import { KnowledgeItemProps } from "./model/KnowledgeItem.model";

import "./KnowledgeItem.scss";
import { Tag } from "@consta/uikit/Tag";
import { IconQuote } from "@consta/uikit/IconQuote";
import { IconDocFilled } from "@consta/uikit/IconDocFilled";
import { IconConnection } from "@consta/uikit/IconConnection";
import { IconEdit } from "@consta/uikit/IconEdit";
import { IconTrash } from "@consta/uikit/IconTrash";
import { DeleteModal } from "../../../../../../../components/DeleteModal/DeleteModal";

export const KnowledgeItem: FC<KnowledgeItemProps> = ({
    id,
    likes,
    complexity,
    description,
    title,
    image,
    toolName,
    blocks,
    link,
    type,
    fileId,
    liked,
}) => {
    const [isOpenPlayer, setIsOpenPlayer] = useState(false);
    const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false);
    const [likesCount, setLikesCount] = useState(likes);

    const [deleteKnowledge] = useDeleteKnowledgeMutation();
    const [rateKnowledge] = useRateKnowledgeMutation();
    const { refetch } = useGetMetricsQuery();
    const { data: userData } = useGetCurrentUserRightsQuery();
    const rightsData = userData?.roles ?? undefined;
    const isAdmin = localStorage.getItem("USER_ROLE") === "ADMIN";
    const basepath =
        process.env.REACT_APP_BASEPATH === "/"
            ? ""
            : `${process.env.REACT_APP_BASEPATH}/`;

    const [likedItem, setIsLiked] = useState(liked);
    const getItemOnClick = (value: string) => {
        return value === "DELETE"
            ? () => {
                  setIsOpenDeleteModal(true);
              }
            : () => window.open(`${basepath}knowledge/edit/${id}`, "_blank");
    };

    const materialItem = useMemo(() => {
        const label = materialTypesItems.find((item) => item.id === type)?.label;
        if (type === formatType.FILE) {
            return (
                <Button
                    size="s"
                    form="round"
                    iconRight={IconDocFilled}
                    label="Файл"
                    view="ghost"
                    onClick={() =>
                        window.open(
                            `${process.env.REACT_APP_API_URL}/knowledge/file/${fileId}`
                        )
                    }
                />
            );
        }
        if (type === formatType.LINK) {
            return (
                <Button
                    size="s"
                    form="round"
                    iconRight={IconConnection}
                    label={label}
                    view="ghost"
                    onClick={() => window.open(link, "_blank")}
                />
            );
        }

        if (type === formatType.VIDEO) {
            return (
                <Button
                    size="s"
                    form="round"
                    iconRight={IconPlay}
                    label={label}
                    view="ghost"
                    onClick={() => setIsOpenPlayer(true)}
                />
            );
        }
    }, [type, fileId, link]);

    const canEditKnowledge = useMemo(() => {
        return (
            (blocks.some((item) => {
                return item.short_name === "БРД";
            }) &&
                checkPermission(brdKnowledgesRights, rightsData)) ||
            (blocks.some((item) => {
                return item.short_name === "БЛПС";
            }) &&
                checkPermission(blpsKnowledgesRights, rightsData)) ||
            (blocks.some((item) => {
                return item.short_name === "КФ";
            }) &&
                checkPermission(cfKnowledgesRights, rightsData))
        );
    }, [blocks, rightsData]);

    // const canEditKnowledge = useMemo(() => {
    //     if (
    //             blocks.some((item) => {
    //                 return item.short_name === "БРД";
    //             })
    //     ) {
    //         return checkPermission(brdKnowledgesRights, rightsData);
    //     }
    //
    //     if (
    //             blocks.some((item) => {
    //                 return item.short_name === "БЛПС";
    //             })
    //     ) {
    //         return checkPermission(blpsKnowledgesRights, rightsData);
    //     }
    //     if (
    //             blocks.some((item) => {
    //                 return item.short_name === "КФ";
    //             })
    //     ) {
    //         return checkPermission(cfKnowledgesRights, rightsData);
    //     }
    // }, [blocks, rightsData]);

    const setIsLikedItem = () => {
        setIsLiked(!likedItem);
    };

    const IconDelete = () => (
        <IconTrash size={"s"} style={{ margin: "0 auto", color: "red" }} />
    );

    return (
        <>
            <div className="knowledge-item">
                <div className="knowledge-item-head">
                    <div>
                        <img
                            src={`${process.env.REACT_APP_API_URL}/images/${image}`}
                            alt="logo"
                            className="tool-logo"
                        />
                        {toolName}
                        {complexity?.level === 0 && (
                            <>
                                <svg
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <rect
                                        x="2"
                                        y="12"
                                        width="4"
                                        height="10"
                                        fill="#22C38E"
                                    />
                                    <rect
                                        x="10"
                                        y="7"
                                        width="4"
                                        height="15"
                                        fill="#DEE4E8"
                                    />
                                    <rect
                                        x="18"
                                        y="2"
                                        width="4"
                                        height="20"
                                        fill="#DEE4E8"
                                    />
                                </svg>
                            </>
                        )}
                        {complexity?.level === 1 && (
                            <>
                                <svg
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <rect
                                        x="2"
                                        y="12"
                                        width="4"
                                        height="10"
                                        fill="#F2C94C"
                                    />
                                    <rect
                                        x="10"
                                        y="7"
                                        width="4"
                                        height="15"
                                        fill="#F2C94C"
                                    />
                                    <rect
                                        x="18"
                                        y="2"
                                        width="4"
                                        height="20"
                                        fill="#DEE4E8"
                                    />
                                </svg>
                            </>
                        )}
                        {complexity?.level === 2 && (
                            <>
                                <svg
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <rect
                                        x="2"
                                        y="12"
                                        width="4"
                                        height="10"
                                        fill="#F38B00"
                                    />
                                    <rect
                                        x="10"
                                        y="7"
                                        width="4"
                                        height="15"
                                        fill="#F38B00"
                                    />
                                    <rect
                                        x="18"
                                        y="2"
                                        width="4"
                                        height="20"
                                        fill="#F38B00"
                                    />
                                </svg>
                            </>
                        )}
                        {complexity?.name}
                    </div>
                    <div className="blocks">
                        {blocks?.map((item) => (
                            <Tag
                                size="s"
                                mode="info"
                                key={item._uuid}
                                label={item.short_name}
                            />
                        ))}
                    </div>
                </div>

                <div className="knowledge-item__title">
                    <div
                        className="knowledge-item__title-text"
                        onClick={() =>
                            window.open(`${basepath}knowledge/${id}`, "_blank")
                        }
                    >
                        {title}
                    </div>
                    {/*{isAdmin && canEditKnowledge && (*/}
                    {/*        <IconKebab*/}
                    {/*                ref={ref}*/}
                    {/*                onClick={() => setIsOpenContextMenu((prev) => !prev)}*/}
                    {/*        />*/}
                    {/*)}*/}
                    {/*<ContextMenu*/}
                    {/*        items={ContextMenuItems}*/}
                    {/*        anchorRef={ref}*/}
                    {/*        isOpen={isOpenContextMenu}*/}
                    {/*        getItemLeftSide={renderLeftSideItem}*/}
                    {/*        getItemStatus={(item) => item.status}*/}
                    {/*        getItemOnClick={getItemOnClick}*/}
                    {/*        direction="downStartRight"*/}
                    {/*        onClickOutside={() => setIsOpenContextMenu(false)}*/}
                    {/*/>*/}
                </div>

                {!!description && (
                    <div className="knowledge-item__description">{description}</div>
                )}

                <div className="knowledge-item__info">
                    <div>
                        <Button
                            size="s"
                            view="primary"
                            form="round"
                            label={type === "INTERNAL" ? "Статья" : "Подробнее"}
                            iconRight={type === "INTERNAL" ? IconQuote : undefined}
                            onClick={() =>
                                window.open(`${basepath}knowledge/${id}`, "_blank")
                            }
                        />
                        {materialItem}

                        {isAdmin && canEditKnowledge && (
                            <>
                                <Button
                                    size="s"
                                    view="ghost"
                                    form="round"
                                    onlyIcon
                                    iconRight={IconEdit}
                                    onClick={getItemOnClick("")}
                                />

                                <Button
                                    size="s"
                                    view="ghost"
                                    form="round"
                                    onlyIcon
                                    iconRight={IconDelete}
                                    onClick={getItemOnClick("DELETE")}
                                />
                            </>
                        )}
                    </div>
                    <Button
                        size="s"
                        view={likedItem ? "secondary" : "clear"}
                        form="round"
                        iconRight={IconThumbUp}
                        label={likesCount || "0"}
                        onClick={async () => {
                            const rateData = await rateKnowledge(id);
                            if ("data" in rateData) {
                                setLikesCount((prev) =>
                                    rateData.data.message === "like" ? prev + 1 : prev - 1
                                );
                            }
                            setIsLikedItem();
                        }}
                    />
                </div>
            </div>
            <DeleteModal
                isOpen={isOpenDeleteModal}
                cancelAction={() => setIsOpenDeleteModal(false)}
                confirmAction={async () => {
                    await deleteKnowledge(id);
                    refetch();
                }}
                title={`Удалить материал "${title}"?`}
                content={`Вы действительно хотите удалить материал “${title}” и все данные в нем?`}
            />
            <Modal
                isOpen={isOpenPlayer}
                hasOverlay
                onClickOutside={() => setIsOpenPlayer(false)}
                onEsc={() => setIsOpenPlayer(false)}
            >
                <video width="595" height="334" controls>
                    <source
                        src={`${process.env.REACT_APP_API_URL}/knowledge/video/${fileId}`}
                    />
                </video>
            </Modal>
        </>
    );
};
