type TemporaryMetricsConfig = {
    pix_bi_all: number;
    pix_bi_portfolio: number;
    qlik_all: number;
    qlik_portfolio: number;
    python_all: number;
    python_portfolio: number;
};

// настройки по умолчанию, новые настройки приходят из файла /public/assets/config.json
// для исключения использования не актуальных данных использовать только внутри компонент
export const temporaryMetricsConfig: TemporaryMetricsConfig = {
    pix_bi_all: 124,
    pix_bi_portfolio: 5,
    qlik_all: 125,
    qlik_portfolio: 6,
    python_all: 12,
    python_portfolio: 3,
};
