import { FC } from "react";

import { ReviewsViewWidgetItem } from "./components/ReviewsViewWidgetItem";

import { ReviewsViewWidgetProps } from "./model/ReviewsViewWidget.model";

import "./ReviewsViewWidget.scss";

export const ReviewsViewWidget: FC<ReviewsViewWidgetProps> = ({ reviews }) => {
    return (
        <div className="reviews-view-widget">
            {reviews?.map((review) => {
                return <ReviewsViewWidgetItem key={review._uuid} review={review} />;
            })}
        </div>
    );
};
