import React, { FC, useMemo } from "react";

import { useFormContext, useWatch } from "react-hook-form";

import { DragNDropField } from "@consta/uikit/DragNDropField";

import { ImagesPlacementOrder } from "./components/ImagesPlacementOrder";
import { ImagesOrderItem } from "./components/ImagesPlacementOrder/components/ImagesOrderItem";

import { ImageDragAndDropItemProps } from "./model/ImageDragAndDropItem.model";

import "./ImageDragAndDropItem.scss";

export const ImageDragAndDropItem: FC<ImageDragAndDropItemProps> = ({
    title,
    fieldName,
    control,
    onlyOne,
    images,
    fieldState,
}) => {
    const { setValue, trigger } = useFormContext();

    const formImages = useWatch({
        control,
        name: fieldName,
        defaultValue: { value: images },
    });

    const isViewDragField = !onlyOne || (onlyOne && !formImages?.value?.length);

    useMemo(() => {
        if (images?.length) {
            setValue(fieldName, { value: images });
        }
    }, [fieldName, images, setValue]);

    return (
        <div className={`image-drag-drop-item`}>
            <div
                className={`image-drag-drop-item__title ${
                    fieldState?.error ? "form-error" : ""
                }`}
            >
                <label>{title}</label>
            </div>
            {isViewDragField ? (
                <DragNDropField
                    className={`image-drag-drop-item__drag-field drag-field`}
                    style={fieldState?.error ? { borderColor: "red" } : undefined}
                    accept={[".png", ".jpg", ".jpeg"]}
                    onDropFiles={(value) => {
                        setValue(
                            fieldName,
                            formImages?.value
                                ? { value: [...formImages.value, value[0]] }
                                : { value: [value[0]] }
                        );
                        trigger(fieldName);
                    }}
                >
                    {({ openFileDialog }) => (
                        <>
                            <div className="drag-field__title">
                                Перетащите фотографию или{" "}
                                <span
                                    className="drag-field__title_link"
                                    onClick={openFileDialog}
                                >
                                    загрузите
                                </span>
                            </div>
                            <div className="drag-field__text">
                                Поддерживаемые форматы: PNG, JPG, JPEG
                            </div>
                        </>
                    )}
                </DragNDropField>
            ) : null}
            {isViewDragField ? (
                <ImagesPlacementOrder fieldName={fieldName} control={control} />
            ) : (
                <ImagesOrderItem
                    image={formImages?.value[0]}
                    fieldName={fieldName}
                    currentIndex={0}
                    control={control}
                />
            )}
        </div>
    );
};
