import { FC } from "react";

import { TextBlockProps } from "./model/TextBlock.model";
import parse from "html-react-parser";

import "./TextBlock.scss";

export const TextBlock: FC<TextBlockProps> = ({ title, description }) => (
    <div className="text-block">
        <div className="text-block__title">{title}</div>
        <div className="text-block__description">{parse(description || '')}</div>
    </div>
);
