import { CreateContactModalSubmitData } from "./model/CreateContactModal.model";

export const createParams = (data: CreateContactModalSubmitData, blockId: string) => {
    const formData = new FormData();
    formData.append("external_key", data?.employee?.value?.id || "");
    formData.append("position", data?.position?.value || "");
    formData.append("image", data?.image || "");
    formData.append("uuid_block", blockId);
    formData.append("department", "null");
    return formData;
};
