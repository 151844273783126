import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

import { AdminBlockDto, ToolDto } from "./types/Tool.model";
import { KnowledgeQueryDto, KnowledgesQueryDto } from "./types/Knowledge.model";
import { MetricTypeDto } from "./types/Metrics.model";
import { HelpdeskDto } from "./types/Helpdesk.model";
import { TeamDto } from "./types/Team.model";
import { ManualBlockDto } from "./types/ManualBlocks.model";
import { ExternalUsersQueryDto } from "./types/ExternalUsers.model";
import { KnowledgeFiltersDto } from "./types/KnowledgeFilters.model";
import {
    PortfolioFiltersQueryDto,
    PortfolioMainDto,
    PortfolioModelDto,
    PortfolioQueryDto,
} from "./types/Portfolio.model";
import { AdminBlockQueryDto } from "./types/Administration.model";

// import { userRightsCutsPart } from "shared/constants/userRights.conts";
import { FaqItemDto } from "./types/Faq.model";
import { UserAuthModel } from "./types/UserAuth.model";

export const labApi = createApi({
    reducerPath: "labApi",
    tagTypes: ["labApi", "Knowledge", "Portfolio"],
    baseQuery: fetchBaseQuery({
        baseUrl: process.env.REACT_APP_API_URL,
        prepareHeaders: (headers) => {
            const isExternalDomain = !!process.env.REACT_APP_IS_EXTERNAL_DOMAIN;
            if (!isExternalDomain) {
                return headers;
            } else {
                const user = localStorage.getItem("user");
                headers.set("X-Auth-User", user ? user : "");
                return headers;
            }
        },
    }),
    endpoints: (builder) => ({
        getKnowledges: builder.query<
            KnowledgesQueryDto,
            { params: string; page: number }
        >({
            query: ({ params, page }) => {
                return `knowledge?limit=16&page=${page}${params || ""}`;
            },
            providesTags: (result) =>
                result
                    ? [
                          ...result.data.models.map(({ guid }) => ({
                              type: "Knowledge" as const,
                              guid,
                          })),
                          "Knowledge",
                      ]
                    : ["Knowledge"],
        }),
        getPortfolio: builder.query<PortfolioQueryDto, { params: string; page: number }>({
            query: ({ params, page }) => {
                return `portfolio?limit=16&page=${page}${params || ""}`;
            },
            providesTags: (result) =>
                result
                    ? [
                          ...result.data.models.map(({ _uuid }) => ({
                              type: "Portfolio" as const,
                              _uuid,
                          })),
                          "Portfolio",
                      ]
                    : ["Portfolio"],
        }),
        getPortfolioItem: builder.query<{ data: PortfolioModelDto }, string>({
            query: (id) => {
                return `portfolio/${id}`;
            },
        }),
        deletePortfolio: builder.mutation({
            query: (id) => ({
                url: `portfolio/${id}`,
                method: "DELETE",
            }),
            invalidatesTags: ["Portfolio"],
        }),
        createPortfolio: builder.mutation({
            query: (body) => ({
                url: "portfolio",
                method: "POST",
                body,
            }),
            invalidatesTags: ["Portfolio"],
        }),
        editPortfolio: builder.mutation({
            query: ({ id, body }) => ({
                url: `portfolio/${id}?_method=PUT`,
                method: "POST",
                body,
            }),
        }),
        getKnowledge: builder.query<KnowledgeQueryDto, string>({
            query: (id) => {
                return `knowledge/${id}`;
            },
        }),
        createKnowledge: builder.mutation({
            query: (body) => ({
                url: "knowledge",
                method: "POST",
                body,
            }),
            invalidatesTags: ["Knowledge"],
        }),
        editKnowledge: builder.mutation({
            query: ({ id, body }) => ({
                url: `knowledge/${id}?_method=PUT`,
                method: "POST",
                body,
            }),
            invalidatesTags: ["Knowledge"],
        }),
        rateKnowledge: builder.mutation<{ message: string }, string>({
            query: (id: string) => ({
                url: `knowledge/${id}/like`,
                method: "GET",
            }),
        }),
        publishPortfolio: builder.mutation<{ message: string }, string>({
            query: (id: string) => ({
                url: `portfolio/${id}/publish`,
                method: "GET",
            }),
        }),
        deleteKnowledge: builder.mutation({
            query: (id: string) => ({
                url: `knowledge/${id}`,
                method: "DELETE",
            }),
            invalidatesTags: ["Knowledge"],
        }),
        getKnowledgeFilters: builder.query<KnowledgeFiltersDto, void>({
            query: () => "knowledge/filters",
        }),
        getPortfolioFilters: builder.query<PortfolioFiltersQueryDto, void>({
            query: () => "portfolio/filters",
        }),
        getMetrics: builder.query<MetricTypeDto[], void>({
            query: () => "main/metrics",
        }),
        getTools: builder.query<ToolDto[], void>({
            query: () => "main/tool",
        }),
        getHelpdesk: builder.query<HelpdeskDto[], void>({
            query: () => "main/helpdesk?type=HELPDESK",
        }),
        getMainPagePortfolios: builder.query<PortfolioMainDto[], void>({
            query: () => "main/portfolio",
        }),
        getTeam: builder.query<TeamDto[], void>({
            query: () => "main/team",
        }),
        getAdminBlock: builder.query<AdminBlockQueryDto, string>({
            query: (id) => `administration/blocks/${id}`,
        }),
        updateMetric: builder.mutation({
            query: ({ id, body }) => ({
                url: `administration/blocks/${id}/metrics`,
                method: "POST",
                body,
            }),
        }),
        getManualBlocks: builder.query<{ data: ManualBlockDto[]; status: string }, void>({
            query: () => "manuals/dct-block",
        }),
        getAdminBlocks: builder.query<{ data?: AdminBlockDto[] }, void>({
            query: () => "administration/blocks",
        }),
        updateAdminBlocks: builder.mutation({
            query: ({ id, body }) => ({
                url: `/administration/blocks/${id}/tools`,
                method: "POST",
                body,
            }),
        }),
        getExternalUsers: builder.query<{ data?: ExternalUsersQueryDto }, string>({
            query: (value) => `administration/users/external?filter[search]=${value}`,
        }),
        createUser: builder.mutation({
            query: (body) => ({
                url: "administration/users",
                method: "POST",
                body,
            }),
        }),
        editUser: builder.mutation({
            query: ({ id, body }) => ({
                url: `administration/users/${id}`,
                method: "POST",
                body,
            }),
        }),
        deleteUser: builder.mutation({
            query: (id: string) => ({
                url: `administration/users/${id}`,
                method: "DELETE",
            }),
        }),
        editHelpdesk: builder.mutation({
            query: ({ id, body }) => ({
                url: `administration/blocks/${id}/helpdesks`,
                method: "POST",
                body,
            }),
        }),
        getCurrentUserRights: builder.query<UserAuthModel, void>({
            query: () => "administration/user/me",
            // transformResponse: ({ data }) => {
            //     return data.map((item: string) => {
            //         let newItem = item;
            //         userRightsCutsPart.forEach((substring) => {
            //             newItem = newItem.replace(substring, "");
            //         });
            //         return newItem;
            //     });
            // },
        }),
        getFaq: builder.query<FaqItemDto[], string>({
            query: (params) => {
                return `administration/faq${params}`;
            },
        }),
        createLog: builder.mutation({
            query: (body: { event_type: string; http_path: string }) => ({
                url: `event-log`,
                method: "POST",
                body,
            }),
        }),
    }),
});

export const {
    useGetKnowledgesQuery,
    useGetMetricsQuery,
    useGetToolsQuery,
    useGetHelpdeskQuery,
    useGetMainPagePortfoliosQuery,
    useGetTeamQuery,
    useGetKnowledgeQuery,
    useCreateKnowledgeMutation,
    useEditKnowledgeMutation,
    useDeleteKnowledgeMutation,
    useGetKnowledgeFiltersQuery,
    useGetManualBlocksQuery,
    useLazyGetAdminBlockQuery,
    useUpdateMetricMutation,
    useGetAdminBlocksQuery,
    useUpdateAdminBlocksMutation,
    useLazyGetExternalUsersQuery,
    useGetExternalUsersQuery,
    useCreateUserMutation,
    useEditUserMutation,
    useDeleteUserMutation,
    useGetPortfolioQuery,
    useGetPortfolioFiltersQuery,
    useEditHelpdeskMutation,
    useCreatePortfolioMutation,
    useGetPortfolioItemQuery,
    useEditPortfolioMutation,
    useLazyGetPortfolioItemQuery,
    useDeletePortfolioMutation,
    useGetCurrentUserRightsQuery,
    useRateKnowledgeMutation,
    usePublishPortfolioMutation,
    useGetFaqQuery,
    useCreateLogMutation,
} = labApi;
