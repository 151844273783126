import { EditContactSubmit } from "./model/EditContactModal.model";

export const createParams = (data: EditContactSubmit, image?: File) => {
    const formData = new FormData();

    formData.append("position", data?.position?.value || "undefined");
    formData.append("department", "null");
    image && formData.append("image", image);
    formData.append("_method", "PUT");
    return formData;
};
