import { EmptyPage } from "pages/EmptyPage";
import { FaqPage } from "pages/FaqPage";
import { KnowledgeCreatePage } from "pages/KnowledgeCreatePage";
import { KnowledgeEditPage } from "pages/KnowledgeEditPage";
import { KnowledgeItemPage } from "pages/KnowledgeItemPage";
import { KnowledgePage } from "pages/KnowledgePage";
import { MainPage } from "pages/MainPage";
import { MetricsPage } from "pages/MetricsPage";
import { PortfolioCreatePage } from "pages/PortfolioCreatePage";
import { PortfolioEditPage } from "pages/PortfolioEditPage";
import { PortfolioItemPage } from "pages/PortfolioItemPage";
import { PortfolioPage } from "pages/PortfolioPage";
import { Navigate } from "react-router-dom";
import { ToolsPage } from "../pages/ToolsPage";

const basepath =
    process.env.REACT_APP_BASEPATH === "/" ? "/" : `${process.env.REACT_APP_BASEPATH}`;

// ссылки на внешние ресурсы перезаписываются при получении /public/assets/config.json
export const PageRoutes = {
    DataLab: `${basepath}`,
    DataLab_MainPage: `${basepath}/main`,
    DataLab_ShopDataPage: "",
    DataLab_EducationPage: "",
    DataLab_KnowledgesPage: `${basepath}/knowledge`,
    DataLab_PortfolioPage: `${basepath}/portfolio`,
    DataLab_ToolsPage: `${basepath}/tools`,
    DataLab_PortfolioCreatePage: `${basepath}/portfolio/create`,
    DataLab_PortfolioEditPage: `${basepath}/portfolio/edit/:id`,
    DataLab_KnowledgeCreatePage: `${basepath}/knowledge/create`,
    DataLab_KnowledgeEditPage: `${basepath}/knowledge/edit/:id`,
    DataLab_MetricsPage: `${basepath}/metrics`,
    DataLab_Faq: `${basepath}/faq`,
};

export const appBaseRoute = PageRoutes.DataLab;
export const onLogoClickRoute = PageRoutes.DataLab_MainPage;
export const appRedirectRoute = PageRoutes.DataLab_MainPage;

// объект PageRoutes мутирует при получении данных из /public/assets/config.json,
// для получения актуальны данных требуется массив роутов должен формироваться
// в процессе вызова функции
export const getAppRoutes = () => [
    { path: PageRoutes.DataLab_MainPage, element: <MainPage /> },
    {
        path: `${basepath}`,
        element: <Navigate to={PageRoutes.DataLab_MainPage} replace />,
    },
    {
        path: PageRoutes.DataLab_EducationPage,
        element: <EmptyPage title="Обучение" />,
    },
    { path: PageRoutes.DataLab_KnowledgesPage, element: <KnowledgePage /> },
    {
        path: PageRoutes.DataLab_PortfolioPage,
        element: <PortfolioPage />,
    },
    {
        path: `${PageRoutes.DataLab_PortfolioPage}/:id`,
        element: <PortfolioItemPage />,
    },
    {
        path: PageRoutes.DataLab_PortfolioCreatePage,
        element: <PortfolioCreatePage />,
    },
    {
        path: `${PageRoutes.DataLab_PortfolioEditPage}`,
        element: <PortfolioEditPage />,
    },
    {
        path: `${PageRoutes.DataLab_KnowledgesPage}/:id`,
        element: <KnowledgeItemPage />,
    },
    {
        path: PageRoutes.DataLab_KnowledgeCreatePage,
        element: <KnowledgeCreatePage />,
    },
    {
        path: PageRoutes.DataLab_KnowledgeEditPage,
        element: <KnowledgeEditPage />,
    },
    {
        path: PageRoutes.DataLab_MetricsPage,
        element: <MetricsPage />,
    },
    {
        path: PageRoutes.DataLab_PortfolioCreatePage,
        element: <MetricsPage />,
    },
    {
        path: PageRoutes.DataLab_Faq,
        element: <FaqPage />,
    },
    {
        path: PageRoutes.DataLab_ToolsPage,
        element: <ToolsPage />,
    },
    {
        path: "*",
        element: <EmptyPage title="Страница не найдена" />,
    },
];

export const withoutFooterRoutes = [];
