import { FC, useRef, useState } from "react";

import { useDeleteUserMutation } from "store/labApi";

import { IconKebab } from "@consta/icons/IconKebab";
import { ContextMenu } from "@consta/uikit/ContextMenu";

import { EditContactModal } from "./components/EditContactModal";

import { renderLeftSideItem } from "shared/utils/contextMenuUtils";
import { ContextMenuItem, ContextMenuItems } from "shared/constants/contextMenu.const";

import { UserCardProps } from "./model/UserCard.model";

import "./UserCard.scss";
import { DeleteModal } from "../../../../../../../../components/DeleteModal/DeleteModal";

export const UserCard: FC<UserCardProps> = ({
    name,
    position,
    email,
    image,
    userId,
    hasRight,
    refetch,
    collapsed,
}) => {
    const [deleteUser, { isLoading: isLoadingDeleteUser }] = useDeleteUserMutation();

    const [isOpenContextMenu, setIsOpenContextMenu] = useState(false);
    const [isOpenEditContactModal, setIsOpenEditContactModal] = useState(false);
    const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false);

    const ref = useRef(null);
    console.log(hasRight, userId);
    const onItemClick = (item: ContextMenuItem) => {
        item.value === "DELETE"
            ? setIsOpenDeleteModal(true)
            : setIsOpenEditContactModal(true);
        setIsOpenContextMenu(false);
    };

    return collapsed ? (
        <>
            <div className="user-card">
                {image && (
                    <img
                        src={
                            typeof image === "string"
                                ? `${process.env.REACT_APP_API_URL}/images/${image}`
                                : URL.createObjectURL(image)
                        }
                        alt="avatar"
                    />
                )}
                <div>
                    <div className="user-card__name">{name}</div>
                    <div className="user-card__text">{position}</div>
                    <a
                        className="user-card__text user-card__link"
                        href={`mailto:${email}`}
                    >
                        {email}
                    </a>
                </div>
                {hasRight && userId && (
                    <IconKebab
                        ref={ref}
                        onClick={() => setIsOpenContextMenu((prev) => !prev)}
                    />
                )}
                <ContextMenu
                    style={{ zIndex: 990 }}
                    items={ContextMenuItems}
                    anchorRef={ref}
                    isOpen={isOpenContextMenu}
                    getItemLeftSide={renderLeftSideItem}
                    getItemStatus={(item) => item.status}
                    onItemClick={({ item }) => onItemClick(item)}
                    direction="downStartRight"
                    possibleDirections={["downStartRight"]}
                    spareDirection={"downStartRight"}
                    onClickOutside={() => setIsOpenContextMenu(false)}
                    className={"user-card__context"}
                />
            </div>
            {userId && refetch && (
                <EditContactModal
                    title="Редактировать контакт"
                    isOpen={isOpenEditContactModal}
                    handleClose={() => setIsOpenEditContactModal(false)}
                    userId={userId}
                    refetch={refetch}
                    position={position}
                    employee={{
                        label: name,
                        subLabel: email,
                        avatarUrl: image
                            ? `${process.env.REACT_APP_API_URL}/images/${image}`
                            : undefined,
                        id: userId,
                    }}
                />
            )}
            <DeleteModal
                isOpen={isOpenDeleteModal}
                cancelAction={() => setIsOpenDeleteModal(false)}
                confirmAction={async () => {
                    userId && (await deleteUser(userId));
                    refetch && refetch();
                }}
                title={`Удалить сотрудника "${name}"?`}
                content={`Вы действительно хотите удалить сотрудника “${name}” из списка контактов?`}
                loading={isLoadingDeleteUser}
            />
        </>
    ) : (
        <></>
    );
};
