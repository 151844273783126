import "./AboutProcessBlock.scss";
import { TaskSlider } from "./components/TaskSlider";
// import {
//     requestForDevDatasetText
// } from "../../../MainPage/components/DataBlock/components/DataShopWidget/DataShopWidget.const";
import { Button } from "@consta/uikit/Button";
import { IconForward } from "@consta/uikit/IconForward";
import { ToolsBlock } from "../ToolsBlock";
import { useRef, useState } from "react";
import { IconAdd } from "@consta/uikit/IconAdd";
// import { useNavigate } from "react-router-dom";
//import iconQlikQrCode from "../../../../assets/icons/qlik.svg";
import { IconEdit } from "@consta/uikit/IconEdit";
import { linksConfig } from "../../../../configs/linksConfig";
import icn_qlik from "../../../../assets/icons/icn_qlik.svg";
import icn_pix from "../../../../assets/icons/icn_pix.svg";
import { RequestForDatasetModal } from "./components/RequestForDatasetModal/RequestForDatasetModal";
import { cnMixSpace } from "@consta/uikit/MixSpace";

// import { PortfolioBlock } from "../PortfolioBlock";
// import { useGetMetricsQuery } from "../../../../store/labApi";
// import { MetricTypeDto } from "../../../../store/types/Metrics.model";
// import { MetricKey } from "../../../../shared/constants/metrics.const";
// import { IncreasingCompetenciesBlock } from "../IncreasingCompetenciesBlock";

export const AboutProcessBlock = () => {
    // const { data } = useGetMetricsQuery(undefined, { refetchOnMountOrArgChange: true });
    // const portfolioCount = data?.find(
    //         (metric: MetricTypeDto) => metric.key === MetricKey.PORTFOLIO_COUNT
    // )?.value;

    const basepath =
        process.env.REACT_APP_BASEPATH === "/"
            ? "/"
            : `${process.env.REACT_APP_BASEPATH}/`;

    const taskRef = useRef<HTMLDivElement>(null);
    const createAppRef = useRef<HTMLDivElement>(null);
    const publishRef = useRef<HTMLDivElement>(null);
    const sourceRef = useRef<HTMLDivElement>(null);
    const [isOpenEditLinksModal, setIsOpenEditLinksModal] = useState(false);
    const isAdmin = localStorage.getItem("USER_ROLE") === "ADMIN";

    // const navigate = useNavigate();
    const scrollToTask = () => {
        window.scrollTo({
            behavior: "smooth",
            top: (taskRef.current?.offsetTop || 0) - 90,
        });
    };
    const scrollToCreateApp = () => {
        window.scrollTo({
            behavior: "smooth",
            top: (createAppRef.current?.offsetTop || 0) - 90,
        });
    };
    const scrollToPublish = () => {
        window.scrollTo({
            behavior: "smooth",
            top: (publishRef.current?.offsetTop || 0) - 90,
        });
    };
    const scrollToSource = () => {
        window.scrollTo({
            behavior: "smooth",
            top: (sourceRef.current?.offsetTop || 0) - 90,
        });
    };

    const scrollToComp = () => {
        window.scrollTo({
            behavior: "smooth",
            top: (publishRef.current?.offsetTop || 0) - 90 + 1148,
        });
    };

    const marketLinks = [
        {
            name: "Магазин данных",
            link: linksConfig.new_market,
            icon: icn_pix,
            disabled: true,
        },
        {
            name: "Магазин данных БЛПС",
            link: linksConfig.old_blps_market,
            icon: icn_qlik,
        },
        {
            name: "Аналоговый Магазин данных БРД",
            link: linksConfig.old_brd_analog_market,
            icon: icn_qlik,
        },
    ];

    const [isRequestModalOpen, setIsRequestModalOpen] = useState<boolean>(false);

    return (
        <div className="about-process-block-main">
            <div className="about-process-wrap">
                <div className="subtitle">О процессе</div>
                <div className="title">Как решить задачу</div>
                <div className="desc">
                    Мы предлагаем универсальный процесс и инструменты для выполнения
                    каждого его шага
                </div>
            </div>
            <div className="items">
                <div>
                    <div className="item" onClick={scrollToTask}>
                        <div className="scheme-circle">1</div>
                        <svg
                            width="48"
                            height="48"
                            viewBox="0 0 48 48"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <rect width="48" height="48" rx="8" fill="#ECF1F4" />
                            <path
                                d="M10 13C10 11.8954 10.8954 11 12 11H36C37.1046 11 38 11.8954 38 13V15C38 16.1046 37.1046 17 36 17H12C10.8954 17 10 16.1046 10 15V13Z"
                                fill="#0078D2"
                            />
                            <path
                                d="M10 23C10 21.8954 10.8954 21 12 21H36C37.1046 21 38 21.8954 38 23V25C38 26.1046 37.1046 27 36 27H12C10.8954 27 10 26.1046 10 25V23Z"
                                fill="#0078D2"
                            />
                            <path
                                d="M10 33C10 31.8954 10.8954 31 12 31H22C23.1046 31 24 31.8954 24 33V35C24 36.1046 23.1046 37 22 37H12C10.8954 37 10 36.1046 10 35V33Z"
                                fill="#00359B"
                            />
                        </svg>
                        Опишите задачу
                        <svg
                            className="arrow"
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="16"
                            viewBox="0 0 24 16"
                            fill="none"
                        >
                            <rect
                                width="16"
                                height="4"
                                transform="matrix(-1 0 0 1 16 6)"
                                fill="#0078D2"
                            />
                            <circle
                                cx="8"
                                cy="8"
                                r="6"
                                transform="matrix(-1 0 0 1 24 0)"
                                fill="white"
                                stroke="#00359B"
                                strokeWidth="4"
                            />
                        </svg>
                        <svg
                            className="arrow-bottom"
                            width="16"
                            height="39"
                            viewBox="0 0 16 39"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <rect
                                x="6"
                                y="31"
                                width="31"
                                height="4"
                                transform="rotate(-90 6 31)"
                                fill="#ECF1F4"
                            />
                            <circle
                                cx="8"
                                cy="31"
                                r="6"
                                transform="rotate(-90 8 31)"
                                fill="white"
                                stroke="#ECF1F4"
                                strokeWidth="4"
                            />
                        </svg>
                    </div>
                    <div className="item big" onClick={scrollToSource}>
                        <div className="scheme-circle">2</div>
                        <svg
                            width="48"
                            height="48"
                            viewBox="0 0 48 48"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <rect width="48" height="48" rx="8" fill="#ECF1F4" />
                            <path
                                d="M10 8H38V38C38 39.1046 37.1046 40 36 40H12C10.8954 40 10 39.1046 10 38V8Z"
                                fill="#0078D2"
                            />
                            <path
                                d="M10 8H38V38C38 39.1046 37.1046 40 36 40H12C10.8954 40 10 39.1046 10 38V8Z"
                                fill="#0078D2"
                            />
                            <path
                                d="M30 8H38V38C38 39.1046 37.1046 40 36 40H30V8Z"
                                fill="#00359B"
                            />
                            <path
                                d="M9 8H30L27.5195 13.7878C27.2043 14.5232 26.4813 15 25.6812 15H9.03308C7.59725 15 6.62919 13.5319 7.19479 12.2122L9 8Z"
                                fill="#00359B"
                            />
                            <path
                                d="M39 8H30L32.4805 13.7878C32.7957 14.5232 33.5187 15 34.3188 15H38.9669C40.4028 15 41.3708 13.5319 40.8052 12.2122L39 8Z"
                                fill="#0078D2"
                            />
                            <circle cx="20" cy="26" r="2" fill="white" />
                            <circle cx="20" cy="26" r="5.5" stroke="white" />
                            <path
                                d="M24.5 21.5C24.5 22.3284 23.8284 23 23 23C22.1716 23 21.5 22.3284 21.5 21.5C21.5 20.6716 22.1716 20 23 20C23.8284 20 24.5 20.6716 24.5 21.5Z"
                                fill="white"
                            />
                            <path
                                d="M24.5 30.5C24.5 31.3284 23.8284 32 23 32C22.1716 32 21.5 31.3284 21.5 30.5C21.5 29.6716 22.1716 29 23 29C23.8284 29 24.5 29.6716 24.5 30.5Z"
                                fill="white"
                            />
                            <path
                                d="M16 26C16 26.8284 15.3284 27.5 14.5 27.5C13.6716 27.5 13 26.8284 13 26C13 25.1716 13.6716 24.5 14.5 24.5C15.3284 24.5 16 25.1716 16 26Z"
                                fill="white"
                            />
                        </svg>
                        Определите источники данных
                        <svg
                            className="arrow"
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="16"
                            viewBox="0 0 24 16"
                            fill="none"
                        >
                            <rect
                                width="16"
                                height="4"
                                transform="matrix(-1 0 0 1 16 6)"
                                fill="#0078D2"
                            />
                            <circle
                                cx="8"
                                cy="8"
                                r="6"
                                transform="matrix(-1 0 0 1 24 0)"
                                fill="white"
                                stroke="#00359B"
                                strokeWidth="4"
                            />
                        </svg>
                        <svg
                            className="arrow-bottom"
                            width="16"
                            height="39"
                            viewBox="0 0 16 39"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <rect
                                x="6"
                                y="31"
                                width="31"
                                height="4"
                                transform="rotate(-90 6 31)"
                                fill="#ECF1F4"
                            />
                            <circle
                                cx="8"
                                cy="31"
                                r="6"
                                transform="rotate(-90 8 31)"
                                fill="white"
                                stroke="#ECF1F4"
                                strokeWidth="4"
                            />
                        </svg>
                    </div>
                    <div className="item" onClick={scrollToCreateApp}>
                        <div className="scheme-circle">3</div>
                        <svg
                            width="48"
                            height="48"
                            viewBox="0 0 48 48"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <rect width="48" height="48" rx="8" fill="white" />
                            <rect
                                x="8"
                                y="8"
                                width="32"
                                height="32"
                                rx="6"
                                fill="#0078D2"
                            />
                            <path d="M20 32H24V36H20V32Z" fill="white" />
                            <path d="M32 23H36V36H32V23Z" fill="white" />
                            <path d="M26 28H30V36H26V28Z" fill="white" />
                            <circle cx="19" cy="19" r="7" fill="#00359B" />
                            <path
                                d="M26 19C26 20.3845 25.5895 21.7378 24.8203 22.889C24.0511 24.0401 22.9579 24.9373 21.6788 25.4672C20.3997 25.997 18.9922 26.1356 17.6344 25.8655C16.2765 25.5954 15.0292 24.9287 14.0503 23.9497C13.0713 22.9708 12.4046 21.7235 12.1345 20.3656C11.8644 19.0078 12.003 17.6003 12.5328 16.3212C13.0627 15.0421 13.9599 13.9489 15.111 13.1797C16.2622 12.4105 17.6155 12 19 12V19H26Z"
                                fill="white"
                            />
                            <rect x="20" y="30" width="4" height="2" fill="#00359B" />
                            <rect x="26" y="26" width="4" height="2" fill="#00359B" />
                            <rect x="32" y="21" width="4" height="2" fill="#00359B" />
                        </svg>
                        Создайте приложение
                        <svg
                            className="arrow"
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="16"
                            viewBox="0 0 24 16"
                            fill="none"
                        >
                            <rect
                                width="16"
                                height="4"
                                transform="matrix(-1 0 0 1 16 6)"
                                fill="#0078D2"
                            />
                            <circle
                                cx="8"
                                cy="8"
                                r="6"
                                transform="matrix(-1 0 0 1 24 0)"
                                fill="white"
                                stroke="#00359B"
                                strokeWidth="4"
                            />
                        </svg>
                        <svg
                            className="arrow-bottom"
                            width="16"
                            height="39"
                            viewBox="0 0 16 39"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <rect
                                x="6"
                                y="31"
                                width="31"
                                height="4"
                                transform="rotate(-90 6 31)"
                                fill="#ECF1F4"
                            />
                            <circle
                                cx="8"
                                cy="31"
                                r="6"
                                transform="rotate(-90 8 31)"
                                fill="white"
                                stroke="#ECF1F4"
                                strokeWidth="4"
                            />
                        </svg>
                    </div>
                    <div className="item" onClick={scrollToPublish}>
                        <div className="scheme-circle">4</div>
                        <svg
                            width="48"
                            height="48"
                            viewBox="0 0 48 48"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <rect width="48" height="48" rx="8" fill="#ECF1F4" />
                            <path
                                d="M34 8C35.1046 8 36 8.89543 36 10L36 30C36 31.1046 35.1046 32 34 32L14 32C12.8954 32 12 31.1046 12 30L12 10C12 8.89543 12.8954 8 14 8L34 8Z"
                                fill="#0078D2"
                            />
                            <circle cx="24" cy="17" r="5" fill="#00359B" />
                            <path
                                d="M29 17C29 17.9889 28.7068 18.9556 28.1573 19.7779C27.6079 20.6001 26.827 21.241 25.9134 21.6194C24.9998 21.9978 23.9945 22.0969 23.0245 21.9039C22.0546 21.711 21.1637 21.2348 20.4645 20.5355C19.7652 19.8363 19.289 18.9454 19.0961 17.9755C18.9031 17.0055 19.0022 16.0002 19.3806 15.0866C19.759 14.173 20.3999 13.3921 21.2221 12.8427C22.0444 12.2932 23.0111 12 24 12V17H29Z"
                                fill="white"
                            />
                            <path
                                d="M34 35C35.1046 35 36 35.8954 36 37L36 40L12 40L12 37C12 35.8954 12.8954 35 14 35L34 35Z"
                                fill="#00359B"
                            />
                            <path d="M18 25H30V28H18V25Z" fill="white" />
                        </svg>
                        Опубликуйте кейс
                        <svg
                            className="arrow-bottom"
                            width="16"
                            height="39"
                            viewBox="0 0 16 39"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <rect
                                x="6"
                                y="31"
                                width="31"
                                height="4"
                                transform="rotate(-90 6 31)"
                                fill="#ECF1F4"
                            />
                            <circle
                                cx="8"
                                cy="31"
                                r="6"
                                transform="rotate(-90 8 31)"
                                fill="white"
                                stroke="#ECF1F4"
                                strokeWidth="4"
                            />
                        </svg>
                    </div>
                </div>
                <div className="item" onClick={scrollToComp}>
                    <div className="scheme-circle">∞</div>
                    <svg
                        width="48"
                        height="48"
                        viewBox="0 0 48 48"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <rect width="48" height="48" rx="8" fill="#ECF1F4" />
                        <path
                            d="M34 8C35.1046 8 36 8.89543 36 10L36 38C36 39.1046 35.1046 40 34 40L14 40C12.8954 40 12 39.1046 12 38L12 10C12 8.89543 12.8954 8 14 8L34 8Z"
                            fill="#0078D2"
                        />
                        <path d="M22 8L22 14L19 12L16 14L16 8L22 8Z" fill="white" />
                        <path
                            d="M26.2 31.8333V35.8C26.2 38.1196 24.3196 40 22 40H34C35.1046 40 36 39.1046 36 38V26C36 28.3196 34.1196 30.2 31.8 30.2H27.8333C26.9313 30.2 26.2 30.9313 26.2 31.8333Z"
                            fill="#00359B"
                        />
                        <path
                            d="M26.2 31.8333V35.8C26.2 38.1196 24.3196 40 22 40C24.6892 40 27.2683 38.9317 29.1698 37.0302L33.0302 33.1698C34.9317 31.2683 36 28.6892 36 26C36 28.3196 34.1196 30.2 31.8 30.2H27.8333C26.9313 30.2 26.2 30.9313 26.2 31.8333Z"
                            fill="white"
                        />
                        <rect x="16" y="18" width="11" height="3" fill="white" />
                        <rect x="16" y="23" width="6" height="3" fill="white" />
                    </svg>
                    Повышайте компетенции на каждом этапе
                </div>
            </div>
            <div className="task-wrap" ref={taskRef}>
                <div className="subtitle">ШАг №1</div>
                <div className="title">Опишите задачу</div>
                <div className="desc">
                    Корректная постановка позволит вам сфокусироваться на главном, не
                    упуская важные моменты
                </div>
            </div>
            <TaskSlider></TaskSlider>
            <div className="source-system" ref={sourceRef}>
                <div className="bg-img"></div>
                <div>
                    <div className="subtitle">ШАг №2</div>
                    <div className="title">Определите источники данных</div>
                    <div className="desc">
                        Для этого используйте Магазин данных — единое окно доступа к
                        данным из информационных систем
                    </div>
                    <div className={"marketsGroup"}>
                        {marketLinks.map((el) => (
                            <div className="market-link" key={el.name}>
                                <div>
                                    <img src={el.icon} alt={""} />
                                    {el.name}
                                </div>
                                <Button
                                    size="s"
                                    form="round"
                                    label={el.disabled ? "Скоро появится" : "Перейти"}
                                    onClick={() => window.open(el.link, "_blank")}
                                    disabled={el.disabled}
                                />
                            </div>
                        ))}
                        <div className="info">
                            Здесь публикуются наборы данных и приложения, доступные вам
                            для использования при решении аналитической задачи
                        </div>
                        <div className="request">
                            <svg
                                width="16"
                                height="16"
                                viewBox="0 0 16 16"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                    d="M8 15C11.866 15 15 11.866 15 8C15 4.13401 11.866 1 8 1C4.13401 1 1 4.13401 1 8C1 11.866 4.13401 15 8 15ZM6.69199 6H5C5 4.2 6.28325 3 8.2 3C9.98564 3 11.2 4.14549 11.2 5.7C11.2 6.70942 10.7803 7.44145 9.9 8C9.06962 8.51817 8.74 8.74697 8.74 9.393L8.79 10H7.14L7.07 9.37954C6.98883 8.30283 7.4384 7.74509 8.3 7.2C9.10541 6.68183 9.3 6.42584 9.3 5.8C9.3 5.10584 8.79193 4.61507 8 4.61507C7.20807 4.61507 6.72945 5.25976 6.69199 6ZM9.10822 12.0472C9.10822 12.7538 8.67742 13.1913 7.97815 13.1913C7.27888 13.1913 6.84183 12.7538 6.84183 12.0472C6.84183 11.3339 7.27888 10.8965 7.97815 10.8965C8.67742 10.8965 9.10822 11.3339 9.10822 12.0472Z"
                                    fill="#0078D2"
                                />
                            </svg>

                            <div>
                                <div className="req-title">
                                    Обратитесь к нам, если не нашли подходящий набор
                                    данных
                                </div>
                                {/*<a*/}
                                {/*    className="req-desc"*/}
                                {/*    onClick={() => setIsRequestModalOpen(true)}*/}
                                {/*>*/}
                                {/*    Отправить запрос*/}
                                {/*</a>*/}
                                <Button
                                    className={cnMixSpace({ pL: "3xs" })}
                                    view={"clear"}
                                    size="s"
                                    label={"Скоро появится"}
                                    disabled
                                />
                                {/* Временная замена модалки */}
                                <RequestForDatasetModal
                                    open={isRequestModalOpen}
                                    setOpen={setIsRequestModalOpen}
                                />
                                {/*<Modal*/}
                                {/*    isOpen={isRequestModalOpen}*/}
                                {/*    hasOverlay*/}
                                {/*    onClickOutside={() => setIsRequestModalOpen(false)}*/}
                                {/*    onEsc={() => setIsRequestModalOpen(false)}*/}
                                {/*>*/}
                                {/*    <div className="modal-request">*/}
                                {/*        <div className="request-modal-title">*/}
                                {/*            Заявка на разработку набора данных*/}
                                {/*        </div>*/}
                                {/*        <div className="request-modal-desc">*/}
                                {/*            Выберите блок*/}
                                {/*        </div>*/}
                                {/*        <div className="request-modal-buttons">*/}
                                {/*            <Button size="m" view="ghost" label="БРД" />*/}
                                {/*            <Button size="m" view="ghost" label="БЛПС" />*/}
                                {/*            <Button size="m" view="ghost" label="КФ" />*/}
                                {/*        </div>*/}
                                {/*        <Button*/}
                                {/*            className="close-modal"*/}
                                {/*            size="s"*/}
                                {/*            view="clear"*/}
                                {/*            onlyIcon*/}
                                {/*            iconRight={IconClose}*/}
                                {/*            onClick={() => setIsRequestModalOpen(false)}*/}
                                {/*        />*/}
                                {/*    </div>*/}
                                {/*</Modal>*/}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div
                className="link"
                onClick={() => window.open(basepath + linksConfig.excel_link, "_blank")}
            >
                <div className="text">
                    <svg
                        width="48"
                        height="48"
                        viewBox="0 0 48 48"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <rect width="48" height="48" rx="8" fill="white" />
                        <g clipPath="url(#clip0_12899_9429)">
                            <path
                                d="M27.5815 23.3484L16.5117 21.3984V35.807C16.5117 36.465 17.0461 36.9984 17.7053 36.9984H36.8065C37.4657 36.9984 38.0001 36.465 38.0001 35.807V30.4984L27.5815 23.3484Z"
                                fill="#185C37"
                            />
                            <path
                                d="M27.5815 11H17.7053C17.0461 11 16.5117 11.5334 16.5117 12.1914V17.5L27.5815 24L33.442 25.95L38.0001 24V17.5L27.5815 11Z"
                                fill="#21A366"
                            />
                            <path
                                d="M16.5117 17.5H27.5815V24H16.5117V17.5Z"
                                fill="#107C41"
                            />
                            <path
                                opacity="0.1"
                                d="M24.4344 16.1992H16.5117V32.4492H24.4344C25.0927 32.4471 25.6259 31.9149 25.628 31.2578V17.3907C25.6259 16.7335 25.0927 16.2014 24.4344 16.1992Z"
                                fill="black"
                            />
                            <path
                                opacity="0.2"
                                d="M23.7833 16.8516H16.5117V33.1016H23.7833C24.4416 33.0994 24.9747 32.5672 24.9768 31.9101V18.043C24.9747 17.3859 24.4416 16.8537 23.7833 16.8516Z"
                                fill="black"
                            />
                            <path
                                opacity="0.2"
                                d="M23.7833 16.8516H16.5117V31.8016H23.7833C24.4416 31.7994 24.9747 31.2672 24.9768 30.6101V18.043C24.9747 17.3859 24.4416 16.8537 23.7833 16.8516Z"
                                fill="black"
                            />
                            <path
                                opacity="0.2"
                                d="M23.1321 16.8516H16.5117V31.8016H23.1321C23.7904 31.7994 24.3235 31.2672 24.3257 30.6101V18.043C24.3235 17.3859 23.7904 16.8537 23.1321 16.8516Z"
                                fill="black"
                            />
                            <path
                                d="M11.1936 16.8516H23.132C23.7912 16.8516 24.3256 17.385 24.3256 18.043V29.9601C24.3256 30.6181 23.7912 31.1516 23.132 31.1516H11.1936C10.5344 31.1516 10 30.6181 10 29.9601V18.043C10 17.385 10.5344 16.8516 11.1936 16.8516Z"
                                fill="url(#paint0_linear_12899_9429)"
                            />
                            <path
                                d="M13.6953 27.873L16.2062 23.9893L13.9056 20.127H15.7562L17.0117 22.597C17.1276 22.8316 17.207 23.0058 17.25 23.1208H17.2663C17.3488 22.9336 17.4356 22.7519 17.5268 22.5755L18.8688 20.1283H20.5677L18.2085 23.9678L20.6276 27.873H18.82L17.3698 25.1619C17.3015 25.0465 17.2436 24.9253 17.1966 24.7998H17.1751C17.1327 24.9228 17.0763 25.0406 17.0071 25.1508L15.514 27.873H13.6953Z"
                                fill="white"
                            />
                            <path
                                d="M36.8071 11H27.582V17.5H38.0006V12.1914C38.0006 11.5334 37.4663 11 36.8071 11Z"
                                fill="#33C481"
                            />
                            <path d="M27.582 24H38.0006V30.5H27.582V24Z" fill="#107C41" />
                        </g>
                        <defs>
                            <linearGradient
                                id="paint0_linear_12899_9429"
                                x1="12.4886"
                                y1="15.9206"
                                x2="21.8119"
                                y2="32.097"
                                gradientUnits="userSpaceOnUse"
                            >
                                <stop stopColor="#18884F" />
                                <stop offset="0.5" stopColor="#117E43" />
                                <stop offset="1" stopColor="#0B6631" />
                            </linearGradient>
                            <clipPath id="clip0_12899_9429">
                                <rect
                                    width="28"
                                    height="26"
                                    fill="white"
                                    transform="translate(10 11)"
                                />
                            </clipPath>
                        </defs>
                    </svg>
                    Также для работы вы можете использовать данные из Excel-файлов,
                    которые хранятся
                    <br />
                    на вашем компьютере или сетевом ресурсе.
                </div>
                <a>
                    Подробнее
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                    >
                        <path
                            d="M10.5942 7.00002L7.29709 3.70291L8.7113 2.2887L14.4226 8.00002L8.7113 13.7113L7.29709 12.2971L10.5942 9.00002H2V7.00002H10.5942Z"
                            fill="#00395C"
                            fillOpacity="0.8"
                        />
                    </svg>
                </a>
            </div>
            <div className="create-app-wrap" ref={createAppRef}>
                <div className="subtitle">ШАГ №3</div>
                <div className="title">Создайте приложение</div>
                <div className="desc">
                    Ознакомьтесь с доступными инструментами и выберите подходящий для
                    решения вашей аналитической задачи
                </div>
                <div className="create-app-button">
                    <Button
                        size="m"
                        view="ghost"
                        iconRight={IconForward}
                        label="Об инструментах"
                        form="round"
                        onClick={() => window.open(basepath + "tools", "_blank")}
                    ></Button>
                    {isAdmin && (
                        <Button
                            size="m"
                            view="clear"
                            iconRight={IconEdit}
                            label="Изменить ссылки на инструменты"
                            form="round"
                            onClick={() => setIsOpenEditLinksModal(true)}
                        />
                    )}
                </div>
            </div>
            <ToolsBlock
                isOpenEditModal={isOpenEditLinksModal}
                setIsOpenEditModal={setIsOpenEditLinksModal}
            />
            <div
                className="link"
                onClick={() => window.open(basepath + linksConfig.publish_link)}
            >
                <div className="text">
                    <svg
                        width="48"
                        height="48"
                        viewBox="0 0 48 48"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <rect width="48" height="48" rx="8" fill="white" />
                        <path
                            d="M8 13C8 11.8954 8.89543 11 10 11H21C22.1046 11 23 11.8954 23 13V28C23 29.1046 22.1046 30 21 30H10C8.89543 30 8 29.1046 8 28V13Z"
                            fill="#00359B"
                        />
                        <path
                            d="M8 15H38C39.1046 15 40 15.8954 40 17V35C40 36.1046 39.1046 37 38 37H10C8.89543 37 8 36.1046 8 35V15Z"
                            fill="#0078D2"
                        />
                        <circle cx="24" cy="26" r="5" fill="white" />
                        <path d="M23 26V29H25V26H27L24 23L21 26H23Z" fill="#0078D2" />
                    </svg>
                    <div>
                        <div className="link-title">
                            Поделитесь приложением с коллегами
                        </div>
                        Когда получен результат в созданном вами приложении, опубликуйте
                        его в директории для совместного использования и настройте права
                        доступа
                    </div>
                </div>
                <a>
                    Подробнее
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                    >
                        <path
                            d="M10.5942 7.00002L7.29709 3.70291L8.7113 2.2887L14.4226 8.00002L8.7113 13.7113L7.29709 12.2971L10.5942 9.00002H2V7.00002H10.5942Z"
                            fill="#00395C"
                            fillOpacity="0.8"
                        />
                    </svg>
                </a>
            </div>

            <div className="case-publish-wrap" ref={publishRef}>
                <div className="subtitle">ШАГ №4</div>
                <div className="title">Опубликуйте кейс</div>
                <div className="desc">
                    Чтобы ваши коллеги смогли узнать о вашем решении или переиспользовать
                    его — опубликуйте кейс с приложением в разделе «Портфолио»
                </div>

                <Button
                    size="m"
                    view="ghost"
                    form="round"
                    iconRight={IconAdd}
                    label="Добавить свой кейс"
                    onClick={() => window.open(basepath + `portfolio/create`, "_blank")}
                />
            </div>
        </div>
    );
};
