import { useEffect } from "react";

import { Breadcrumbs } from "@consta/uikit/Breadcrumbs";

import { PageRoutes } from "appMenuAndRouting/appRoutes";

import { PageContentWrapper } from "components/PageContentWrapper";
import { FormMutatePortfolio } from "./components/FormMutatePortfolio";

export const PortfolioCreatePage = () => {
    const items = [
        {
            label: "Лаборатория данных",
            href: PageRoutes.DataLab_MainPage,
        },
        {
            label: "Портфолио",
            href: PageRoutes.DataLab_PortfolioPage,
        },
        {
            label: "Добавление кейса",
            href: PageRoutes.DataLab_PortfolioCreatePage,
        },
    ];

    useEffect(() => {
        document.title = "Создание портфолио";
    }, []);

    return (
        <PageContentWrapper className="portfolio-mutate-page">
            <Breadcrumbs items={items} />

            <div className="portfolio-mutate-page__title">Добавление кейса</div>
            <div className="portfolio-mutate-page__description">
                <div className="portfolio-mutate-page__description-text">
                    Поделитесь вашим приложением, созданным в Лаборатории данных. Для
                    этого заполните поля ниже и отправьте заявку на модерацию. После
                    успешной модерации кейс будет опубликован в Портфолио.
                </div>
            </div>
            <FormMutatePortfolio />
        </PageContentWrapper>
    );
};
