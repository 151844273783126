import { FC, useEffect, useState } from "react";

// import { Grid } from "@consta/uikit/Grid";

import { PortfolioItem } from "./components/PortfolioItem";

import { PortfolioItemsProps } from "./model/PortfolioItems.model";

import "./PortfolioItems.scss";

export const PortfolioItems: FC<PortfolioItemsProps> = ({
    porfolio,
    totalPages,
    currentPage,
    setPage,
}) => {
    const [porfolioState, setPorfolioState] = useState(porfolio);

    const handleChangePage = () => {
        currentPage && setPage(currentPage + 1);
        // setPorfolioState((prev) => [...(prev || []), ...(porfolio || [])]);
    };

    useEffect(() => {
        if (currentPage === 1) setPorfolioState(porfolio);
        else setPorfolioState((prev) => [...(prev || []), ...(porfolio || [])]);
    }, [porfolio, currentPage]);

    return (
        <div className="portfolio-items">
            {porfolio?.length ? (
                <>
                    <div className="portfolio-list">
                        {porfolioState?.map((item) => (
                            <PortfolioItem
                                id={item._uuid}
                                key={item._uuid}
                                mainImage={item.images[0]?._uuid}
                                title={item.name}
                                description={item.description}
                                toolImage={item.tool?.uuid_image}
                                toolIcon={item.tool?.uuid_image_icon}
                                toolName={item.tool?.name}
                                complexity={item.complexity}
                                blockTitle={item.block?.short_name}
                                date={item._create_ts}
                            />
                        ))}
                    </div>

                    {!!totalPages && !!currentPage && totalPages !== currentPage && (
                        <span
                            className="portfolio-items__link"
                            onClick={handleChangePage}
                        >
                            Показать еще
                        </span>
                    )}
                </>
            ) : (
                <div className="portfolio-items__empty-list">Ничего не найдено</div>
            )}
        </div>
    );
};
