import { useEffect } from "react";

import { HeroBlock } from "./components/HeroBlock";
import { FaqWidget } from "./components/FaqWidget";
import { PageContentWrapper } from "components/PageContentWrapper";

export const FaqPage = () => {
    useEffect(() => {
        document.title = "FAQ | Лаборатория данных";
    }, []);
    return (
        <>
            <HeroBlock />
            <PageContentWrapper>
                <FaqWidget showFilters={true} />
            </PageContentWrapper>
        </>
    );
};
