import { FC } from "react";

import { useFormContext } from "react-hook-form";

import { IconDraggable } from "@consta/uikit/IconDraggable";
import { Button } from "@consta/uikit/Button";
import { IconTrash } from "@consta/uikit/IconTrash";
import { Avatar } from "@consta/uikit/Avatar";

import quotes from "assets/icons/icn_quotes.svg";

import { ReviewType } from "../../../../model/ReviewsWidget.model";
import { ReviewItemProps } from "./model/ReviewItem.model";

import "./ReviewItem.scss";

export const ReviewItem: FC<ReviewItemProps> = ({ review, currentIndex }) => {
    const { setValue, getValues } = useFormContext();
    return (
        <div className="review-item">
            <div className="review-item__header">
                <IconDraggable size="xs" />
                <div className="review-item__header-text">Комментарий {currentIndex}</div>
                <div className="review-item__header-buttons">
                    <Button
                        onlyIcon
                        iconRight={IconTrash}
                        size="xs"
                        view="ghost"
                        onClick={() => {
                            const oldValues = getValues("reviews") as ReviewType[];
                            const newValues = oldValues.filter(
                                (item, index) => index !== currentIndex - 1
                            );
                            setValue("reviews", newValues);
                        }}
                    />
                </div>
            </div>
            <div className="review-item__body">
                <img src={quotes} className="review-item__body-icon" alt={""} />
                <div className="review-item__body-user review-user">
                    <div className="review-user__info user-info">
                        <Avatar
                            className="user-info__avatar"
                            size="l"
                            name={`${review.user?.label}`}
                            url={review?.user?.avatarUrl}
                        />
                        <div className="user-info__content">
                            {review.user?.label && (
                                <div className="user-info__content-name">
                                    {review.user.label}
                                </div>
                            )}
                            {review.user?.subLabel && (
                                <div className="user-info__content-desc">
                                    {review.user?.subLabel}
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="review-user__text">{review.body}</div>
                </div>
            </div>
        </div>
    );
};
