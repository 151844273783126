import { FC } from "react";

import { PageContentWrapperProps } from "./model/PageContentWrapper.model";

import "./PageContentWrapper.scss";

export const PageContentWrapper: FC<PageContentWrapperProps> = ({
    children,
    className,
}) => <div className={`page-content ${className}`}>{children}</div>;
