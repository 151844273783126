import { createIcon } from "@consta/uikit/createIcon";

const svg = () => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="12"
        height="12"
        viewBox="0 0 12 12"
        fill="none"
    >
        <g clipPath="url(#clip0_15755_7620)">
            <path
                d="M0 1C0 0.722917 0.222917 0.5 0.5 0.5H1.44792C1.90625 0.5 2.3125 0.766667 2.50208 1.16667H11.0646C11.6125 1.16667 12.0125 1.6875 11.8688 2.21667L11.0146 5.38958C10.8375 6.04375 10.2438 6.5 9.56667 6.5H3.55625L3.66875 7.09375C3.71458 7.32917 3.92083 7.5 4.16042 7.5H10.1667C10.4438 7.5 10.6667 7.72292 10.6667 8C10.6667 8.27708 10.4438 8.5 10.1667 8.5H4.16042C3.43958 8.5 2.82083 7.9875 2.6875 7.28125L1.6125 1.63542C1.59792 1.55625 1.52917 1.5 1.44792 1.5H0.5C0.222917 1.5 0 1.27708 0 1ZM2.66667 10.1667C2.66667 10.0353 2.69253 9.90531 2.74279 9.78398C2.79304 9.66266 2.8667 9.55242 2.95956 9.45956C3.05242 9.3667 3.16266 9.29304 3.28398 9.24279C3.40531 9.19253 3.53534 9.16667 3.66667 9.16667C3.79799 9.16667 3.92802 9.19253 4.04935 9.24279C4.17068 9.29304 4.28092 9.3667 4.37377 9.45956C4.46663 9.55242 4.54029 9.66266 4.59055 9.78398C4.6408 9.90531 4.66667 10.0353 4.66667 10.1667C4.66667 10.298 4.6408 10.428 4.59055 10.5494C4.54029 10.6707 4.46663 10.7809 4.37377 10.8738C4.28092 10.9666 4.17068 11.0403 4.04935 11.0905C3.92802 11.1408 3.79799 11.1667 3.66667 11.1667C3.53534 11.1667 3.40531 11.1408 3.28398 11.0905C3.16266 11.0403 3.05242 10.9666 2.95956 10.8738C2.8667 10.7809 2.79304 10.6707 2.74279 10.5494C2.69253 10.428 2.66667 10.298 2.66667 10.1667ZM9.66667 9.16667C9.93188 9.16667 10.1862 9.27202 10.3738 9.45956C10.5613 9.6471 10.6667 9.90145 10.6667 10.1667C10.6667 10.4319 10.5613 10.6862 10.3738 10.8738C10.1862 11.0613 9.93188 11.1667 9.66667 11.1667C9.40145 11.1667 9.1471 11.0613 8.95956 10.8738C8.77202 10.6862 8.66667 10.4319 8.66667 10.1667C8.66667 9.90145 8.77202 9.6471 8.95956 9.45956C9.1471 9.27202 9.40145 9.16667 9.66667 9.16667Z"
                fill="#00395C"
                fillOpacity="0.8"
            />
        </g>
        <defs>
            <clipPath id="clip0_15755_7620">
                <rect width="12" height="12" fill="white" />
            </clipPath>
        </defs>
    </svg>
);

export const IconMarket = createIcon({
    m: svg,
    s: svg,
    xs: svg,
    name: "IconMarket",
});
