import { FC, useRef, useState } from "react";

import Slider from "react-slick";

import { CarouselButtons } from "./components/CarouselButtons";

import { PorfolioSliderProps } from "./model/PorfolioSlider.model";

import "./PorfolioSlider.scss";

export const PorfolioSlider: FC<PorfolioSliderProps> = ({ images }) => {
    const sliderRef = useRef<Slider>(null);

    const [currentSlide, setCurrentSlide] = useState(0);

    const portfolioLength = images?.length || 0;

    return (
        <div className="portfolio-item-slider">
            <div className="portfolio-slider-title">Скриншоты из приложения</div>
            <Slider
                slidesToShow={1}
                slidesToScroll={1}
                afterChange={setCurrentSlide}
                ref={sliderRef}
                infinite={false}
            >
                {images?.map((image) => (
                    <div key={image._uuid}>
                        <img
                            key={image._uuid}
                            src={`${process.env.REACT_APP_API_URL}/images/${image._uuid}`}
                            alt={""}
                            height={"380px"}
                            width={"100%"}
                            style={{ objectFit: "contain" }}
                        />
                    </div>
                ))}
            </Slider>
            <CarouselButtons
                handleNextSlide={() => sliderRef.current?.slickNext()}
                handlePrevSlide={() => sliderRef.current?.slickPrev()}
                currentSlide={currentSlide + 1}
                slidesLength={portfolioLength}
            />
        </div>
    );
};
