import "./TaskSlider.scss";
import Slider from "react-slick";
import { useRef, useState } from "react";
import { linksConfig } from "../../../../../../configs/linksConfig";

export const TaskSlider = function () {
    const sliderRef = useRef<Slider>(null);
    const [currentSlide, setCurrentSlide] = useState(0);

    const settings = {
        infinite: false,
        speed: 500,
        slidesToScroll: 3,
        variableWidth: true,
    };

    const basepath =
        process.env.REACT_APP_BASEPATH === "/"
            ? process.env.REACT_APP_BASEPATH
            : `${process.env.REACT_APP_BASEPATH}/`;

    const next = () => {
        if (currentSlide != 3) {
            sliderRef.current?.slickNext();
        }
    };

    return (
        <div className="task-slider-container">
            <Slider {...settings} ref={sliderRef} afterChange={setCurrentSlide}>
                <div className="slide">
                    <div className="slide-title">
                        <svg
                            width="16"
                            height="16"
                            viewBox="0 0 16 16"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <g clipPath="url(#clip0_14139_95848)">
                                <path d="M7 5H5V7H7V5Z" fill="#0078D2" />
                                <path d="M11 9V11H5V9H11Z" fill="#0078D2" />
                                <path d="M11 5H9V7H11V5Z" fill="#0078D2" />
                                <path
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                    d="M16 8C16 12.4183 12.4183 16 8 16C3.58172 16 0 12.4183 0 8C0 3.58172 3.58172 0 8 0C12.4183 0 16 3.58172 16 8ZM14 8C14 11.3137 11.3137 14 8 14C4.68629 14 2 11.3137 2 8C2 4.68629 4.68629 2 8 2C11.3137 2 14 4.68629 14 8Z"
                                    fill="#0078D2"
                                />
                            </g>
                            <defs>
                                <clipPath id="clip0_14139_95848">
                                    <rect width="16" height="16" fill="white" />
                                </clipPath>
                            </defs>
                        </svg>
                        Аудитория
                    </div>
                    <div className="slide-desc">Кто будет пользоваться приложением?</div>
                </div>
                <div className="slide">
                    <div className="slide-title">
                        <svg
                            width="16"
                            height="16"
                            viewBox="0 0 16 16"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <g clipPath="url(#clip0_14212_98618)">
                                <path
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                    d="M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16ZM6.50513 5.71429H4.57143C4.57143 3.65715 6.038 2.28571 8.22857 2.28571C10.2693 2.28571 11.6571 3.59485 11.6571 5.37143C11.6571 6.52505 11.1775 7.36166 10.1714 8C9.22242 8.59219 8.84571 8.85368 8.84571 9.592L8.90286 10.2857H7.01714L6.93714 9.57662C6.84438 8.34609 7.35817 7.70867 8.34286 7.08571C9.26333 6.49352 9.48571 6.20096 9.48571 5.48571C9.48571 4.69239 8.90507 4.13151 8 4.13151C7.09493 4.13151 6.54794 4.8683 6.50513 5.71429ZM9.26654 12.6254C9.26654 13.433 8.77419 13.9329 7.97503 13.9329C7.17586 13.9329 6.67638 13.433 6.67638 12.6254C6.67638 11.8102 7.17586 11.3103 7.97503 11.3103C8.77419 11.3103 9.26654 11.8102 9.26654 12.6254Z"
                                    fill="#0078D2"
                                />
                            </g>
                            <defs>
                                <clipPath id="clip0_14212_98618">
                                    <rect width="16" height="16" fill="white" />
                                </clipPath>
                            </defs>
                        </svg>
                        Вопросы
                    </div>
                    <div className="slide-desc">
                        На какие вопросы пользователей должно ответить приложение?
                    </div>
                </div>
                <div className="slide">
                    <div className="slide-title">
                        <svg
                            width="16"
                            height="16"
                            viewBox="0 0 16 16"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <g clipPath="url(#clip0_14139_95842)">
                                <path
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                    d="M12 8C12 10.2091 10.2091 12 8 12C5.79086 12 4 10.2091 4 8C4 5.79086 5.79086 4 8 4C10.2091 4 12 5.79086 12 8ZM10 8C10 9.10457 9.10457 10 8 10C6.89543 10 6 9.10457 6 8C6 6.89543 6.89543 6 8 6C9.10457 6 10 6.89543 10 8Z"
                                    fill="#0078D2"
                                />
                                <path
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                    d="M16 8C16 12.4183 12.4183 16 8 16C3.58172 16 0 12.4183 0 8C0 3.58172 3.58172 0 8 0C12.4183 0 16 3.58172 16 8ZM14 8C14 11.3137 11.3137 14 8 14C4.68629 14 2 11.3137 2 8C2 4.68629 4.68629 2 8 2C11.3137 2 14 4.68629 14 8Z"
                                    fill="#0078D2"
                                />
                            </g>
                            <defs>
                                <clipPath id="clip0_14139_95842">
                                    <rect width="16" height="16" fill="white" />
                                </clipPath>
                            </defs>
                        </svg>
                        Показатели
                    </div>
                    <div className="slide-desc">
                        Какие показатели помогут на них ответить?
                    </div>
                </div>
                <div className="slide">
                    <div className="slide-title">
                        <svg
                            width="16"
                            height="16"
                            viewBox="0 0 16 16"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <g clipPath="url(#clip0_14139_95830)">
                                <path
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                    d="M0 0V6H7V4H9V6H11.5C11.5 6.55228 11.0523 7 10.5 7H5.5C3.84315 7 2.5 8.34315 2.5 10H0V16H7V14H9V16H16V10H9V12H7V10H4.5C4.5 9.44772 4.94772 9 5.5 9H10.5C12.1569 9 13.5 7.65685 13.5 6H16V0H9V2H7V0H0ZM5 2H2V4H5V2ZM14 2H11V4H14V2ZM2 12H5V14H2V12ZM14 12H11V14H14V12Z"
                                    fill="#0078D2"
                                />
                            </g>
                            <defs>
                                <clipPath id="clip0_14139_95830">
                                    <rect width="16" height="16" fill="white" />
                                </clipPath>
                            </defs>
                        </svg>
                        Фильтры
                    </div>
                    <div className="slide-desc">
                        В каких разрезах нужно детализировать показатели?
                    </div>
                </div>
                <div className="slide">
                    <div className="slide-title">
                        <svg
                            width="16"
                            height="16"
                            viewBox="0 0 16 16"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <g clipPath="url(#clip0_14139_95854)">
                                <path d="M4 5H12V7H4V5Z" fill="#0078D2" />
                                <path d="M4 9H8V11H4V9Z" fill="#0078D2" />
                                <path
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                    d="M3 0C1.34315 0 0 1.34315 0 3V13C0 14.6569 1.34315 16 3 16H13C14.6569 16 16 14.6569 16 13V3C16 1.34315 14.6569 0 13 0H3ZM2 3C2 2.44772 2.44772 2 3 2H13C13.5523 2 14 2.44772 14 3V13C14 13.5523 13.5523 14 13 14H3C2.44772 14 2 13.5523 2 13V3Z"
                                    fill="#0078D2"
                                />
                            </g>
                            <defs>
                                <clipPath id="clip0_14139_95854">
                                    <rect width="16" height="16" fill="white" />
                                </clipPath>
                            </defs>
                        </svg>
                        Макет
                    </div>
                    <div className="slide-desc">
                        Какие визуализации выбрать и как разместить их на дашборде?
                    </div>
                </div>
                <div
                    className="slide last"
                    onClick={() =>
                        window.open(
                            basepath + linksConfig.task_slider_last_element_link,
                            "_blank"
                        )
                    }
                >
                    <div className="slide-title">
                        Читать подробнее о каждом шаге описания задачи
                    </div>
                    <div className="slide-desc">
                        Перейти{" "}
                        <svg
                            width="16"
                            height="16"
                            viewBox="0 0 16 16"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M10.5942 6.99989L7.29709 3.70279L8.7113 2.28857L14.4226 7.9999L8.7113 13.7112L7.29709 12.297L10.5942 8.99989H2V6.99989H10.5942Z"
                                fill="#FAFAFA"
                            />
                        </svg>
                    </div>
                </div>
            </Slider>
            <div className="arrows">
                <svg
                    width="40"
                    height="40"
                    viewBox="0 0 40 40"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    onClick={() => sliderRef.current?.slickPrev()}
                >
                    <rect
                        width="40"
                        height="40"
                        rx="20"
                        fill="#004269"
                        fillOpacity="0.07"
                    />
                    <path
                        d="M28 19H15.83L21.42 13.41L20 12L12 20L20 28L21.41 26.59L15.83 21H28V19Z"
                        fill="#00395C"
                        fillOpacity="0.8"
                    />
                </svg>
                <svg
                    width="40"
                    height="40"
                    viewBox="0 0 40 40"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    onClick={() => next()}
                >
                    <rect
                        width="40"
                        height="40"
                        rx="20"
                        fill="#004269"
                        fillOpacity="0.07"
                    />
                    <path
                        d="M20 12L18.59 13.41L24.17 19H12V21H24.17L18.59 26.59L20 28L28 20L20 12Z"
                        fill="#00395C"
                        fillOpacity="0.8"
                    />
                </svg>
            </div>
        </div>
    );
};
