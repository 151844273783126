import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

import { useGetKnowledgesQuery } from "store/labApi";

import { Loader } from "@consta/uikit/Loader";

import { KnowledgeFilters } from "./components/KnowledgeFilters";
import { KnowledgeItems } from "./components/KnowledgeItems";

import { getKnowledgesQueryParams } from "./KnowledgeBlock.utils";
import { KnowledgeModelDto } from "../../../../store/types/Knowledge.model";

export const KnowledgeBlock = () => {
    const [searchParams] = useSearchParams();
    const [page, setPage] = useState(1);
    const [requestParams, setRequestParams] = useState<{ params: string; page: number }>({
        params: "",
        page: 1,
    });
    const { data, isLoading } = useGetKnowledgesQuery(requestParams, {
        refetchOnMountOrArgChange: true,
    });

    const [knowledgesData, setKnowledgesData] = useState<KnowledgeModelDto[] | undefined>(
        data?.data.models
    );

    const metaData = data?.data?.meta;

    useEffect(() => {
        if (page === 1) {
            setKnowledgesData(data?.data.models ?? []);
        } else {
            setKnowledgesData((prevState) => [
                ...(prevState ?? []),
                ...(data?.data.models ?? []),
            ]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    useEffect(() => {
        setPage(1);
        setRequestParams({
            page: 1,
            params: getKnowledgesQueryParams([...searchParams.entries()]),
        });
    }, [searchParams]);

    useEffect(() => {
        if (page > 1) {
            setRequestParams({
                page: page,
                params: getKnowledgesQueryParams([...searchParams.entries()]),
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page]);

    if (isLoading) {
        return <Loader />;
    }

    return (
        <>
            <KnowledgeFilters
                total={metaData?.total}
                availableFilters={data?.data?.filters.available}
            />
            <KnowledgeItems
                knowledges={knowledgesData}
                totalPages={metaData?.total_pages}
                currentPage={metaData?.current_page}
                setPage={(value) => setPage(value)}
            />
        </>
    );
};
