import { useState } from "react";
import { useSearchParams } from "react-router-dom";

import { useGetPortfolioQuery } from "store/labApi";

import { Loader } from "@consta/uikit/Loader";
import { PortfolioFilters } from "./components/PortfolioFilters";
import { PortfolioItems } from "./components/PortfolioItems";

import { getPorfoliosQueryParams } from "./PorfolioBlock.utils";

export const PorfolioBlock = () => {
    const [searchParams] = useSearchParams();
    const [page, setPage] = useState(1);

    const { data, isLoading } = useGetPortfolioQuery(
        {
            params: getPorfoliosQueryParams([...searchParams.entries()]),
            page: page,
        },
        { refetchOnMountOrArgChange: true }
    );

    const portfolioData = data?.data;
    const metaData = portfolioData?.meta;

    if (isLoading) {
        return <Loader />;
    }

    return (
        <>
            <PortfolioFilters total={metaData?.total} setPage={setPage} />
            <PortfolioItems
                porfolio={portfolioData?.models}
                totalPages={metaData?.total_pages}
                currentPage={metaData?.current_page}
                setPage={(value) => setPage(value)}
            />
        </>
    );
};
