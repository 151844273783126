import { FC, useEffect, useState } from "react";

import { Controller, useForm } from "react-hook-form";

import { useEditUserMutation } from "store/labApi";

import { TextField } from "@consta/uikit/TextField";
import { cnMixSpace } from "@consta/uikit/MixSpace";
import { Button } from "@consta/uikit/Button";
import { Informer } from "@consta/uikit/Informer";

import { ModalWrapper } from "shared/components/ModalWrapper";

import { ErrorType } from "shared/types/ErrorType";

import { createParams } from "./EditContactModal.utils";

import { EditContactModalProps, EditContactSubmit } from "./model/EditContactModal.model";

import "../../../MutateContactModal.scss";

export const EditContactModal: FC<EditContactModalProps> = ({
    title,
    isOpen,
    handleClose,
    employee,
    refetch,
    position,
}) => {
    const { handleSubmit, control, reset } = useForm();

    const [hasError, setHasError] = useState(false);

    const [editUser, { error, isLoading }] = useEditUserMutation();

    const onSubmit = async (data: EditContactSubmit) => {
        const params = createParams(data);
        await editUser({
            id: employee.id,
            body: params,
        }).unwrap();
        refetch();
        extendedHandleClose();
    };

    const extendedHandleClose = () => {
        handleClose();
        reset();
    };

    useEffect(() => {
        if (error) {
            setHasError(true);
            setTimeout(() => {
                setHasError(false);
            }, 7000);
        }
    }, [error]);

    return (
        <>
            <ModalWrapper title={title} isOpen={isOpen} handleClose={extendedHandleClose}>
                {hasError && error && (
                    <Informer
                        status="alert"
                        view="filled"
                        title="Ошибка в редактировании контакта"
                        label={"data" in error ? (error as ErrorType).data.error : ""}
                    />
                )}
                <form onSubmit={handleSubmit(onSubmit)} className="mutate-contact-form">
                    <Controller
                        name="position"
                        control={control}
                        defaultValue={{ value: position }}
                        render={({ field }) => {
                            return (
                                <TextField
                                    width="full"
                                    label="Роль"
                                    placeholder="Плейсхолдер"
                                    {...field}
                                    value={field.value?.value}
                                />
                            );
                        }}
                    />

                    <div className="mutate-contact-form__buttons">
                        <Button
                            type="button"
                            label="Отменить"
                            onClick={extendedHandleClose}
                            view="ghost"
                        />
                        <Button
                            type="submit"
                            label="Сохранить"
                            className={cnMixSpace({
                                mL: "m",
                            })}
                            loading={isLoading}
                        />
                    </div>
                </form>
            </ModalWrapper>
        </>
    );
};
